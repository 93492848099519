/**
 * Factory to return ITelemetryLogger objects
 */

import { App, Session, Host, Release } from "./Contracts";
import ITelemetryLogger from "./ITelemetryLogger";
import AriaTelemetryLogger from "./AriaTelemetryLogger";

export default class TelemetryLoggerFactory {
	public static create(tenantToken: string, namespace: string, app: App, session: Session,
		host: Host, release: Release): ITelemetryLogger {
			return new AriaTelemetryLogger(tenantToken, namespace, app, session, host, release);
		}
}
