"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Utils = require("./Utils");
var GovernedChannelType;
(function (GovernedChannelType) {
    GovernedChannelType[GovernedChannelType["Standard"] = 0] = "Standard";
    GovernedChannelType[GovernedChannelType["Urgent"] = 1] = "Urgent";
    GovernedChannelType[GovernedChannelType["Banner"] = 2] = "Banner";
    GovernedChannelType[GovernedChannelType["TeachingMessage"] = 3] = "TeachingMessage";
})(GovernedChannelType = exports.GovernedChannelType || (exports.GovernedChannelType = {}));
(function (GovernedChannelType) {
    function getDefault() { return GovernedChannelType.Standard; }
    GovernedChannelType.getDefault = getDefault;
})(GovernedChannelType = exports.GovernedChannelType || (exports.GovernedChannelType = {}));
var GovernedChannel = /** @class */ (function () {
    function GovernedChannel(type, name, cooldownSeconds, cooldownStartTime) {
        if (!Utils.isEnumValue(type, GovernedChannelType)) {
            throw new Error("type is not a valid GovernedChannelType");
        }
        if (!name) {
            throw new Error("name must not be null or empty");
        }
        if (cooldownSeconds < 0) {
            throw new Error("cooldownSeconds must not be negative");
        }
        this.type = type;
        this.name = name;
        this.cooldownSeconds = cooldownSeconds;
        this.setCooldownStartTime(cooldownStartTime);
    }
    GovernedChannel.prototype.setCooldownStartTime = function (cooldownStartTime) {
        // Set the start time
        this.cooldownStartTime = cooldownStartTime;
        if (Utils.isNullOrUndefined(this.cooldownStartTime)) {
            // Null cooldownStartTime means cool down has never been initiated, so set the cooldownEndTime to distant past
            this.cooldownEndTime = Utils.getDistantPast();
            return;
        }
        // Calculate the end time, but don't overflow past max.
        this.cooldownEndTime = Utils.addSecondsWithoutOverflow(this.cooldownStartTime, this.cooldownSeconds);
    };
    // @Override
    GovernedChannel.prototype.isOpen = function () {
        return this.isOpenAtDate(new Date());
    };
    // @Override
    GovernedChannel.prototype.isOpenAtDate = function (date) {
        if (!date) {
            return false;
        }
        return (date > this.cooldownEndTime);
    };
    // @Override
    GovernedChannel.prototype.getType = function () {
        return this.type;
    };
    // @Override
    GovernedChannel.prototype.getName = function () {
        return this.name;
    };
    // @Override
    GovernedChannel.prototype.getCooldownSeconds = function () {
        return this.cooldownSeconds;
    };
    // @Override
    GovernedChannel.prototype.getCooldownStartTime = function () {
        return this.cooldownStartTime;
    };
    // @Override
    GovernedChannel.prototype.getCooldownEndTime = function () {
        return this.cooldownEndTime;
    };
    return GovernedChannel;
}());
exports.GovernedChannel = GovernedChannel;
