"use strict";
var ISurveyEvent;
(function (ISurveyEvent) {
    var Type;
    (function (Type) {
        // A string to listen to, with a trigger threshold, and potentially cross-session aggregation
        Type[Type["CountedActivity"] = "CountedActivity"] = "CountedActivity";
        // An ordered list of CountedActivities, to be activated in sequence
        Type[Type["CountedActivitySequence"] = "CountedActivitySequence"] = "CountedActivitySequence";
    })(Type = ISurveyEvent.Type || (ISurveyEvent.Type = {}));
})(ISurveyEvent || (ISurveyEvent = {}));
module.exports = ISurveyEvent;
