﻿/**
 * UI.ts
 *
 * Module for the UI
 */

export {IUserVoiceInitOptions} from "./Bellyband/FormTemplates/UserVoiceFormTemplate";
export {createMulti as createBellyBandMulti} from "./BellyBand/BellyBand";
export {createSingle as createBellyBandSingle} from "./BellyBand/BellyBand";
export {createSurvey as createSurvey} from "./Toast/Toast";
