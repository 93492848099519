﻿/**
 * BasicFormTemplate.ts
 *
 * A form template with contact info fields.
 */

import * as UIStrings from "./../../../UIStrings/UIStrings";
import {FormTemplateType} from "./FormTemplateType";
import {IUIAsJson} from "./../../Renderer";
import * as CategoriesDropdown from "./../../CategoriesDropdown";
import * as RatingControl from "./../../RatingControl";
import * as Constants from "./../../../Constants";
import * as Configuration from "./../../../Configuration/Configuration";

/**
 * Generate the markup
 * @returns the markup as json
 */
export function generate(): IUIAsJson {
	let categories = Configuration.get().getInAppFeedbackLaunchOptions().categories;

	let formSchema = {
		children: [
			{
				attributes: [{ name: Constants.AttributeName.For, value: Constants.IDs.BasicFormComment}],
				classes: [
					Constants.Classes.FontSubtitle,
					Constants.Classes.TextAlignLeft,
					Constants.Classes.FormQuestionMiddleText,
				],
				id: Constants.IDs.BasicFormQuestionMiddleText,
				tag: Constants.Tags.Label,
			},
			{
				attributes: [{ name: Constants.AttributeName.Id, value: Constants.IDs.BasicFormCategoriesDropdown }],
				brs: categories.show,
				children: CategoriesDropdown.generate(categories ? categories.customCategories : null),
				classes: [Constants.Classes.FontSubText, Constants.Classes.FormCategoriesDropdown, Constants.Classes.TextInput],
				tag: Constants.Tags.Select,
			},
			{
				attributes: [
					{
						name: Constants.AttributeName.Placeholder,
						value: UIStrings.getUIStrings().Form.CommentPlaceholder,
					},
					{
						name: Constants.AttributeName.MaxLength,
						value: Constants.AttributeValue.TextAreaMaxLength,
					},
				],
				classes: [Constants.Classes.FontSubText, Constants.Classes.FormComment, Constants.Classes.TextInput],
				id: Constants.IDs.BasicFormComment,
				tag: Constants.Tags.TextArea,
			},
			{
				brs: false, // disable rating for now
				children: [
					{
						attributes: [{ name: Constants.AttributeName.For, value: Constants.IDs.BasicFormRating }],
						classes: [Constants.Classes.FontText, Constants.Classes.TextAlignLeft, Constants.Classes.FormRatingLabel],
						innerText: UIStrings.getUIStrings().Form.RatingLabel,
						tag: Constants.Tags.Label,
					},
					RatingControl.generate(Constants.IDs.BasicFormRating),
				],
				classes: [Constants.Classes.FormRatingContainer],
			},
			{
				attributes: [
					{
						name: Constants.AttributeName.Type,
						value: Constants.AttributeValue.Text,
					},
					{
						name: Constants.AttributeName.Placeholder,
						value: UIStrings.getUIStrings().Form.EmailPlaceholder,
					},
					{
						name: Constants.AttributeName.AriaLabel,
						value: UIStrings.getUIStrings().Form.EmailPlaceholder,
					},
					{
						name: Constants.AttributeName.Name,
						value: Constants.IDs.BasicFormEmailInput,
					},
					{
						name: Constants.AttributeName.MaxLength,
						value: Constants.AttributeValue.TextAreaMaxLength,
					},
					{
						name: Constants.AttributeName.Value,
						value:
							Configuration.get().getInAppFeedbackInitOptions().userEmail
							? Configuration.get().getInAppFeedbackInitOptions().userEmail
							: "",
					},
				],
				classes: [Constants.Classes.FontSubText, Constants.Classes.FormEmailInput, Constants.Classes.TextInput],
				id: Constants.IDs.BasicFormEmailInput,
				tag: Constants.Tags.Input,
			},
			{
				children: [
					{
						children: [
							{
								attributes: [
									{
										name: Constants.AttributeName.Type,
										value: Constants.AttributeValue.Checkbox,
									},
									{
										name: Constants.AttributeName.Value,
										value: Constants.AttributeValue.Unchecked,
									},
								],
								brs: Configuration.get().getInAppFeedbackInitOptions().screenshot,
								classes: [Constants.Classes.FormScreenshotCheckbox, Constants.Classes.CheckBox],
								id: Constants.IDs.BasicFormScreenshotCheckbox,
								tag: Constants.Tags.Input,
							},
							{
								attributes: [{ name: Constants.AttributeName.For, value: Constants.IDs.BasicFormScreenshotCheckbox }],
								brs: Configuration.get().getInAppFeedbackInitOptions().screenshot,
								classes: [Constants.Classes.FontText, Constants.Classes.TextAlignLeft, Constants.Classes.FormScreenshotLabel],
								innerText: UIStrings.getUIStrings().Form.ScreenshotLabel,
								tag: Constants.Tags.Label,
							},
						],
						classes: [Constants.Classes.FormScreenshotContainer],
					},
					{
						children: [
							{
								classes: [
									Constants.Classes.FontSubText,
									Constants.Classes.SubmitButton,
								],
								id: Constants.IDs.BasicFormSubmitButton,
								innerText: UIStrings.getUIStrings().Form.Submit,
								tag: Constants.Tags.Button,
							},
							{
								classes: [Constants.Classes.Spinner, Constants.Classes.Hidden],
								id: Constants.IDs.BasicFormSubmitButtonSpinner,
								tag: Constants.Tags.Div,
							},
						],
						classes: [Constants.Classes.FormSubmitButtonContainer],
					},
				],
				classes: [Constants.Classes.FormBottomContainer],
			},
		],
		classes: [Constants.Classes.Hidden, Constants.Classes.MarginLeft60px],
		id: containerId,
	};
	return formSchema;
}

/**
 * The id for the container. This is the id of the top-most element and should
 * be unique to each form
 */
export let containerId: string = Constants.IDs.BasicFormContainer;

/**
 * Forms may be used for different feedback types. This method executes form
 * specific logic when the feedback type is selected
 * @param feedbackType feedback type
 */
export function onSelect(feedbackType: Constants.FeedbackType): void {

	switch (feedbackType) {
		case Constants.FeedbackType.Smile: {
			document.getElementById(Constants.IDs.BasicFormQuestionMiddleText).textContent =
				UIStrings.getUIStrings().SmileForm.Title;
			break;
		}
		case Constants.FeedbackType.Frown: {
			document.getElementById(Constants.IDs.BasicFormQuestionMiddleText).textContent =
				UIStrings.getUIStrings().FrownForm.Title;
			break;
		}
		case Constants.FeedbackType.Idea: {
			document.getElementById(Constants.IDs.BasicFormQuestionMiddleText).textContent =
				UIStrings.getUIStrings().IdeaForm.Title;
			break;
		}
		case Constants.FeedbackType.Bug: {
			document.getElementById(Constants.IDs.BasicFormQuestionMiddleText).textContent =
				UIStrings.getUIStrings().BugForm.Title;
			break;
		}
		default: {
			break;
		}
	}

	document.getElementById(Constants.IDs.BasicFormComment).focus();
}

/**
 * Form template type
 */
export let type: FormTemplateType = FormTemplateType.Basic;
