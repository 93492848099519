"use strict";
var ISurveyComponent = require("./../Api/ISurveyComponent");
var CommentComponent = /** @class */ (function () {
    function CommentComponent(data) {
        if (!data) {
            throw new Error("data must not be null");
        }
        if (!data.question) {
            throw new Error("data.question must not be null or empty");
        }
        this.data = data;
        this.userComment = "";
    }
    CommentComponent.make = function (data) {
        try {
            return new CommentComponent(data);
        }
        catch (e) {
            return null;
        }
    };
    // @Override
    CommentComponent.prototype.getType = function () {
        return ISurveyComponent.Type.Comment;
    };
    // @Override
    CommentComponent.prototype.getQuestion = function () {
        return this.data.question;
    };
    // @Override
    CommentComponent.prototype.setSubmittedText = function (userComment) {
        this.userComment = userComment;
    };
    // @Override
    CommentComponent.prototype.getSubmittedText = function () {
        return this.userComment;
    };
    // @Override
    CommentComponent.prototype.getDomElements = function (doc) {
        if (!doc) {
            throw new Error("Document must not be null");
        }
        var element = doc.createElement(ISurveyComponent.DOM_COMMENT_TAGNAME);
        element.appendChild(doc.createTextNode(this.getSubmittedText()));
        return [element];
    };
    // @Override
    CommentComponent.prototype.getJsonElements = function () {
        var result = {};
        result[ISurveyComponent.JSON_COMMENT_KEYNAME] = this.getSubmittedText();
        return result;
    };
    return CommentComponent;
}());
(function (CommentComponent) {
    var CommentComponentData = /** @class */ (function () {
        function CommentComponentData() {
        }
        return CommentComponentData;
    }());
    CommentComponent.CommentComponentData = CommentComponentData;
})(CommentComponent || (CommentComponent = {}));
module.exports = CommentComponent;
