"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Basic structure used to configure an ActivityTracker
 */
var ActivityTrackingData = /** @class */ (function () {
    function ActivityTrackingData(activity, count, isAggregate) {
        this.activity = activity;
        this.count = count;
        this.isAggregate = isAggregate;
    }
    ActivityTrackingData.prototype.getActivity = function () {
        return this.activity;
    };
    ActivityTrackingData.prototype.getCount = function () {
        return this.count;
    };
    ActivityTrackingData.prototype.setCount = function (count) {
        this.count = count;
    };
    ActivityTrackingData.prototype.getIsAggregate = function () {
        return this.isAggregate;
    };
    return ActivityTrackingData;
}());
exports.ActivityTrackingData = ActivityTrackingData;
