/**
 * App_Common.ts
 *
 * Common logic for entrypoints.
 */

import * as UIStrings from "./UIStrings/UIStrings";
import Version from "./Version";
import * as Configuration from "./Configuration/Configuration";
import * as Window from "./Window/Window";
import * as Logging from "./Logging/Logging";
import * as Theme from "./Theme";

import { Promise } from "es6-promise";
import { loadStylesheet, loadScript } from "./Window/DomUtils";

const APP_NAME: string = "OfficeFeedbackSDK";

/**
 * Has the SDK been initialized
 */
let initialized: boolean = false;

/**
 * Initialize common modules
 * @param initOptions init options
 */
function initializeModules(initOptions: Configuration.IInitOptionsCommon): void {
	Logging.initialize(initOptions.environment, APP_NAME, Version,
		(initOptions.telemetryGroup) ? initOptions.telemetryGroup.audienceGroup : undefined,
		initOptions.appId.toString(), initOptions.sessionId, initOptions.build,
		Configuration.get().getSdkSessionId());
	Theme.initialize(initOptions.primaryColour, initOptions.secondaryColour);
}

/**
 * Set ui Strings.
 * @param data the ui strings
 */
function setUIStrings(data: any): void {
	UIStrings.setUIStrings(data);
};

/**
 * Initialize
 */
export function initialize(): Promise<any> {
	return new Promise((resolve, reject) => {
		if (!initialized) {
			if (!Window.get().OfficeBrowserFeedback.initOptions) {
				reject("Window.OfficeBrowserFeedback.initOptions not set");
				return;
			}

			Window.get().OfficeBrowserFeedback.sdkVersion = Version;

			Configuration.get().setCommonInitOptions(Window.get().OfficeBrowserFeedback.initOptions);

			let initOptionsCommon = Configuration.get().getCommonInitOptions();
			loadStylesheet(initOptionsCommon.stylesUrl);

			let intlFileUrl: string = initOptionsCommon.intlUrl + initOptionsCommon.locale.toLowerCase() + "/" +
				initOptionsCommon.intlFilename;

			loadScript(intlFileUrl)
			.then(
				function onLoadScriptFulfilled() {
					if (!UIStrings.getUIStrings()) {
						reject("UiStrings were not loaded from " + intlFileUrl);
						return;
					}

					initializeModules(initOptionsCommon);
					initialized = true;

					resolve();
				}
			).catch(
				function onLoadScriptRejected(err) {
					reject("Script load failed for " + intlFileUrl);
				}
			);
		} else {
			resolve();
		}
	});
}

/**
 * Reset the module. Used in unit tests.
 */
export function reset() {
	initialized = false;
}

/* Make the setUIStrings method available globally */
Window.setSetUiStrings(setUIStrings);
