/**
 * ChoiceGroupControl.ts
 *
 * A module for rendering a choice group.
 */

import * as Constants from "./../Constants";
import * as Renderer from "./Renderer";

export function generateRadioGroup(id: string, question: string,
	choices: [number, string][]): Renderer.IUIAsJson {
	let fieldSet: Renderer.IUIAsJson = {
		children: [
			{
				classes: [Constants.Classes.FontSubText],
				id: Constants.IDs.TFormRatingQuestion,
				innerText: question,
				tag: Constants.Tags.Legend,
				attributes: [{name: "tabindex", value: "0"}],
			},
		],
		tag: Constants.Tags.FieldSet,
	};

	for (let choice of choices) {
		Array.prototype.push.apply(fieldSet.children, generateRadioButton(choice[0].toString(), choice[1], id));
	}

	return {
		children: [fieldSet],
		classes: [Constants.Classes.ChoiceGroup],
		id: id,
	};
}

function generateRadioButton(value: string, label: string, id: string): Renderer.IUIAsJson[] {
	return [
		{
			attributes: [
				{ name: Constants.AttributeName.Type, value: Constants.AttributeValue.Radio },
				{ name: Constants.AttributeName.Value, value: value },
				{ name: Constants.AttributeName.Name, value: id },
			],
			id: value,
			tag: Constants.Tags.Input,
		},
		{
			attributes: [{ name: Constants.AttributeName.For, value: value }],
			children: [
				{
					children: [{ tag: Constants.Tags.Span }],
					classes: [Constants.Classes.ChoiceGroupIcon],
					tag: Constants.Tags.Span,
				},
				{
					classes: ["obf-ChoiceGroupLabel"],
					innerHTML: label,
					tag: Constants.Tags.Span,
				},
			],
			classes: [Constants.Classes.FontSubText],
			tag: Constants.Tags.Label,
		},
	];
}
