/**
 * Layout.ts
 *
 * Module for the layout
 */

import * as Renderer from "./../Renderer";
import * as ChoiceGroupControl from "./../ChoiceGroupControl";
import * as Configuration from "./../../Configuration/Configuration";
import { IDs, Classes, AttributeName, AttributeValue, Tags, Urls } from "./../../Constants";
import * as UIStrings from "./../../UIStrings/UIStrings";
import * as Utils from "./../Utils";
import * as Constants from "./../../Constants";

/**
 * Generates the toast
 *
 * @returns {Renderer.IUIAsJson} Renderer.IUIAsJson object
 */
export function generate(): Renderer.IUIAsJson {
	let schema: Renderer.IUIAsJson = {
		attributes: [
			{ name: AttributeName.DataHtml2CanvasIgnore, value: AttributeValue.True },
			{ name: AttributeName.Role, value: "alert" },
			{ name: "aria-labelledby", value: IDs.TPromptTitle },
			{ name: "aria-describedby", value: IDs.TPromptText },
		],
		children: [
			{
				attributes: [
					{ name: AttributeName.Type, value: AttributeValue.Button },
					{ name: AttributeName.AriaLabel, value: UIStrings.getUIStrings().CloseLabel },
				],
				id: IDs.ToastCancel,
				// tslint:disable-next-line:max-line-length
				innerHTML: '<svg viewBox="4 4 16 16" width="16px" height="16px" focusable="false"><path d="M19,6.41L17.59,5 12,10.59 6.41,5 5,6.41 10.59,12 5,17.59 6.41,19 12,13.41 17.59,19 19,17.59 13.41,12z" fill="#000"/></svg>',
				tag: Tags.Button,
			},
			generatePrompt(),
			generateForm(),
		],
		classes: [Classes.Toast, Utils.isRightToLeft() ? Classes.Rtl : ""],
		id: IDs.ToastContainer,
	};
	return schema;
}

function generatePrompt(): Renderer.IUIAsJson {
	let promptSchema: Renderer.IUIAsJson = {
		attributes: [{ name: "aria-labelledby", value: IDs.TPromptTitle }],
		children: [
			{
				classes: [
					Classes.FontText,
					Classes.TextAlignLeft,
				],
				id: IDs.TPromptTitle,
				innerText: Configuration.get().getFloodgateSurvey().getTitle(),
			},
			{
				classes: [
					Classes.FontSubText,
					Classes.TextAlignLeft,
				],
				id: IDs.TPromptText,
				innerText: Configuration.get().getFloodgateSurvey().getPromptQuestion(),
			},
		],
		id: IDs.TPromptContainer,
		tag: Tags.Button,
	};

	return promptSchema;
}

function userEmailConsentDefault(): string {
	let userEmailConsentDefault = Configuration.get().getCommonInitOptions().userEmailConsentDefault;

	return userEmailConsentDefault ? Constants.AttributeValue.Checked : Constants.AttributeValue.Unchecked;
}

function generateForm(): Renderer.IUIAsJson {
	let formSchema: Renderer.IUIAsJson = {
		children: [
			{
				classes: [
					Classes.FontText,
					Classes.TextAlignLeft,
				],
				id: IDs.TFormTitle,
				innerText: Configuration.get().getFloodgateSurvey().getTitle(),
			},
			generateRatingControl(),
			{
				attributes: [
					{
						name: AttributeName.Placeholder,
						value: Configuration.get().getFloodgateSurvey().getCommentQuestion(),
					},
					{
						name: AttributeName.AriaLabel,
						value: Configuration.get().getFloodgateSurvey().getCommentQuestion(),
					},
					{
						name: AttributeName.MaxLength,
						value: AttributeValue.TextAreaMaxLength,
					},
				],
				classes: [Classes.FontSubText, Classes.TextInput],
				id: IDs.TFormComment,
				tag: Tags.TextArea,
			},
			{
				brs: Configuration.get().getFloodgateSurvey().showEmailRequest,
				children: [
					{
						attributes: [
							{
								name: Constants.AttributeName.Type,
								value: Constants.AttributeValue.Checkbox,
							},
							{
								name: userEmailConsentDefault(),
								value: "",
							},
						],
						classes: [Constants.Classes.TFormEmailCheckbox, Constants.Classes.CheckBox],
						id: Constants.IDs.TFormEmailCheckBox,
						tag: Constants.Tags.Input,
					},
					{
						attributes: [{ name: Constants.AttributeName.For, value: Constants.IDs.TFormEmailCheckBox }],
						classes: [Constants.Classes.FontSubText, Constants.Classes.TextAlignLeft, Constants.Classes.TFormEmailLabel],
						innerText: UIStrings.getUIStrings().Form.EmailCheckBoxLabel,
						tag: Constants.Tags.Label,
					},
					{
						attributes: [
							{
								name: Constants.AttributeName.Type,
								value: Constants.AttributeValue.Text,
							},
							{
								name: Constants.AttributeName.Placeholder,
								value: UIStrings.getUIStrings().Form.EmailPlaceholder,
							},
							{
								name: Constants.AttributeName.AriaLabel,
								value: UIStrings.getUIStrings().Form.EmailPlaceholder,
							},
							{
								name: Constants.AttributeName.Name,
								value: Constants.IDs.BasicFormEmailInput,
							},
							{
								name: Constants.AttributeName.MaxLength,
								value: Constants.AttributeValue.TextAreaMaxLength,
							},
							{
								name: Constants.AttributeName.Value,
								value: Configuration.get().getCommonInitOptions().userEmailConsentDefault
								? Configuration.get().getCommonInitOptions().userEmail
								:  "",
							},
						],
						classes: [Constants.Classes.FontSubText, Constants.Classes.FormEmailInput, Constants.Classes.TextInput],
						id: Constants.IDs.TFormEmailTextBox,
						tag: Constants.Tags.Input,
					},
				],
			},
			{
				children:
					[
						{
							attributes: [
								{
									name: AttributeName.HRef,
									value: Urls.PrivacyStatementLink,
								},
								{
									name: AttributeName.Target,
									value: AttributeValue.BlankWindow,
								},
								{
									name: AttributeName.Rel,
									value: AttributeValue.NoReferrer,
								},
							],
							classes: [Classes.Link],
							innerText: UIStrings.getUIStrings().PrivacyStatement,
							tag: Tags.Anchor,
						},
					],
				classes: [Classes.FontSubSubText, Classes.TextAlignLeft, Classes.PrivacyStatementLinkDiv],
			},
			{
				children: [
					{
						attributes: [{ name: AttributeName.Disabled, value: AttributeValue.True }],
						classes: [
							Classes.FontSubText,
							Classes.SubmitButton,
						],
						id: IDs.TFormSubmitButton,
						innerText: UIStrings.getUIStrings().Form.Submit,
						tag: Tags.Button,
					},
					{
						classes: [Classes.Spinner, Classes.Hidden],
						id: IDs.TFormSubmitButtonSpinner,
						tag: Tags.Div,
					},
				],
				id: IDs.TFormSubmitButtonContainer,
			},
		],
		classes: [Classes.Hidden],
		id: IDs.TFormContainer,
	};

	return formSchema;
}

function generateRatingControl(): Renderer.IUIAsJson {
	let choices: [number, string][] = [];

	let ratingValues: string[] = Configuration.get().getFloodgateSurvey().getRatingValuesAscending();
	let index = 0;

	for (let ratingValue of ratingValues) {
		choices.push([index, ratingValue]);
		index++;
	}

	// Ratings need to be shown in descending order
	return ChoiceGroupControl.generateRadioGroup(IDs.TFormRating,
		Configuration.get().getFloodgateSurvey().getRatingQuestion(), choices.reverse());
}
