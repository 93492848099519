"use strict";
var IPrompt = require("./../Api/IPrompt");
var ISurveyComponent = require("./../Api/ISurveyComponent");
var PromptComponent = /** @class */ (function () {
    function PromptComponent(data) {
        if (!data) {
            throw new Error("data must not be null");
        }
        if (!data.question) {
            throw new Error("data.question must not be null or empty");
        }
        if (!data.title) {
            throw new Error("data.title must not be null or empty");
        }
        if (!data.yesButtonLabel) {
            throw new Error("data.yesButtonLabel must not be null or empty");
        }
        if (!data.noButtonLabel) {
            throw new Error("data.noButtonLabel must not be null or empty");
        }
        this.data = data;
        this.selectedButton = IPrompt.PromptButton.Unselected;
    }
    PromptComponent.make = function (data) {
        try {
            return new PromptComponent(data);
        }
        catch (e) {
            return null;
        }
    };
    // @Override
    PromptComponent.prototype.getType = function () {
        return ISurveyComponent.Type.Prompt;
    };
    // @Override
    PromptComponent.prototype.getTitle = function () {
        return this.data.title;
    };
    // @Override
    PromptComponent.prototype.getQuestion = function () {
        return this.data.question;
    };
    // @Override
    PromptComponent.prototype.getYesButtonText = function () {
        return this.data.yesButtonLabel;
    };
    // @Override
    PromptComponent.prototype.getNoButtonText = function () {
        return this.data.noButtonLabel;
    };
    // @Override
    PromptComponent.prototype.setButtonSelected = function (selected) {
        if (selected) {
            this.selectedButton = selected;
        }
    };
    // @Override
    PromptComponent.prototype.getButtonSelected = function () {
        return this.selectedButton;
    };
    // @Override
    PromptComponent.prototype.getDomElements = function (doc) {
        if (!doc) {
            throw new Error("Document must not be null");
        }
        var element = doc.createElement(ISurveyComponent.DOM_PROMPT_TAGNAME);
        element.appendChild(doc.createTextNode(this.promptButtonToString(this.getButtonSelected())));
        return [element];
    };
    // @Override
    PromptComponent.prototype.getJsonElements = function () {
        var result = {};
        result[ISurveyComponent.JSON_PROMPT_KEYNAME] = this.promptButtonToString(this.getButtonSelected());
        return result;
    };
    PromptComponent.prototype.promptButtonToString = function (value) {
        switch (value) {
            case IPrompt.PromptButton.Unselected:
                return "Unselected";
            case IPrompt.PromptButton.Yes:
                return "Yes";
            case IPrompt.PromptButton.No:
                return "No";
            default:
                return "Unknown";
        }
    };
    return PromptComponent;
}());
(function (PromptComponent) {
    var PromptComponentData = /** @class */ (function () {
        function PromptComponentData() {
        }
        return PromptComponentData;
    }());
    PromptComponent.PromptComponentData = PromptComponentData;
})(PromptComponent || (PromptComponent = {}));
module.exports = PromptComponent;
