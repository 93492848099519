"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var IFloodgateStorageProvider = require("../Api/IFloodgateStorageProvider");
var FloodgateEngine_1 = require("../FloodgateEngine");
var GovernedChannel_1 = require("../GovernedChannel");
var ISurveyInfo = require("../ISurveyInfo");
var Utils = require("../Utils");
var es6_promise_1 = require("es6-promise");
// region Language Range
/**
 * Base class representing a language range in a campaign setting
 */
var CampaignLanguageRange = /** @class */ (function () {
    function CampaignLanguageRange() {
    }
    CampaignLanguageRange.deserialize = function (input) {
        var result;
        if (input && input.Type === 0) {
            result = CampaignLanguageRangeLanguageSubtag.deserialize(input);
        }
        if (result === undefined) {
            return null;
        }
        return result;
    };
    CampaignLanguageRange.prototype.validate = function () {
        return true;
    };
    return CampaignLanguageRange;
}());
exports.CampaignLanguageRange = CampaignLanguageRange;
/**
 * Represents a range matching on the "language subtag" against fully specified language tags, according to RFC 5646
 */
var CampaignLanguageRangeLanguageSubtag = /** @class */ (function (_super) {
    __extends(CampaignLanguageRangeLanguageSubtag, _super);
    // public for UT only
    function CampaignLanguageRangeLanguageSubtag() {
        return _super.call(this) || this;
    }
    CampaignLanguageRangeLanguageSubtag.deserialize = function (input) {
        var result = new CampaignLanguageRangeLanguageSubtag();
        if (input) {
            result.languageSubTag = input.Value;
        }
        if (!result.validate()) {
            return null;
        }
        return result;
    };
    // @Override
    CampaignLanguageRangeLanguageSubtag.prototype.isInRange = function (language) {
        if (Utils.isNullOrUndefined(language)) {
            return false;
        }
        var extractedLanguageSubTag = Utils.extractLanguageSubtag(language);
        if (Utils.isNullOrUndefined(extractedLanguageSubTag)) {
            return false;
        }
        // compare strings ignoring case
        return extractedLanguageSubTag.toLocaleUpperCase() === this.languageSubTag.toLocaleUpperCase();
    };
    // @Override
    CampaignLanguageRangeLanguageSubtag.prototype.validate = function () {
        if (!_super.prototype.validate.call(this)) {
            return false;
        }
        if (Utils.isNullOrUndefined(this.languageSubTag) || !Utils.isString(this.languageSubTag)) {
            return false;
        }
        // Reject any spec that isn't exactly a language subtag
        if (!Utils.isValidLanguageSubTag(this.languageSubTag)) {
            return false;
        }
        return true;
    };
    return CampaignLanguageRangeLanguageSubtag;
}(CampaignLanguageRange));
exports.CampaignLanguageRangeLanguageSubtag = CampaignLanguageRangeLanguageSubtag;
// endregion
// region Scope
var CampaignScope = /** @class */ (function () {
    function CampaignScope() {
    }
    CampaignScope.deserialize = function (input) {
        var result;
        if (Utils.isNullOrUndefined(input)) {
            return null;
        }
        switch (input.Type) {
            case 0:
                result = CampaignScopeAny.deserialize(input);
                break;
            case 1:
                result = CampaignScopeEnvironmentCrossProduct.deserialize(input);
                break;
        }
        if (result === undefined) {
            return null;
        }
        return result;
    };
    CampaignScope.prototype.validate = function () {
        return true;
    };
    return CampaignScope;
}());
exports.CampaignScope = CampaignScope;
var CampaignScopeAny = /** @class */ (function (_super) {
    __extends(CampaignScopeAny, _super);
    // public for UT only
    function CampaignScopeAny() {
        return _super.call(this) || this;
    }
    CampaignScopeAny.deserialize = function (input) {
        return new CampaignScopeAny();
    };
    // @Override
    CampaignScopeAny.prototype.isInScope = function (environmentProvider) {
        return true;
    };
    // @Override
    CampaignScopeAny.prototype.validate = function () {
        if (!_super.prototype.validate.call(this)) {
            return false;
        }
        return true;
    };
    return CampaignScopeAny;
}(CampaignScope));
exports.CampaignScopeAny = CampaignScopeAny;
var CampaignScopeEnvironmentCrossProduct = /** @class */ (function (_super) {
    __extends(CampaignScopeEnvironmentCrossProduct, _super);
    // public for UT only
    function CampaignScopeEnvironmentCrossProduct() {
        return _super.call(this) || this;
    }
    CampaignScopeEnvironmentCrossProduct.deserialize = function (input) {
        var result = new CampaignScopeEnvironmentCrossProduct();
        var languageRanges = [];
        if (Utils.isNullOrUndefined(input)) {
            return null;
        }
        for (var key in input.Languages) {
            if (input.Languages.hasOwnProperty(key)) {
                var readRange = input.Languages[key];
                if (readRange) {
                    languageRanges.push(CampaignLanguageRange.deserialize(readRange));
                }
            }
        }
        if (languageRanges.length === 0) {
            languageRanges = null;
        }
        result.languageRanges = languageRanges;
        if (!result.validate()) {
            return null;
        }
        return result;
    };
    // @Override
    CampaignScopeEnvironmentCrossProduct.prototype.isInScope = function (environmentProvider) {
        if (Utils.isNullOrUndefined(this.languageRanges)) {
            return true;
        }
        if (Utils.isNullOrUndefined(environmentProvider) || !environmentProvider.getLanguage()) {
            return false;
        }
        // If the environment matches a single pattern, then this is a yes
        for (var key in this.languageRanges) {
            if (this.languageRanges.hasOwnProperty(key)) {
                var range = this.languageRanges[key];
                if (range.isInRange(environmentProvider.getLanguage())) {
                    return true;
                }
            }
        }
        return false;
    };
    // @Override
    CampaignScopeEnvironmentCrossProduct.prototype.validate = function () {
        if (!_super.prototype.validate.call(this)) {
            return false;
        }
        // Null languageRanges is allowed, means will match with everything.
        if (this.languageRanges) {
            for (var key in this.languageRanges) {
                if (this.languageRanges.hasOwnProperty(key) && !this.languageRanges[key]) {
                    return false;
                }
            }
        }
        return true;
    };
    return CampaignScopeEnvironmentCrossProduct;
}(CampaignScope));
exports.CampaignScopeEnvironmentCrossProduct = CampaignScopeEnvironmentCrossProduct;
// endregion
// region Durations
/**
 * Base class representing a duration in a campaign setting
 */
var CampaignDuration = /** @class */ (function () {
    function CampaignDuration() {
    }
    CampaignDuration.deserialize = function (input) {
        var result;
        if (Utils.isNullOrUndefined(input)) {
            return null;
        }
        switch (input.Type) {
            case 0:
                result = CampaignDurationTimeInterval.deserialize(input);
                break;
            case 1:
                result = CampaignDurationSingleBuildChange.deserialize(input);
                break;
        }
        if (result === undefined) {
            return null;
        }
        return result;
    };
    CampaignDuration.prototype.validate = function () {
        return true;
    };
    return CampaignDuration;
}());
exports.CampaignDuration = CampaignDuration;
/**
 * A Duration expressed in seconds. UTC datetime comparisons are used to determine expiration.
 */
var CampaignDurationTimeInterval = /** @class */ (function (_super) {
    __extends(CampaignDurationTimeInterval, _super);
    // public for UT only
    function CampaignDurationTimeInterval() {
        return _super.call(this) || this;
    }
    CampaignDurationTimeInterval.deserialize = function (input) {
        var result = new CampaignDurationTimeInterval();
        if (input) {
            result.intervalSeconds = input.IntervalSeconds;
        }
        if (!result.validate()) {
            return null;
        }
        return result;
    };
    // @Override
    CampaignDurationTimeInterval.prototype.asTimeIntervalSeconds = function () {
        return this.intervalSeconds;
    };
    // @Override
    CampaignDurationTimeInterval.prototype.validate = function () {
        if (!_super.prototype.validate.call(this)) {
            return false;
        }
        if (!Utils.isNumber(this.intervalSeconds)) {
            return false;
        }
        return true;
    };
    return CampaignDurationTimeInterval;
}(CampaignDuration));
exports.CampaignDurationTimeInterval = CampaignDurationTimeInterval;
/**
 * A Duration representing exactly 1 change in build number
 */
var CampaignDurationSingleBuildChange = /** @class */ (function (_super) {
    __extends(CampaignDurationSingleBuildChange, _super);
    // public for UT only
    function CampaignDurationSingleBuildChange() {
        return _super.call(this) || this;
    }
    CampaignDurationSingleBuildChange.deserialize = function (input) {
        return new CampaignDurationSingleBuildChange();
    };
    // @Override
    CampaignDurationSingleBuildChange.prototype.asTimeIntervalSeconds = function () {
        return null;
    };
    return CampaignDurationSingleBuildChange;
}(CampaignDuration));
exports.CampaignDurationSingleBuildChange = CampaignDurationSingleBuildChange;
/**
 * Class representing AdditionalDataRequested in CampaignDefinition
 */
var CampaignAdditionalDataRequested = /** @class */ (function () {
    function CampaignAdditionalDataRequested() {
    }
    CampaignAdditionalDataRequested.deserialize = function (additionalDataRequested) {
        var result = new CampaignAdditionalDataRequested();
        if (!Utils.isNullOrUndefined(additionalDataRequested)) {
            // Convert from AdditionalDataRequested string to enum
            result.additionalData = [];
            for (var _i = 0, additionalDataRequested_1 = additionalDataRequested; _i < additionalDataRequested_1.length; _i++) {
                var additionalData = additionalDataRequested_1[_i];
                if (additionalData === "EmailAddress") {
                    result.additionalData.push(ISurveyInfo.AdditionalDataType.EmailAddress);
                }
            }
        }
        if (!result.validate()) {
            return null;
        }
        return result;
    };
    CampaignAdditionalDataRequested.prototype.validate = function () {
        if (Utils.isNullOrUndefined(this.additionalData)) {
            return false;
        }
        return true;
    };
    return CampaignAdditionalDataRequested;
}());
exports.CampaignAdditionalDataRequested = CampaignAdditionalDataRequested;
// endregion
// region Distribution Schemes
var CampaignDistribution = /** @class */ (function () {
    function CampaignDistribution() {
    }
    CampaignDistribution.deserialize = function (input) {
        var result;
        if (input && input.Type === 0) {
            result = CampaignDistributionRamp.deserialize(input);
        }
        if (result === undefined) {
            return null;
        }
        return result;
    };
    CampaignDistribution.prototype.validate = function () {
        return true;
    };
    return CampaignDistribution;
}());
exports.CampaignDistribution = CampaignDistribution;
var CampaignDistributionRamp = /** @class */ (function (_super) {
    __extends(CampaignDistributionRamp, _super);
    function CampaignDistributionRamp() {
        return _super.call(this) || this;
    }
    CampaignDistributionRamp.deserialize = function (input) {
        var result = new CampaignDistributionRamp();
        if (input) {
            result.maxDelaySeconds = input.MaxDelaySeconds;
            result.chunks = input.Chunks;
        }
        if (!result.validate()) {
            return null;
        }
        return result;
    };
    // @Override
    CampaignDistributionRamp.prototype.validate = function () {
        if (!_super.prototype.validate.call(this)) {
            return false;
        }
        if (!Utils.isNumber(this.maxDelaySeconds) || this.maxDelaySeconds < 0) {
            return false;
        }
        if (!Utils.isNumber(this.chunks) || this.chunks < 0) {
            return false;
        }
        return true;
    };
    return CampaignDistributionRamp;
}(CampaignDistribution));
exports.CampaignDistributionRamp = CampaignDistributionRamp;
// endregion
// region Nomination Scheme
var CampaignNominationScheme = /** @class */ (function () {
    function CampaignNominationScheme() {
    }
    CampaignNominationScheme.deserialize = function (input) {
        var result;
        if (input && input.Type === 0) {
            result = CampaignNominationSchemeRatioPercentage.deserialize(input);
        }
        if (result === undefined) {
            return null;
        }
        return result;
    };
    /**
     * @return The amount of time the survey should be active, in seconds. Abstracts the difference between nominationPeriod and the fallback
     */
    CampaignNominationScheme.prototype.getActiveSurveyTimeIntervalSeconds = function () {
        var nominationTimIntervalSeconds = this.nominationPeriod.asTimeIntervalSeconds();
        if (Utils.isNumber(nominationTimIntervalSeconds)) {
            return nominationTimIntervalSeconds;
        }
        return this.fallbackSurveyDurationSeconds;
    };
    /**
     * @return The survey start time, possibly adjusted by an underlying DistributionModel.
     */
    CampaignNominationScheme.prototype.calculateSurveyStartTimeFromDate = function (soonestStartTime) {
        // No adjustment in base class.  Derived classes can override and implement delays like a "ramp"
        return soonestStartTime ? soonestStartTime : new Date();
    };
    /**
     * @return The survey expiration time, based on the nomination periods and the survey's adjusted start time
     */
    CampaignNominationScheme.prototype.calculateSurveyExpirationTimeFromSurveyStartTime = function (surveyStartTime) {
        surveyStartTime = surveyStartTime ? surveyStartTime : new Date();
        return Utils.addSecondsWithoutOverflow(surveyStartTime, this.getActiveSurveyTimeIntervalSeconds());
    };
    /**
     * @return The appropriate campaign cool down based on whether or not the user activated the survey
     */
    CampaignNominationScheme.prototype.getCampaignCooldown = function (didUserActivateSurvey) {
        if (didUserActivateSurvey) {
            return this.cooldownPeriod;
        }
        else {
            return this.nominationPeriod;
        }
    };
    CampaignNominationScheme.prototype.validate = function () {
        if (Utils.isNullOrUndefined(this.nominationPeriod)) {
            return false;
        }
        if (Utils.isNullOrUndefined(this.cooldownPeriod)) {
            this.cooldownPeriod = this.nominationPeriod;
        }
        if (!Utils.isNumber(this.nominationPeriod.asTimeIntervalSeconds())) {
            if (!Utils.isNumber(this.fallbackSurveyDurationSeconds) || this.fallbackSurveyDurationSeconds <= 0) {
                return false;
            }
        }
        return true;
    };
    return CampaignNominationScheme;
}());
exports.CampaignNominationScheme = CampaignNominationScheme;
/**
 * Class representing a set of campaign nomination rules to evaluate for in-scope campaigns:
 * percentage
 * re-election durations
 * distribution model (for "nominated" candidates)
 */
var CampaignNominationSchemeRatioPercentage = /** @class */ (function (_super) {
    __extends(CampaignNominationSchemeRatioPercentage, _super);
    function CampaignNominationSchemeRatioPercentage() {
        return _super.call(this) || this;
    }
    CampaignNominationSchemeRatioPercentage.deserialize = function (input) {
        var result = new CampaignNominationSchemeRatioPercentage();
        if (Utils.isNullOrUndefined(input)) {
            return null;
        }
        if (input.DistributionModel) {
            result.distributionModel = CampaignDistribution.deserialize(input.DistributionModel);
        }
        if (input.CooldownPeriod) {
            result.cooldownPeriod = CampaignDuration.deserialize(input.CooldownPeriod);
        }
        if (input.NominationPeriod) {
            result.nominationPeriod = CampaignDuration.deserialize(input.NominationPeriod);
        }
        result.fallbackSurveyDurationSeconds = input.FallbackSurveyDurationSeconds;
        result.percentageDenominator = input.PercentageDenominator;
        result.percentageNumerator = input.PercentageNumerator;
        if (!result.validate()) {
            return null;
        }
        return result;
    };
    // @Override
    CampaignNominationSchemeRatioPercentage.prototype.evaluateNominationRules = function () {
        var rand = Math.random();
        // Creates a random number between [0 to (percentageDenominator - 1)]
        // In range [0 to (percentageDenominator - 1)] exactly 'percentageNumerator' values are < percentageNumerator
        return Math.floor(rand * this.percentageDenominator) < this.percentageNumerator;
    };
    // @Override
    CampaignNominationSchemeRatioPercentage.prototype.validate = function () {
        if (!_super.prototype.validate.call(this)) {
            return false;
        }
        if (!Utils.isNumber(this.percentageDenominator) || !Utils.isNumber(this.percentageNumerator)) {
            return false;
        }
        if (this.percentageDenominator <= 0 || this.percentageNumerator < 0) {
            return false;
        }
        if (this.percentageNumerator > this.percentageDenominator) {
            return false;
        }
        // distribution model may be null
        return true;
    };
    return CampaignNominationSchemeRatioPercentage;
}(CampaignNominationScheme));
exports.CampaignNominationSchemeRatioPercentage = CampaignNominationSchemeRatioPercentage;
// endregion
// region Survey Event Definitions
var CampaignSurveyEvent = /** @class */ (function () {
    function CampaignSurveyEvent() {
    }
    CampaignSurveyEvent.deserialize = function (input) {
        var result;
        if (Utils.isNullOrUndefined(input)) {
            return null;
        }
        switch (input.Type) {
            case 0:
                result = CampaignSurveyEventCountedActivity.deserialize(input);
                break;
            case 1:
                result = CampaignSurveyEventCountedActivitySequence.deserialize(input);
                break;
        }
        if (result === undefined) {
            return null;
        }
        return result;
    };
    CampaignSurveyEvent.prototype.validate = function () {
        return true;
    };
    return CampaignSurveyEvent;
}());
exports.CampaignSurveyEvent = CampaignSurveyEvent;
var CampaignSurveyEventCountedActivity = /** @class */ (function (_super) {
    __extends(CampaignSurveyEventCountedActivity, _super);
    // public for UT only
    function CampaignSurveyEventCountedActivity() {
        return _super.call(this) || this;
    }
    CampaignSurveyEventCountedActivity.deserialize = function (input) {
        var result = new CampaignSurveyEventCountedActivity();
        if (Utils.isNullOrUndefined(input)) {
            return null;
        }
        result.activity = input.Activity;
        result.count = input.Count;
        result.isAggregate = input.IsAggregate;
        if (!result.validate()) {
            return null;
        }
        return result;
    };
    // @Override
    CampaignSurveyEventCountedActivity.prototype.validate = function () {
        if (!_super.prototype.validate.call(this)) {
            return false;
        }
        if (Utils.isNullOrUndefined(this.activity) || !Utils.isString(this.activity)) {
            return false;
        }
        if (!Utils.isNumber(this.count) || this.count <= 0) {
            return false;
        }
        if (!Utils.isBoolean(this.isAggregate)) {
            return false;
        }
        return true;
    };
    return CampaignSurveyEventCountedActivity;
}(CampaignSurveyEvent));
exports.CampaignSurveyEventCountedActivity = CampaignSurveyEventCountedActivity;
var CampaignSurveyEventCountedActivitySequence = /** @class */ (function (_super) {
    __extends(CampaignSurveyEventCountedActivitySequence, _super);
    // public for UT only
    function CampaignSurveyEventCountedActivitySequence() {
        return _super.call(this) || this;
    }
    CampaignSurveyEventCountedActivitySequence.deserialize = function (input) {
        var result = new CampaignSurveyEventCountedActivitySequence();
        var sequence = [];
        if (Utils.isNullOrUndefined(input)) {
            return null;
        }
        for (var key in input.Sequence) {
            if (input.Sequence.hasOwnProperty(key)) {
                var readActivity = input.Sequence[key];
                if (readActivity) {
                    sequence.push(CampaignSurveyEventCountedActivity.deserialize(readActivity));
                }
            }
        }
        if (sequence.length === 0) {
            sequence = null;
        }
        result.sequence = sequence;
        if (!result.validate()) {
            return null;
        }
        return result;
    };
    // @Override
    CampaignSurveyEventCountedActivitySequence.prototype.validate = function () {
        if (!_super.prototype.validate.call(this)) {
            return false;
        }
        if (Utils.isNullOrUndefined(this.sequence)) {
            return false;
        }
        for (var key in this.sequence) {
            if (this.sequence.hasOwnProperty(key) && !this.sequence[key]) {
                return false;
            }
        }
        return true;
    };
    return CampaignSurveyEventCountedActivitySequence;
}(CampaignSurveyEvent));
exports.CampaignSurveyEventCountedActivitySequence = CampaignSurveyEventCountedActivitySequence;
// endregion
// region Survey Content Definitions
var CampaignSurveyContent = /** @class */ (function () {
    function CampaignSurveyContent() {
    }
    CampaignSurveyContent.deserialize = function (input, optionalComponents) {
        var result = new CampaignSurveyContent();
        if (Utils.isNullOrUndefined(input)) {
            return null;
        }
        result.prompt = CampaignSurveyContentPrompt.deserialize(input.Prompt);
        result.rating = CampaignSurveyContentRating.deserialize(input.Rating);
        result.comment = CampaignSurveyContentComment.deserialize(input.Question);
        result.multipleChoice = CampaignSurveyContentMultipleChoice.deserialize(input.MultipleChoice);
        if (!result.validate(optionalComponents)) {
            return null;
        }
        return result;
    };
    CampaignSurveyContent.prototype.validate = function (optionalComponents) {
        if (Utils.isNullOrUndefined(this.prompt)) {
            return false;
        }
        if (optionalComponents) {
            return !Utils.isNullOrUndefined(this.rating) ||
                !Utils.isNullOrUndefined(this.multipleChoice) ||
                !Utils.isNullOrUndefined(this.comment);
        }
        return !Utils.isNullOrUndefined(this.rating) && !Utils.isNullOrUndefined(this.comment);
    };
    return CampaignSurveyContent;
}());
exports.CampaignSurveyContent = CampaignSurveyContent;
var CampaignSurveyContentMultipleChoice = /** @class */ (function () {
    function CampaignSurveyContentMultipleChoice() {
    }
    CampaignSurveyContentMultipleChoice.deserialize = function (input) {
        var result = new CampaignSurveyContentMultipleChoice();
        if (Utils.isNullOrUndefined(input)) {
            return null;
        }
        result.question = input.Question;
        result.availableOptions = input.AvailableOptions;
        result.minNumberOfSelectedOptions = input.MinNumberOfSelectedOptions;
        result.maxNumberOfSelectedOptions = input.MaxNumberOfSelectedOptions;
        if (!result.validate()) {
            return null;
        }
        return result;
    };
    CampaignSurveyContentMultipleChoice.prototype.validate = function () {
        if (Utils.isNullOrUndefined(this.question) || !Utils.isString(this.question) ||
            Utils.isNullOrUndefined(this.availableOptions) || !Array.isArray(this.availableOptions) || this.availableOptions.length < 2 ||
            Utils.isNullOrUndefined(this.minNumberOfSelectedOptions) || !Utils.isNumber(this.minNumberOfSelectedOptions) ||
            Utils.isNullOrUndefined(this.maxNumberOfSelectedOptions) || !Utils.isNumber(this.maxNumberOfSelectedOptions)) {
            return false;
        }
        // expect all availableOptions values to be string
        for (var key in this.availableOptions) {
            if (this.availableOptions.hasOwnProperty(key)) {
                if (!Utils.isString(this.availableOptions[key])) {
                    return false;
                }
            }
        }
        return true;
    };
    return CampaignSurveyContentMultipleChoice;
}());
exports.CampaignSurveyContentMultipleChoice = CampaignSurveyContentMultipleChoice;
var CampaignSurveyContentPrompt = /** @class */ (function () {
    function CampaignSurveyContentPrompt() {
    }
    CampaignSurveyContentPrompt.deserialize = function (input) {
        var result = new CampaignSurveyContentPrompt();
        if (Utils.isNullOrUndefined(input)) {
            return null;
        }
        result.title = input.Title;
        result.question = input.Question;
        result.yesLabel = input.YesLabel;
        result.noLabel = input.NoLabel;
        if (!result.validate()) {
            return null;
        }
        return result;
    };
    CampaignSurveyContentPrompt.prototype.validate = function () {
        if (Utils.isNullOrUndefined(this.title) || !Utils.isString(this.title) ||
            Utils.isNullOrUndefined(this.question) || !Utils.isString(this.question) ||
            Utils.isNullOrUndefined(this.yesLabel) || !Utils.isString(this.yesLabel) ||
            Utils.isNullOrUndefined(this.noLabel) || !Utils.isString(this.noLabel)) {
            return false;
        }
        return true;
    };
    return CampaignSurveyContentPrompt;
}());
exports.CampaignSurveyContentPrompt = CampaignSurveyContentPrompt;
var CampaignSurveyContentRating = /** @class */ (function () {
    function CampaignSurveyContentRating() {
    }
    CampaignSurveyContentRating.deserialize = function (input) {
        var result = new CampaignSurveyContentRating();
        if (Utils.isNullOrUndefined(input)) {
            return null;
        }
        result.isZeroBased = input.IsZeroBased;
        result.question = input.Question;
        result.ratingValuesAscending = input.RatingValuesAscending;
        if (!result.validate()) {
            return null;
        }
        return result;
    };
    CampaignSurveyContentRating.prototype.validate = function () {
        // expect ratingValuesAscending to contain between 2 to 11 values
        if (Utils.isNullOrUndefined(this.question) || !Utils.isString(this.question) ||
            Utils.isNullOrUndefined(this.ratingValuesAscending) || !Array.isArray(this.ratingValuesAscending) ||
            this.ratingValuesAscending.length < 2 || this.ratingValuesAscending.length > 11) {
            return false;
        }
        if (Utils.isNullOrUndefined(this.isZeroBased)) {
            // default to false if not provided
            this.isZeroBased = false;
        }
        else if (!Utils.isBoolean(this.isZeroBased)) {
            // fail validation if non boolean value is provided
            return false;
        }
        // expect all ratingValuesAscending values to be string
        for (var key in this.ratingValuesAscending) {
            if (this.ratingValuesAscending.hasOwnProperty(key)) {
                if (!Utils.isString(this.ratingValuesAscending[key])) {
                    return false;
                }
            }
        }
        return true;
    };
    return CampaignSurveyContentRating;
}());
exports.CampaignSurveyContentRating = CampaignSurveyContentRating;
var CampaignSurveyContentComment = /** @class */ (function () {
    function CampaignSurveyContentComment() {
    }
    CampaignSurveyContentComment.deserialize = function (input) {
        var result = new CampaignSurveyContentComment();
        if (Utils.isNullOrUndefined(input)) {
            return null;
        }
        // @SerializedName("Question")
        result.question = input.Question;
        if (!result.validate()) {
            return null;
        }
        return result;
    };
    CampaignSurveyContentComment.prototype.validate = function () {
        if (Utils.isNullOrUndefined(this.question) || !Utils.isString(this.question)) {
            return false;
        }
        return true;
    };
    return CampaignSurveyContentComment;
}());
exports.CampaignSurveyContentComment = CampaignSurveyContentComment;
// endregion
// region Survey Metadata Definitions
var CampaignSurveyMetadata = /** @class */ (function () {
    function CampaignSurveyMetadata() {
    }
    CampaignSurveyMetadata.deserialize = function (input) {
        if (Utils.isNullOrUndefined(input)) {
            return null;
        }
        var result = new CampaignSurveyMetadata();
        result.contentMetadata = input.ContentMetadata;
        if (!result.validate()) {
            return null;
        }
        return result;
    };
    CampaignSurveyMetadata.prototype.validate = function () {
        if (Utils.isNullOrUndefined(this.contentMetadata) || !Utils.isObject(this.contentMetadata)) {
            return false;
        }
        return true;
    };
    return CampaignSurveyMetadata;
}());
exports.CampaignSurveyMetadata = CampaignSurveyMetadata;
// endregion
// region Survey Template definitions
var CampaignSurveyTemplate = /** @class */ (function () {
    function CampaignSurveyTemplate() {
    }
    CampaignSurveyTemplate.deserialize = function (input) {
        var result;
        if (Utils.isNullOrUndefined(input)) {
            return null;
        }
        switch (input.Type) {
            case CampaignSurveyTemplate.Type.Nps5PointStatic:
                result = CampaignSurveyTemplateNps5PointStatic.deserialize(input);
                break;
            case CampaignSurveyTemplate.Type.Nps11PointStatic:
                result = CampaignSurveyTemplateNps11PointStatic.deserialize(input);
                break;
            case CampaignSurveyTemplate.Type.Fps:
                result = CampaignSurveyTemplateFps.deserialize(input);
                break;
            case CampaignSurveyTemplate.Type.Nlqs:
                result = CampaignSurveyTemplateNlqs.deserialize(input);
                break;
            case CampaignSurveyTemplate.Type.Nps:
                result = CampaignSurveyTemplateNps.deserialize(input);
                break;
            case CampaignSurveyTemplate.Type.GenericMessagingSurface:
                result = CampaignSurveyTemplateGenericMessagingSurface.deserialize(input);
                break;
        }
        if (result === undefined) {
            return null;
        }
        return result;
    };
    CampaignSurveyTemplate.prototype.validate = function () {
        if (Utils.isNullOrUndefined(this.activationEvent)) {
            return false;
        }
        return true;
    };
    return CampaignSurveyTemplate;
}());
exports.CampaignSurveyTemplate = CampaignSurveyTemplate;
(function (CampaignSurveyTemplate) {
    var Type;
    (function (Type) {
        Type[Type["Nps5PointStatic"] = 0] = "Nps5PointStatic";
        Type[Type["Nps11PointStatic"] = 1] = "Nps11PointStatic";
        Type[Type["Fps"] = 2] = "Fps";
        Type[Type["Nlqs"] = 3] = "Nlqs";
        Type[Type["Nps"] = 4] = "Nps";
        // A generic messagibg surface template type, which will make use of
        // content metadata to render ui.
        // As of 4th Feb 2019 there are 20 template types defined in Mso,
        // hence giving 22 as value for this type.
        Type[Type["GenericMessagingSurface"] = 22] = "GenericMessagingSurface";
    })(Type = CampaignSurveyTemplate.Type || (CampaignSurveyTemplate.Type = {}));
})(CampaignSurveyTemplate = exports.CampaignSurveyTemplate || (exports.CampaignSurveyTemplate = {}));
exports.CampaignSurveyTemplate = CampaignSurveyTemplate;
var CampaignSurveyTemplateFps = /** @class */ (function (_super) {
    __extends(CampaignSurveyTemplateFps, _super);
    // public for UT only
    function CampaignSurveyTemplateFps() {
        return _super.call(this) || this;
    }
    CampaignSurveyTemplateFps.deserialize = function (input) {
        var result = new CampaignSurveyTemplateFps();
        if (Utils.isNullOrUndefined(input)) {
            return null;
        }
        result.activationEvent = CampaignSurveyEvent.deserialize(input.ActivationEvent);
        result.content = CampaignSurveyContent.deserialize(input.Content, true /* optionalComponents */);
        if (!result.validate()) {
            return null;
        }
        return result;
    };
    CampaignSurveyTemplateFps.prototype.validate = function () {
        if (!_super.prototype.validate.call(this) || Utils.isNullOrUndefined(this.content)) {
            return false;
        }
        return true;
    };
    return CampaignSurveyTemplateFps;
}(CampaignSurveyTemplate));
exports.CampaignSurveyTemplateFps = CampaignSurveyTemplateFps;
var CampaignSurveyTemplateNlqs = /** @class */ (function (_super) {
    __extends(CampaignSurveyTemplateNlqs, _super);
    // public for UT only
    function CampaignSurveyTemplateNlqs() {
        return _super.call(this) || this;
    }
    CampaignSurveyTemplateNlqs.deserialize = function (input) {
        var result = new CampaignSurveyTemplateNlqs();
        if (Utils.isNullOrUndefined(input)) {
            return null;
        }
        result.activationEvent = CampaignSurveyEvent.deserialize(input.ActivationEvent);
        result.content = CampaignSurveyContent.deserialize(input.Content);
        if (!result.validate()) {
            return null;
        }
        return result;
    };
    CampaignSurveyTemplateNlqs.prototype.validate = function () {
        if (!_super.prototype.validate.call(this) || Utils.isNullOrUndefined(this.content)) {
            return false;
        }
        return true;
    };
    return CampaignSurveyTemplateNlqs;
}(CampaignSurveyTemplate));
exports.CampaignSurveyTemplateNlqs = CampaignSurveyTemplateNlqs;
var CampaignSurveyTemplateNps = /** @class */ (function (_super) {
    __extends(CampaignSurveyTemplateNps, _super);
    // public for UT only
    function CampaignSurveyTemplateNps() {
        return _super.call(this) || this;
    }
    CampaignSurveyTemplateNps.deserialize = function (input) {
        var result = new CampaignSurveyTemplateNps();
        if (Utils.isNullOrUndefined(input)) {
            return null;
        }
        result.activationEvent = CampaignSurveyEvent.deserialize(input.ActivationEvent);
        result.content = CampaignSurveyContent.deserialize(input.Content);
        if (!result.validate()) {
            return null;
        }
        return result;
    };
    CampaignSurveyTemplateNps.prototype.validate = function () {
        if (!_super.prototype.validate.call(this) || Utils.isNullOrUndefined(this.content)) {
            return false;
        }
        return true;
    };
    return CampaignSurveyTemplateNps;
}(CampaignSurveyTemplate));
exports.CampaignSurveyTemplateNps = CampaignSurveyTemplateNps;
var CampaignSurveyTemplateNps5PointStatic = /** @class */ (function (_super) {
    __extends(CampaignSurveyTemplateNps5PointStatic, _super);
    // public for UT only
    function CampaignSurveyTemplateNps5PointStatic() {
        return _super.call(this) || this;
    }
    CampaignSurveyTemplateNps5PointStatic.deserialize = function (input) {
        var result = new CampaignSurveyTemplateNps5PointStatic();
        if (Utils.isNullOrUndefined(input)) {
            return null;
        }
        result.activationEvent = CampaignSurveyEvent.deserialize(input.ActivationEvent);
        if (!result.validate()) {
            return null;
        }
        return result;
    };
    CampaignSurveyTemplateNps5PointStatic.prototype.validate = function () {
        if (!_super.prototype.validate.call(this)) {
            return false;
        }
        return true;
    };
    return CampaignSurveyTemplateNps5PointStatic;
}(CampaignSurveyTemplate));
exports.CampaignSurveyTemplateNps5PointStatic = CampaignSurveyTemplateNps5PointStatic;
var CampaignSurveyTemplateNps11PointStatic = /** @class */ (function (_super) {
    __extends(CampaignSurveyTemplateNps11PointStatic, _super);
    function CampaignSurveyTemplateNps11PointStatic() {
        return _super.call(this) || this;
    }
    CampaignSurveyTemplateNps11PointStatic.deserialize = function (input) {
        var result = new CampaignSurveyTemplateNps11PointStatic();
        if (Utils.isNullOrUndefined(input)) {
            return null;
        }
        result.activationEvent = CampaignSurveyEvent.deserialize(input.ActivationEvent);
        if (!result.validate()) {
            return null;
        }
        return result;
    };
    CampaignSurveyTemplateNps11PointStatic.prototype.validate = function () {
        if (!_super.prototype.validate.call(this)) {
            return false;
        }
        return true;
    };
    return CampaignSurveyTemplateNps11PointStatic;
}(CampaignSurveyTemplate));
exports.CampaignSurveyTemplateNps11PointStatic = CampaignSurveyTemplateNps11PointStatic;
var CampaignSurveyTemplateGenericMessagingSurface = /** @class */ (function (_super) {
    __extends(CampaignSurveyTemplateGenericMessagingSurface, _super);
    function CampaignSurveyTemplateGenericMessagingSurface() {
        return _super.call(this) || this;
    }
    CampaignSurveyTemplateGenericMessagingSurface.deserialize = function (input) {
        if (Utils.isNullOrUndefined(input)) {
            return null;
        }
        var result = new CampaignSurveyTemplateGenericMessagingSurface();
        result.activationEvent = CampaignSurveyEvent.deserialize(input.ActivationEvent);
        result.metadata = CampaignSurveyMetadata.deserialize(input.Metadata);
        if (!result.validate()) {
            return null;
        }
        return result;
    };
    CampaignSurveyTemplateGenericMessagingSurface.prototype.validate = function () {
        if (!_super.prototype.validate.call(this) || Utils.isNullOrUndefined(this.metadata)) {
            return false;
        }
        return true;
    };
    return CampaignSurveyTemplateGenericMessagingSurface;
}(CampaignSurveyTemplate));
exports.CampaignSurveyTemplateGenericMessagingSurface = CampaignSurveyTemplateGenericMessagingSurface;
// endregion
// region Campaign Definition
var CampaignDefinition = /** @class */ (function () {
    function CampaignDefinition() {
    }
    /**
     * Method to deserialize a JSON object to class object
     * @param input: JSON object
     * Returns result of validation check
     */
    CampaignDefinition.deserialize = function (input) {
        var result = new CampaignDefinition();
        if (Utils.isNullOrUndefined(input)) {
            return null;
        }
        result.campaignId = input.CampaignId;
        result.governedChannelType = input.GovernedChannelType;
        result.startTime = input.StartTimeUtc;
        result.endTime = input.EndTimeUtc;
        result.launcherType = input.LauncherType;
        var additionalDataRequested = CampaignAdditionalDataRequested.deserialize(input.AdditionalDataRequested);
        result.additionalDataRequested = additionalDataRequested ? additionalDataRequested.additionalData : [];
        if (input.SurveyTemplate) {
            result.surveyTemplate = CampaignSurveyTemplate.deserialize(input.SurveyTemplate);
        }
        if (input.NominationScheme) {
            result.nominationScheme = CampaignNominationScheme.deserialize(input.NominationScheme);
        }
        if (input.Scope) {
            result.scope = CampaignScope.deserialize(input.Scope);
        }
        if (!result.validate()) {
            return null;
        }
        return result;
    };
    CampaignDefinition.prototype.validate = function () {
        if (Utils.isNullOrUndefined(this.scope)) {
            this.scope = new CampaignScopeAny();
        }
        if (Utils.isNullOrUndefined(this.campaignId) || !Utils.isString(this.campaignId)) {
            return false;
        }
        if (!Utils.isNumber(this.governedChannelType) || !Utils.isEnumValue(this.governedChannelType, GovernedChannel_1.GovernedChannelType)) {
            return false;
        }
        if (Utils.isNullOrUndefined(this.nominationScheme)) {
            return false;
        }
        if (Utils.isNullOrUndefined(this.surveyTemplate)) {
            return false;
        }
        if (!Utils.isNullOrUndefined(this.launcherType) && !Utils.isString(this.launcherType)) {
            return false;
        }
        // Campaigns with no start date are effectively disabled, in fact, this is the supported way to stage a definition but have it be turned off
        if (Utils.isNullOrUndefined(this.startTime)) {
            this.startTime = Utils.getDistantFuture();
        }
        else if (Utils.isUtcDatetimeString(this.startTime)) {
            this.startTime = Utils.stringToDate(this.startTime);
        }
        else {
            return false;
        }
        // Campaigns with no end date are effectively on indefinitely
        if (Utils.isNullOrUndefined(this.endTime)) {
            this.endTime = Utils.getDistantFuture();
        }
        else if (Utils.isUtcDatetimeString(this.endTime)) {
            this.endTime = Utils.stringToDate(this.endTime);
        }
        else {
            return false;
        }
        return true;
    };
    return CampaignDefinition;
}());
exports.CampaignDefinition = CampaignDefinition;
/**
 * Given an array of campaign definitions, returns the valid ones and errors if any are invalid.
 * @param input: JSON object
 * Returns the valid definitions and the error as string if any.
 */
function FilterValidCampaignDefinitions(campaignDefinitions) {
    var result = [];
    if (Utils.isNullOrUndefined(campaignDefinitions)) {
        return { result: result, error: "Empty" };
    }
    if (!Array.isArray(campaignDefinitions)) {
        return { result: result, error: "Not an array" };
    }
    var badIndexes = [];
    for (var i = 0; i < campaignDefinitions.length; i++) {
        var definition = campaignDefinitions[i];
        if (definition) {
            var newDefinition = CampaignDefinition.deserialize(definition);
            newDefinition
                ? result.push(newDefinition)
                : badIndexes.push(i);
        }
        else {
            badIndexes.push(i);
        }
    }
    var error = badIndexes.length > 0 ? "Invalid campaign definitions at indexes: " + badIndexes.toString() : undefined;
    return { result: result, error: error };
}
exports.FilterValidCampaignDefinitions = FilterValidCampaignDefinitions;
var FileSystemCampaignDefinitionProvider = /** @class */ (function () {
    function FileSystemCampaignDefinitionProvider(storage) {
        if (Utils.isNullOrUndefined(storage)) {
            throw new Error("storage must not be null");
        }
        this.storage = storage;
    }
    FileSystemCampaignDefinitionProvider.prototype.load = function () {
        var definitionString = this.storage.read(IFloodgateStorageProvider.FileType.CampaignDefinitions);
        if (Utils.isNullOrUndefined(definitionString)) {
            return [];
        }
        var readDefinitions;
        try {
            readDefinitions = JSON.parse(definitionString);
        }
        catch (e) {
            FloodgateEngine_1.FloodgateEngine.getTelemetryLogger().log_CampaignLoad_Failed(e.toString());
            return [];
        }
        // This ignores errors in validation for now.
        return FilterValidCampaignDefinitions(readDefinitions).result;
    };
    FileSystemCampaignDefinitionProvider.prototype.loadAsync = function () {
        var definitions = this.load();
        return new es6_promise_1.Promise(function onFulfilled(resolve, reject) {
            resolve(definitions);
        });
    };
    return FileSystemCampaignDefinitionProvider;
}());
exports.FileSystemCampaignDefinitionProvider = FileSystemCampaignDefinitionProvider;
