"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var IFloodgateStorageProvider = require("./Api/IFloodgateStorageProvider");
var Constants_1 = require("./Constants");
var FloodgateEngine_1 = require("./FloodgateEngine");
var GovernedChannel_1 = require("./GovernedChannel");
var StateProviderHelpers = require("./StateProviderHelpers");
var Utils = require("./Utils");
var FileType = IFloodgateStorageProvider.FileType;
var StateListType = StateProviderHelpers.StateListType;
var GovernedChannelState = /** @class */ (function () {
    function GovernedChannelState(type, cooldownStartTime) {
        this.ChannelType = type;
        this.CooldownStartTimeUtc = cooldownStartTime;
        if (!this.validate()) {
            throw new Error("Constructor arguments are not valid");
        }
    }
    /**
     * Method to deserialize a JSON object to class object
     * @param input: JSON object
     * Returns class object
     */
    GovernedChannelState.deserialize = function (input) {
        var result;
        if (!input) {
            return null;
        }
        // Validation is handled by GovernedChannelState's constructor
        try {
            result = new GovernedChannelState(input.ChannelType, input.CooldownStartTimeUtc);
        }
        catch (e) {
            FloodgateEngine_1.FloodgateEngine.getTelemetryLogger().log_Event(Constants_1.TelemetryEvent.GovernedChannelState.Deserialize.Failed, { ErrorMessage: e.toString() });
            return null;
        }
        return result;
    };
    GovernedChannelState.prototype.validate = function () {
        if (!Utils.isEnumValue(this.ChannelType, GovernedChannel_1.GovernedChannelType)) {
            return false;
        }
        // make it a date object if it's a valid UTC date time value
        if (Utils.isUtcDatetimeString(this.CooldownStartTimeUtc)) {
            this.CooldownStartTimeUtc = Utils.stringToDate(this.CooldownStartTimeUtc);
            // Allow null and bad cooldownStartTime
        }
        else if (Utils.isNullOrUndefined(this.CooldownStartTimeUtc) || !Utils.isDate(this.CooldownStartTimeUtc)) {
            this.CooldownStartTimeUtc = Utils.getDistantPast();
        }
        return true;
    };
    GovernedChannelState.prototype.getType = function () {
        return this.ChannelType;
    };
    GovernedChannelState.prototype.getCooldownStartTime = function () {
        return this.CooldownStartTimeUtc;
    };
    return GovernedChannelState;
}());
exports.GovernedChannelState = GovernedChannelState;
var GovernedChannelStateProviderFactory = /** @class */ (function () {
    function GovernedChannelStateProviderFactory() {
    }
    GovernedChannelStateProviderFactory.make = function (fileBasedStorage, hostBasedStorage) {
        if (hostBasedStorage) {
            return new HostBasedGovernedChannelStateProvider(fileBasedStorage, hostBasedStorage);
        }
        return new FileBasedGovernedChannelStateProvider(fileBasedStorage);
    };
    return GovernedChannelStateProviderFactory;
}());
exports.GovernedChannelStateProviderFactory = GovernedChannelStateProviderFactory;
/**
 * Class representing what is stored in the file.
 */
var FileData = /** @class */ (function () {
    function FileData() {
    }
    return FileData;
}());
var HostBasedGovernedChannelStateProvider = /** @class */ (function () {
    function HostBasedGovernedChannelStateProvider(fileBasedStorage, hostBasedStorage) {
        if (!hostBasedStorage) {
            throw new Error("host-based storage must not be null");
        }
        this.hostBasedStorage = hostBasedStorage;
        // file-based provider is optional
        if (fileBasedStorage) {
            this.fileBasedStateProvider = new FileBasedGovernedChannelStateProvider(fileBasedStorage);
        }
    }
    // @Override
    HostBasedGovernedChannelStateProvider.prototype.load = function () {
        var statesFromHost = this.getStatesFromHost();
        var statesFromFile;
        if (this.fileBasedStateProvider) {
            statesFromFile = this.fileBasedStateProvider.load();
        }
        var StateCollections = StateProviderHelpers.MergeAndUpdateGovernedChannelStates(statesFromFile, statesFromHost);
        return StateCollections.find(StateListType.Merged);
    };
    // @Override
    HostBasedGovernedChannelStateProvider.prototype.save = function (states) {
        var _this = this;
        if (!states) {
            return;
        }
        var statesFromHost = this.getStatesFromHost();
        var StateCollections = StateProviderHelpers.MergeAndUpdateGovernedChannelStates(states, statesFromHost);
        var toBeUpdatedStates = StateCollections.find(StateListType.ToBeUpdated);
        var mergedStates = StateCollections.find(StateListType.Merged);
        // write delta_data to Host
        if (toBeUpdatedStates) {
            toBeUpdatedStates.forEach(function (state) {
                var stateValue = StateProviderHelpers.makeSettingItemValue(JSON.stringify(state));
                _this.hostBasedStorage.upsertSettingItem(FileType[FileType.GovernedChannelStates], String(state.getType()), stateValue);
            });
        }
        // Write merged data to File
        if (this.fileBasedStateProvider) {
            this.fileBasedStateProvider.save(mergedStates);
        }
    };
    HostBasedGovernedChannelStateProvider.prototype.getStatesFromHost = function () {
        var states = [];
        var settings = this.hostBasedStorage.readSettingList(FileType[FileType.GovernedChannelStates]);
        for (var key in settings) {
            if (!settings.hasOwnProperty(key)) {
                continue;
            }
            var value = settings[key];
            var dataString = StateProviderHelpers.extractSettingItemValueSubString(value);
            var itemObject = {};
            try {
                itemObject = JSON.parse(dataString);
                var newState = GovernedChannelState.deserialize(itemObject);
                if (newState) {
                    states.push(newState);
                }
            }
            catch (e) {
                FloodgateEngine_1.FloodgateEngine.getTelemetryLogger().log_Event(Constants_1.TelemetryEvent.HostBasedGovernedChannelStateProvider.GetStatesFromHost.Failed, { ErrorMessage: "Json parsing/deserializing failed. " + e.toString() + ".  Input:" + dataString });
            }
        }
        return states;
    };
    return HostBasedGovernedChannelStateProvider;
}());
exports.HostBasedGovernedChannelStateProvider = HostBasedGovernedChannelStateProvider;
var FileBasedGovernedChannelStateProvider = /** @class */ (function () {
    function FileBasedGovernedChannelStateProvider(storage) {
        if (!storage) {
            throw new Error("storage must not be null");
        }
        this.storage = storage;
    }
    // @Override
    FileBasedGovernedChannelStateProvider.prototype.load = function () {
        // Load channel state from file using _storageProvider (get back string)
        var readString = this.storage.read(FileType.GovernedChannelStates);
        if (!readString) {
            return [];
        }
        var fileData;
        try {
            fileData = JSON.parse(readString);
            var result_1 = [];
            if (fileData && fileData.ChannelStates) {
                fileData.ChannelStates.forEach(function (state) {
                    var newState = GovernedChannelState.deserialize(state);
                    if (newState) {
                        result_1.push(newState);
                    }
                });
            }
            return result_1;
        }
        catch (e) {
            FloodgateEngine_1.FloodgateEngine.getTelemetryLogger().log_Event(Constants_1.TelemetryEvent.FileBasedGovernedChannelStateProvider.Load.Failed, { ErrorMessage: "Json parsing/deserializing failed. " + e.toString() + ".  Input:" + readString });
            return [];
        }
    };
    // @Override
    FileBasedGovernedChannelStateProvider.prototype.save = function (states) {
        if (!states) {
            return;
        }
        var fileData = new FileData();
        fileData.ChannelStates = states;
        var writeString = JSON.stringify(fileData);
        this.storage.write(FileType.GovernedChannelStates, writeString);
    };
    return FileBasedGovernedChannelStateProvider;
}());
exports.FileBasedGovernedChannelStateProvider = FileBasedGovernedChannelStateProvider;
