"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * General floodgate settings
 */
var FloodgateSettings = /** @class */ (function () {
    function FloodgateSettings() {
    }
    /**
     * Convert to Json
     */
    FloodgateSettings.toJson = function (object) {
        return JSON.stringify(object);
    };
    /**
     * Load from Json
     */
    FloodgateSettings.fromJson = function (json) {
        return JSON.parse(json);
    };
    return FloodgateSettings;
}());
exports.FloodgateSettings = FloodgateSettings;
