﻿/**
 * UserVoiceFormTemplate
 *
 * A form template for directing to user voice.
 */

import * as UIStrings from "./../../../UIStrings/UIStrings";
import {FormTemplateType} from "./FormTemplateType";
import {IUIAsJson} from "./../../Renderer";
import * as Constants from "./../../../Constants";
import * as Configuration from "./../../../Configuration/Configuration";

export interface IUserVoiceInitOptions {
	// Url for user voice.
	url: string;
}

/**
 * Generate the markup
 * @returns the markup as json
 */
export function generate(): IUIAsJson {
	let initOptions: IUserVoiceInitOptions = Configuration.get().getInAppFeedbackInitOptions().userVoice;

	let formSchema = {
		children: [
			{
				classes: [Constants.Classes.FontSubtitle, Constants.Classes.TextAlignLeft,
					Constants.Classes.FormQuestionMiddleText],
				innerText: UIStrings.getUIStrings().UserVoiceForm.Title,
			},
			{
				classes: [Constants.Classes.FontText, Constants.Classes.TextAlignLeft,
					Constants.Classes.FormMiddleText],
				innerText: UIStrings.getUIStrings().UserVoiceForm.Text,
			},
			{
				children: [
					{
						children: [
							{
								attributes: [
									{
										name: Constants.AttributeName.Source,
										value: initOptions.url,
									},
								],
								classes: [
									Constants.Classes.SubmitButton,
									Constants.Classes.FontSubText,
								],
								id: Constants.IDs.UserVoiceFormGoButton,
								innerText: UIStrings.getUIStrings().UserVoiceForm.Button,
								tag: Constants.Tags.Button,
							},
						],
						classes: [Constants.Classes.FormSubmitButtonContainer],
					},
				],
				classes: [Constants.Classes.FormBottomContainer],
			},
		],
		classes: [Constants.Classes.Hidden, Constants.Classes.MarginLeft60px],
		id: containerId,
	};
	return formSchema;
}

/**
 * The id for the container. This is the id of the top-most element and should
 * be unique to each form
 */
export let containerId: string = Constants.IDs.UserVoiceFormContainer;

/**
 * Forms may be used for different feedback types. This method executes form
 * specific logic when the feedback type is selected
 * @param feedbackType feedback type
 */
export function onSelect(feedbackType: Constants.FeedbackType): void {
	document.getElementById(Constants.IDs.UserVoiceFormGoButton).focus();
}

/**
 * Form template type
 */
export let type: FormTemplateType = FormTemplateType.UserVoice;
