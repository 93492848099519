"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ISurvey = require("../Api/ISurvey");
var FloodgateEngine_1 = require("../FloodgateEngine");
var Survey = /** @class */ (function () {
    function Survey() {
    }
    Survey.prototype.getCampaignId = function () {
        return this.getSurveyInfo().getBackEndId();
    };
    Survey.prototype.getLauncherType = function () {
        return this.getSurveyInfo().getLauncherType();
    };
    Survey.prototype.submit = function () {
        var surveyType = ISurvey.Type[this.getType()];
        var transporter = FloodgateEngine_1.FloodgateEngine.getTransportFactory().makeTransporter(surveyType);
        transporter.setManifestValues(this.getJsonElements());
        transporter.submit();
    };
    return Survey;
}());
exports.Survey = Survey;
