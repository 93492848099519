"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var Constants_1 = require("./Constants");
var FloodgateEngine_1 = require("./FloodgateEngine");
var SurveyStatCollection_1 = require("./SurveyStatCollection");
var Utils = require("./Utils");
/**
 * StatCollection for EventActivity counts.  Should be used to store/merge
 * aggregate values between sessions tracking the same surveys/events
 * E.g. A survey that activates after 3 boots.
 */
// TODO (gachoi) check the following - VSOBug: 1443010 One bad Stat object fails the entire serialization
var SurveyStatCollectionEventActivity = /** @class */ (function (_super) {
    __extends(SurveyStatCollectionEventActivity, _super);
    function SurveyStatCollectionEventActivity() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Load from Json
     */
    SurveyStatCollectionEventActivity.fromJson = function (json) {
        var statCollection = new SurveyStatCollectionEventActivity();
        if (!json) {
            FloodgateEngine_1.FloodgateEngine.getTelemetryLogger().log_Event(Constants_1.TelemetryEvent.SurveyStatCollectionEventActivity.FromJson.Failed, { ErrorMessage: "Input json is null or empty." });
            return statCollection;
        }
        var readStats;
        try {
            readStats = JSON.parse(json);
        }
        catch (e) {
            FloodgateEngine_1.FloodgateEngine.getTelemetryLogger().log_Event(Constants_1.TelemetryEvent.SurveyStatCollectionEventActivity.FromJson.Failed, { ErrorMessage: "Json parsing failed. " + e.toString() });
            return statCollection;
        }
        if (!statCollection.deserialize(readStats)) {
            return new SurveyStatCollectionEventActivity();
        }
        return statCollection;
    };
    /**
     * Convert to Json
     */
    SurveyStatCollectionEventActivity.toJson = function (object) {
        if (!object) {
            FloodgateEngine_1.FloodgateEngine.getTelemetryLogger().log_Event(Constants_1.TelemetryEvent.SurveyStatCollectionEventActivity.ToJson.Failed, { ErrorMessage: "Input json is null or empty." });
            object = new SurveyStatCollectionEventActivity();
        }
        return JSON.stringify(object);
    };
    /**
     * Add another SurveyStatCollectionEventActivity object
     */
    SurveyStatCollectionEventActivity.prototype.accumulate = function (other) {
        if (!other) {
            return;
        }
        var stats = other.getStats();
        // SurveyEventActivityStats accumulation simply overwrites any keys from 'other' into our collection
        for (var key in stats) {
            if (stats.hasOwnProperty(key)) {
                var ourStats = this.getBySurveyId(key);
                // If it does not already exist
                if (!ourStats) {
                    ourStats = new SurveyEventActivityStats();
                    ourStats.Counts = [];
                    this.addStats(key, ourStats);
                }
                ourStats.ExpirationTimeUtc = stats[key].ExpirationTimeUtc;
                // If for some reason the other counts array is larger, resize ourStats.counts
                if (ourStats.Counts.length < stats[key].Counts.length) {
                    var resizedCounts = ourStats.Counts.slice();
                    ourStats.Counts = resizedCounts;
                }
                for (var i = 0; i < stats[key].Counts.length; i++) {
                    if (!ourStats.Counts[i]) {
                        ourStats.Counts[i] = 0;
                    }
                    ourStats.Counts[i] += stats[key].Counts[i];
                }
            }
        }
    };
    /**
     * Method to deserialize SurveyStatCollectionEventActivity
     * @param input: collection of SurveyStatCollectionEventActivity
     * Returns result of validation check
     */
    SurveyStatCollectionEventActivity.prototype.deserialize = function (input) {
        var rawStats = input.Surveys;
        var now = new Date();
        for (var key in rawStats) {
            if (rawStats.hasOwnProperty(key)) {
                var newStat = new SurveyEventActivityStats();
                if (newStat.deserialize(rawStats[key]) && newStat.ExpirationTimeUtc > now) {
                    this.addStats(key, newStat);
                }
            }
        }
        return this.validate();
    };
    /**
     * Validate the Surveys
     * Returns false if validation fails
     */
    SurveyStatCollectionEventActivity.prototype.validate = function () {
        return Utils.isObject(this.getStats());
    };
    return SurveyStatCollectionEventActivity;
}(SurveyStatCollection_1.SurveyStatCollection));
exports.SurveyStatCollectionEventActivity = SurveyStatCollectionEventActivity;
var SurveyEventActivityStats = /** @class */ (function () {
    function SurveyEventActivityStats() {
    }
    /**
     * Method to deserialize a JSON object to class object
     * @param input: JSON object
     * Returns result of validation check
     */
    SurveyEventActivityStats.prototype.deserialize = function (input) {
        this.ExpirationTimeUtc = input.ExpirationTimeUtc;
        this.Counts = input.Counts;
        return this.validate();
    };
    /**
     * Method to call after deserialization to validate generated object.
     * Returns false if not valid.
     */
    SurveyEventActivityStats.prototype.validate = function () {
        if (!this.Counts) {
            return false;
        }
        for (var i = 0; i < this.Counts.length; i++) {
            var val = this.Counts[i];
            if (!Utils.isNumber(val)) {
                return false;
            }
            if (val < 0) {
                this.Counts[i] = 0;
            }
        }
        // make it a date object if it's a valid UTC date time value
        if (Utils.isUtcDatetimeString(this.ExpirationTimeUtc)) {
            this.ExpirationTimeUtc = Utils.stringToDate(this.ExpirationTimeUtc);
        }
        else {
            return false;
        }
        return true;
    };
    return SurveyEventActivityStats;
}());
exports.SurveyEventActivityStats = SurveyEventActivityStats;
