import { IEventId, ICustomProperties } from "./Constants";
import { Environment } from "./../Constants";
import { App, Session, Host, Release } from "./Telemetry/Contracts";
import * as Utils from "./../Utils";
import ITelemetryLogger from "./Telemetry/ITelemetryLogger";
import TelemetryLoggerFactory from "./Telemetry/TelemetryLoggerFactory";
import FloodgateStorageProvider from "../FloodgateCore/FloodgateStorageProvider";
import { FileType } from "@ms-ofb/officefloodgatecore/dist/src/Api/IFloodgateStorageProvider";

export { ICustomProperties, IEventId, EventIds } from "./Constants";

const NAMESPACE: string = "Office_Feedback";

let logger: ILogger;

let env: Environment;

/**
 * Initialize the logging module
 * @param environment environment
 * @param appName app name
 * @param appVersion app version
 * @param audienceGroup audience group
 * @param hostId host id
 * @param hostSessionId host session Id
 * @param hostVersion host version
 */
export function initialize(environment: Environment, appName: string, appVersion: string,
	audienceGroup: string, hostId: string, hostSessionId: string, hostVersion: string,
	logSessionId: string): void {
	logger = new Logger(
		TelemetryLoggerFactory.create(TokenManager.getTenantToken(environment),
			NAMESPACE,
			new App(appName, appVersion),
			new Session(logSessionId),
			new Host(hostId, hostSessionId, hostVersion),
			new Release(audienceGroup)));
	env = environment;
}

/**
 * Get the logger object
 */
export function getLogger(): ILogger {
	return logger;
}

/**
 * Interface for a logger
 */
export interface ILogger {
	logEvent(eventId: IEventId, logSeverity: LogLevel, customProperties?: ICustomProperties): void;
}

/**
 * Log level settings
 */
export const enum LogLevel {
	None,
	Critical,
	Error,
	Info
}

/**
 * Class representing a logger for the feedback SDK
 */
export class Logger implements ILogger {
	private static EVENT_NAME: string = "SDK";
	private static EVENT_ID: string = "EventId";

	private telemetryLogger: ITelemetryLogger;
	private logLevel: LogLevel;
	private isConsoleLogEnabled: boolean;

	constructor(telemetryLogger: ITelemetryLogger) {
		if (!telemetryLogger) {
			throw new Error("telemetryLogger must not be null");
		}

		this.telemetryLogger = telemetryLogger;
	}

	/**
	 * Log an event
	 * @param eventId event Id
	 * @param logSeverity the log level severity for the message
	 * @param customProperties custom properties to add to the log
	 */
	public logEvent(eventId: IEventId, logSeverity: LogLevel, customProperties?: ICustomProperties): boolean {
		if (!eventId) {
			throw new Error("eventId must not be null");
		}

		if (Utils.isNullorUndefined(logSeverity) || logSeverity === LogLevel.None) {
			if (env === Environment.Production) {
				const errorMessage = "logSeverity must not be null or none";
				if (this.isConsoleLogEnabled && console) {
					// tslint:disable:no-console
					console.log("Floodgate event: ", Logger.EVENT_NAME, errorMessage);
				}
				this.telemetryLogger.logEvent(Logger.EVENT_NAME, { ErrorMessage: errorMessage });
			}
			return false;
		}

		customProperties = customProperties || {};
		(<any> customProperties)[Logger.EVENT_ID] = eventId.name;

		if (this.isLoggingEnabled(logSeverity)) {
			this.telemetryLogger.logEvent(Logger.EVENT_NAME, customProperties);
		}

		if (this.isConsoleLogEnabled && console) {
			// tslint:disable:no-console
			console.log("Floodgate event: ", Logger.EVENT_NAME, logSeverity, customProperties);
		}

		return true;
	}

	/**
	 * This method enables logging only if the log severity of the event is less than or equal to the
	 * current log level set. For example, if the log severity of the event is LogLevel.Error and the
	 * current log level is set as LogLevel.Critical, then isLoggingEnabled will return false since the
	 * condition LogLevel.Error <= LogLevel.Critical is false. Because, the log severity follows the order
	 * defined in the LogLevel enum (None < Critical < Error < Info).
	 * If no current log level is set, then the default log level is set as LogLevel.Error so that all events
	 * marked with critical and error log severity get logged.
	 * @param inputLogLevel the log severity of the event
	 */
	private isLoggingEnabled(inputLogLevel: LogLevel): boolean {
		if (Utils.isNullorUndefined(this.logLevel)) {
			const currentLogLevel = this.getCurrentLogLevel();
			this.logLevel = Utils.isNullorUndefined(currentLogLevel) ? LogLevel.Error : currentLogLevel;
		}

		return inputLogLevel <= this.logLevel;
	}

	/**
	 * This method returns the current log level if it is set in either url query parameters or floodgate local storage.
	 * Following table summarizes whether the log event calls with a log severity will log the events or not according
	 * to the current log level set. For example, the first row in the table demonstrates that when the current log level
	 * enbaled is critical, then the log event will log the events only if the log severity is critical.
	 *
	 * Current Log Level Enabled | LogSeverity: Critical	| LogSeverity: Error	| LogSeverity: Info
	 * --------------------------------------------------------------------------------------------
	 * Critical Enabled			 | yes      				| no    			 	| no
	 * Error Enabled       		 | yes      				| yes   			 	| no
	 * Info Enabled        		 | yes      				| yes   			 	| yes
	 * None Enabled        		 | no       				| no    			 	| no
	 *
	 * Console logging will be enabled when the current log level is set through the url query parameters or
	 * when the url query parameter "obfconsolelog" is explicitly set to true.
	 */
	private getCurrentLogLevel(): LogLevel {
		try {
			// Check if log level is set in url query parameters
			const urlParams = typeof URLSearchParams !== "undefined" && new URLSearchParams(window.location.search);
			const consoleLogParam = urlParams && urlParams.get("obfconsolelog");
			if (consoleLogParam) {
				this.isConsoleLogEnabled = true;
			}
			const logLevelParam = urlParams && urlParams.get("obfloglevel");
			if (!Utils.isNullorUndefined(logLevelParam)) {
				this.isConsoleLogEnabled = true;
				return parseInt(logLevelParam, 10);
			}

			// Check if log level is set in floodgate local storage
			if (FloodgateStorageProvider.isStorageAvailable()) {
				const floodgateStorageProvider: FloodgateStorageProvider = new FloodgateStorageProvider();
				const tempStorageItem: string = floodgateStorageProvider.read(FileType.LogLevelSettings);
				if (!Utils.isNullorUndefined(tempStorageItem)) {
					let parsedObj = this.parseObject(tempStorageItem);
					if (!Utils.isNullorUndefined(parsedObj) && parsedObj.content) {
						if (parsedObj.content.consoleLog) {
							this.isConsoleLogEnabled = true;
						}

						return parsedObj.content.logLevel;
					}
				}
			}
		} catch (e) {
			if (this.isConsoleLogEnabled) {
				// tslint:disable:no-console
				console.log("Error while getting the current log level: ", e);
			}
			return undefined;
		}

		return null;
	}

	private parseObject(tempItem: string): any {
		try {
			return JSON.parse(tempItem);
		} catch (e) {
			if (this.isConsoleLogEnabled) {
				// tslint:disable:no-console
				console.log("Error while parsing the json string for log level: ", e);
			}
			return null;
		}
	}
}

class TokenManager {
	public static getTenantToken(environment: Environment): string {
		if (environment === Environment.Production) {
			return TokenManager.TENANT_TOKEN_PRODUCTION;
		} else {
			return TokenManager.TENANT_TOKEN_PRE_PRODUCTION;
		}
	}

	private static TENANT_TOKEN_PRODUCTION: string =
	"d79e824386c4441cb8c1d4ae15690526-bd443309-5494-444a-aba9-0af9eef99f84-7360"; // "Office Feedback" Prod Aria tenant
	private static TENANT_TOKEN_PRE_PRODUCTION: string =
	"2bf6a2ffddca4a80a892a0b182132961-625cb102-8b0c-480e-af53-92e48695d08d-7736"; // "Office Feedback" Sandbox Aria tenant
}
