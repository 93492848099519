"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var GovernedChannel_1 = require("./GovernedChannel");
var GovernedChannelStateProvider_1 = require("./GovernedChannelStateProvider");
var Utils = require("./Utils");
var DefaultChannelData = /** @class */ (function () {
    function DefaultChannelData(name, cooldownSeconds) {
        this.name = name;
        this.cooldownSeconds = cooldownSeconds;
    }
    return DefaultChannelData;
}());
var Governor = /** @class */ (function () {
    function Governor(channelStateProvider) {
        if (!channelStateProvider) {
            throw new Error("channelStateProvider must not be null");
        }
        this.channelStateProvider = channelStateProvider;
        this.loadChannels();
    }
    // @Override
    Governor.prototype.refreshChannelData = function () {
        this.loadChannels();
    };
    // @Override
    Governor.prototype.getAvailableChannelData = function () {
        var channelData = [];
        for (var key in this.channels) {
            if (this.channels.hasOwnProperty(key)) {
                var channel = this.channels[key];
                if (channel.isOpen()) {
                    channelData.push(channel);
                }
            }
        }
        return channelData;
    };
    // @Override
    Governor.prototype.isChannelOpen = function (type) {
        if (Utils.isNullOrUndefined(type)) {
            throw new Error("type must not be null");
        }
        return this.channels[type].isOpen();
    };
    // @Override
    Governor.prototype.startChannelCooldown = function (type, date) {
        if (Utils.isNullOrUndefined(type)) {
            throw new Error("type must not be null");
        }
        date = date ? date : new Date();
        // Start the cool down
        this.channels[type].setCooldownStartTime(date);
        // Save the new channel state for future sessions
        this.saveChannelStates();
    };
    Governor.prototype.saveChannelStates = function () {
        // Build up the list of channel states
        var channelStates = [];
        for (var key in this.channels) {
            if (this.channels.hasOwnProperty(key)) {
                var channel = this.channels[key];
                var channelState = new GovernedChannelStateProvider_1.GovernedChannelState(channel.getType(), channel.getCooldownStartTime());
                channelStates.push(channelState);
            }
        }
        this.channelStateProvider.save(channelStates);
    };
    Governor.prototype.loadChannels = function () {
        // Load up state from previous sessions
        this.loadChannelStatesFromProvider();
        // For any missing channels, init them from scratch
        // There's no straightforward way to iterate enums, we need to iterate through the enum array
        //    filtering out just the numeric enum values (excluding enum names).
        for (var item in GovernedChannel_1.GovernedChannelType) {
            if (GovernedChannel_1.GovernedChannelType.hasOwnProperty(item)) {
                var key = Number(item);
                // skip if key is not numeric enum value, or if already exists
                if (isNaN(key) || this.channels[key]) {
                    continue;
                }
                var defaultData = Governor.defaultChannelData[key];
                var newChannel = new GovernedChannel_1.GovernedChannel(key, defaultData.name, defaultData.cooldownSeconds, null);
                this.channels[key] = newChannel;
            }
        }
    };
    Governor.prototype.loadChannelStatesFromProvider = function () {
        this.channels = [];
        var channelStates = this.channelStateProvider.load();
        for (var key in channelStates) {
            if (channelStates.hasOwnProperty(key)) {
                var channelState = channelStates[key];
                var type = channelState.getType();
                var defaultData = Governor.defaultChannelData[type];
                var newChannel = new GovernedChannel_1.GovernedChannel(type, defaultData.name, defaultData.cooldownSeconds, channelState.getCooldownStartTime());
                this.channels[newChannel.getType()] = newChannel;
            }
        }
    };
    Governor.minute = 60;
    Governor.hour = 60 * Governor.minute;
    Governor.day = 24 * Governor.hour;
    // Array of default values for each channel type. Indexed by the GovernedChannelType enum name.
    Governor.defaultChannelData = [
        new DefaultChannelData(GovernedChannel_1.GovernedChannelType[GovernedChannel_1.GovernedChannelType.Standard], 14 * Governor.day),
        new DefaultChannelData(GovernedChannel_1.GovernedChannelType[GovernedChannel_1.GovernedChannelType.Urgent], 0),
        new DefaultChannelData(GovernedChannel_1.GovernedChannelType[GovernedChannel_1.GovernedChannelType.Banner], 15 * Governor.day),
        new DefaultChannelData(GovernedChannel_1.GovernedChannelType[GovernedChannel_1.GovernedChannelType.TeachingMessage], 15 * Governor.day),
    ];
    return Governor;
}());
exports.Governor = Governor;
