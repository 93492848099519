"use strict";
/**
 * Constants.ts
 *
 * A module for all the constants.
 */
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Telemetry events
 */
var TelemetryEvent = /** @class */ (function () {
    function TelemetryEvent() {
    }
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5;
    TelemetryEvent.CampaignState = (_a = /** @class */ (function () {
            function class_1() {
            }
            return class_1;
        }()),
        _a.Deserialize = (_b = /** @class */ (function () {
                function class_2() {
                }
                return class_2;
            }()),
            _b.Failed = "FloodgateCore_CampaignState_Deserialize_Failed",
            _b),
        _a);
    TelemetryEvent.FileBasedCampaignStateProvider = (_c = /** @class */ (function () {
            function class_3() {
            }
            return class_3;
        }()),
        _c.Load = (_d = /** @class */ (function () {
                function class_4() {
                }
                return class_4;
            }()),
            _d.Failed = "FloodgateCore_FileBasedCampaignStateProvider_Load_Failed",
            _d),
        _c);
    TelemetryEvent.HostBasedCampaignStateProvider = (_e = /** @class */ (function () {
            function class_5() {
            }
            return class_5;
        }()),
        _e.GetStatesFromHost = (_f = /** @class */ (function () {
                function class_6() {
                }
                return class_6;
            }()),
            _f.Failed = "FloodgateCore_HostBasedCampaignStateProvider_GetStatesFromHost_Failed",
            _f),
        _e);
    TelemetryEvent.Floodgate = (_g = /** @class */ (function () {
            function class_7() {
            }
            return class_7;
        }()),
        _g.Initialize = (_h = /** @class */ (function () {
                function class_8() {
                }
                return class_8;
            }()),
            _h.Failed = "FloodgateCore_Floodgate_Initialize_Failed",
            _h),
        _g.Start = (_j = /** @class */ (function () {
                function class_9() {
                }
                return class_9;
            }()),
            _j.Failed = "FloodgateCore_Floodgate_Start_Failed",
            _j),
        _g);
    TelemetryEvent.FloodgateEngine = (_k = /** @class */ (function () {
            function class_10() {
            }
            return class_10;
        }()),
        _k.Make = (_l = /** @class */ (function () {
                function class_11() {
                }
                return class_11;
            }()),
            _l.Failed = "FloodgateCore_FloodgateEngine_Make_Failed",
            _l),
        _k.Start = (_m = /** @class */ (function () {
                function class_12() {
                }
                return class_12;
            }()),
            _m.Failed = "FloodgateCore_FloodgateEngine_Start_Failed",
            _m),
        _k.StartAsync = (_o = /** @class */ (function () {
                function class_13() {
                }
                return class_13;
            }()),
            _o.Failed = "FloodgateCore_FloodgateEngine_StartAsync_Failed",
            _o.Stopped = "FloodgateCore_FloodgateEngine_StartAsync_Stopped",
            _o),
        _k.OnSurveyActivated = (_p = /** @class */ (function () {
                function class_14() {
                }
                return class_14;
            }()),
            _p.ActivationStatsSuppressedSurvey = "FloodgateCore_FloodgateEngine_OnSurveyActivated_ActivationStatsSuppressedSurvey",
            _p.ClosedChannelType = "FloodgateCore_FloodgateEngine_OnSurveyActivated_ClosedChannelType",
            _p),
        _k);
    TelemetryEvent.GovernedChannelState = (_q = /** @class */ (function () {
            function class_15() {
            }
            return class_15;
        }()),
        _q.Deserialize = (_r = /** @class */ (function () {
                function class_16() {
                }
                return class_16;
            }()),
            _r.Failed = "FloodgateCore_GovernedChannelState_Deserialize_Failed",
            _r),
        _q);
    TelemetryEvent.FileBasedGovernedChannelStateProvider = (_s = /** @class */ (function () {
            function class_17() {
            }
            return class_17;
        }()),
        _s.Load = (_t = /** @class */ (function () {
                function class_18() {
                }
                return class_18;
            }()),
            _t.Failed = "FloodgateCore_FileBasedGovernedChannelStateProvider_Load_Failed",
            _t),
        _s);
    TelemetryEvent.HostBasedGovernedChannelStateProvider = (_u = /** @class */ (function () {
            function class_19() {
            }
            return class_19;
        }()),
        _u.GetStatesFromHost = (_v = /** @class */ (function () {
                function class_20() {
                }
                return class_20;
            }()),
            _v.Failed = "FloodgateCore_HostBasedGovernedChannelStateProvider_GetStatesFromHost_Failed",
            _v),
        _u);
    TelemetryEvent.SurveyStatCollectionActivation = (_w = /** @class */ (function () {
            function class_21() {
            }
            return class_21;
        }()),
        _w.FromJson = (_x = /** @class */ (function () {
                function class_22() {
                }
                return class_22;
            }()),
            _x.Failed = "FloodgateCore_SurveyStatCollectionActivation_FromJson_Failed",
            _x),
        _w.ToJson = (_y = /** @class */ (function () {
                function class_23() {
                }
                return class_23;
            }()),
            _y.Failed = "FloodgateCore_SurveyStatCollectionActivation_ToJson_Failed",
            _y),
        _w);
    TelemetryEvent.HostBasedSurveyActivationStatsProvider = (_z = /** @class */ (function () {
            function class_24() {
            }
            return class_24;
        }()),
        _z.GetSurveyStatsFromHost = (_0 = /** @class */ (function () {
                function class_25() {
                }
                return class_25;
            }()),
            _0.Failed = "FloodgateCore_HostBasedSurveyActivationStatsProvider_GetSurveyStatsFromHost_Failed",
            _0),
        _z);
    TelemetryEvent.SurveyStatCollectionEventActivity = (_1 = /** @class */ (function () {
            function class_26() {
            }
            return class_26;
        }()),
        _1.FromJson = (_2 = /** @class */ (function () {
                function class_27() {
                }
                return class_27;
            }()),
            _2.Failed = "FloodgateCore_SurveyStatCollectionEventActivity_FromJson_Failed",
            _2),
        _1.ToJson = (_3 = /** @class */ (function () {
                function class_28() {
                }
                return class_28;
            }()),
            _3.Failed = "FloodgateCore_SurveyStatCollectionEventActivity_ToJson_Failed",
            _3),
        _1);
    TelemetryEvent.SurveyActivity = (_4 = /** @class */ (function () {
            function class_29() {
            }
            return class_29;
        }()),
        _4.LogActivity = (_5 = /** @class */ (function () {
                function class_30() {
                }
                return class_30;
            }()),
            _5.EventsDropped = "FloodgateCore_SurveyActivity_LogActivity_EventsDropped",
            _5),
        _4);
    return TelemetryEvent;
}());
exports.TelemetryEvent = TelemetryEvent;
