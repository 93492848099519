"use strict";
var IPrompt;
(function (IPrompt) {
    var PromptButton;
    (function (PromptButton) {
        PromptButton[PromptButton["Unselected"] = 0] = "Unselected";
        PromptButton[PromptButton["Yes"] = 1] = "Yes";
        PromptButton[PromptButton["No"] = 2] = "No";
    })(PromptButton = IPrompt.PromptButton || (IPrompt.PromptButton = {}));
})(IPrompt || (IPrompt = {}));
module.exports = IPrompt;
