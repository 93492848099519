"use strict";
var IActivityListener;
(function (IActivityListener) {
    var ActivityName = /** @class */ (function () {
        function ActivityName() {
        }
        ActivityName.AppLaunch = "AppLaunch";
        ActivityName.AppUsageTime = "AppUsageTime";
        ActivityName.AppResume = "AppResume";
        return ActivityName;
    }());
    IActivityListener.ActivityName = ActivityName;
})(IActivityListener || (IActivityListener = {}));
module.exports = IActivityListener;
