"use strict";
var ISurveyInfo;
(function (ISurveyInfo) {
    var LaunchType;
    (function (LaunchType) {
        // No launch type specified, defer to the launcher factory code
        LaunchType[LaunchType["Default"] = "Default"] = "Default";
        // Prefer a notification-based launcher (like the windows toast launcher when present)
        LaunchType[LaunchType["Notification"] = "Notification"] = "Notification";
        // Prefer a modal-dialog based launcher
        LaunchType[LaunchType["Modal"] = "Modal"] = "Modal";
    })(LaunchType = ISurveyInfo.LaunchType || (ISurveyInfo.LaunchType = {}));
    var AdditionalDataType;
    (function (AdditionalDataType) {
        // Request email address
        AdditionalDataType[AdditionalDataType["EmailAddress"] = 0] = "EmailAddress";
    })(AdditionalDataType = ISurveyInfo.AdditionalDataType || (ISurveyInfo.AdditionalDataType = {}));
    ISurveyInfo.DOM_TYPE_TAGNAME = "Type";
    ISurveyInfo.DOM_TYPE_VALUE = "Survey";
    ISurveyInfo.DOM_ID_TAGNAME = "SurveyID";
    ISurveyInfo.JSON_SURVEY_KEYNAME = "survey";
    ISurveyInfo.JSON_ID_KEYNAME = "surveyId";
})(ISurveyInfo || (ISurveyInfo = {}));
module.exports = ISurveyInfo;
