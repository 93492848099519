﻿/**
 * SingleFormTemplate.ts
 *
 * A form template with contact info fields.
 */

import * as UIStrings from "./../../../UIStrings/UIStrings";
import {FormTemplateType} from "./FormTemplateType";
import {IUIAsJson} from "./../../Renderer";
import * as CategoriesDropdown from "./../../CategoriesDropdown";
import * as RatingControl from "./../../RatingControl";
import * as Configuration from "./../../../Configuration/Configuration";
import * as Constants from "./../../../Constants";

/**
 * Generate the markup
 * @returns the markup as json
 */
export function generate(): IUIAsJson {
	let categories = Configuration.get().getInAppFeedbackLaunchOptions().categories;

	let formSchema = {
		children: [
			{
				attributes: [{ name: Constants.AttributeName.For, value: Constants.IDs.SingleFormComment }],
				classes: [
					Constants.Classes.FontSubtitle,
					Constants.Classes.TextAlignLeft,
					Constants.Classes.FormQuestionMiddleText,
				],
				id: Constants.IDs.SingleFormQuestionMiddleText,
				innerText: UIStrings.getUIStrings().SingleForm.Title,
				tag: Constants.Tags.Label,
			},
			{
				attributes: [{ name: Constants.AttributeName.Id, value: Constants.IDs.SingleFormCategoriesDropdown }],
				brs: categories.show,
				children: CategoriesDropdown.generate(categories ? categories.customCategories : null),
				classes: [Constants.Classes.FontSubText, Constants.Classes.FormCategoriesDropdown, Constants.Classes.TextInput],
				tag: Constants.Tags.Select,
			},
			{
				attributes: [
					{
						name: Constants.AttributeName.Placeholder,
						value: UIStrings.getUIStrings().Form.CommentPlaceholder,
					},
					{
						name: Constants.AttributeName.MaxLength,
						value: Constants.AttributeValue.TextAreaMaxLength,
					},
				],
				classes: [Constants.Classes.FontSubText, Constants.Classes.FormComment, Constants.Classes.TextInput],
				id: Constants.IDs.SingleFormComment,
				tag: Constants.Tags.TextArea,
			},
			{
				brs: false, // ratings are disabled for now
				children: [
					{
						attributes: [{ name: Constants.AttributeName.For, value: Constants.IDs.SingleFormRating }],
						classes: [
							Constants.Classes.FontSubText,
							Constants.Classes.TextAlignLeft,
							Constants.Classes.FormRatingLabel,
						],
						innerText: UIStrings.getUIStrings().Form.RatingLabel,
						tag: Constants.Tags.Label,
					},
					RatingControl.generate(Constants.IDs.SingleFormRating),
				],
				classes: [Constants.Classes.FormRatingContainer],
			},
			{
				attributes: [
					{
						name: Constants.AttributeName.Type,
						value: Constants.AttributeValue.Text,
					},
					{
						name: Constants.AttributeName.Placeholder,
						value: UIStrings.getUIStrings().Form.EmailPlaceholder,
					},
					{
						name: Constants.AttributeName.AriaLabel,
						value: UIStrings.getUIStrings().Form.EmailPlaceholder,
					},
					{
						name: Constants.AttributeName.Name,
						value: Constants.IDs.SingleFormEmailInput,
					},
					{
						name: Constants.AttributeName.MaxLength,
						value: Constants.AttributeValue.TextAreaMaxLength,
					},
					{
						name: Constants.AttributeName.Value,
						value:
							Configuration.get().getInAppFeedbackInitOptions().userEmail
							? Configuration.get().getInAppFeedbackInitOptions().userEmail
							: "",
					},
				],
				classes: [Constants.Classes.FontSubText, Constants.Classes.FormEmailInput, Constants.Classes.TextInput],
				id: Constants.IDs.SingleFormEmailInput,
				tag: Constants.Tags.Input,
			},
			{
				children:
				[
					{
						attributes: [
							{
								name: Constants.AttributeName.HRef,
								value: Constants.Urls.PrivacyStatementLink,
							},
							{
								name: Constants.AttributeName.Target,
								value: Constants.AttributeValue.BlankWindow,
							},
							{
								name: Constants.AttributeName.Rel,
								value: Constants.AttributeValue.NoReferrer,
							},
						],
						classes: [Constants.Classes.Link],
						id: Constants.IDs.PrivacyStatementLink,
						innerText: UIStrings.getUIStrings().PrivacyStatement,
						tag: Constants.Tags.Anchor,
					},
				],
				classes: [Constants.Classes.FontSubSubText, Constants.Classes.TextAlignLeft,
					Constants.Classes.PrivacyStatementLinkDiv],
			},
			{
				children: [
					{
						children: [
							{
								attributes: [
									{
										name: Constants.AttributeName.Type,
										value: Constants.AttributeValue.Checkbox,
									},
									{
										name: Constants.AttributeName.Value,
										value: Constants.AttributeValue.Unchecked,
									},
								],
								brs: Configuration.get().getInAppFeedbackInitOptions().screenshot,
								classes: [Constants.Classes.FormScreenshotCheckbox, Constants.Classes.CheckBox],
								id: Constants.IDs.SingleFormScreenshotCheckbox,
								tag: Constants.Tags.Input,
							},
							{
								attributes: [{ name: Constants.AttributeName.For, value: Constants.IDs.SingleFormScreenshotCheckbox }],
								brs: Configuration.get().getInAppFeedbackInitOptions().screenshot,
								classes: [Constants.Classes.FontText, Constants.Classes.TextAlignLeft, Constants.Classes.FormScreenshotLabel],
								innerText: UIStrings.getUIStrings().Form.ScreenshotLabel,
								tag: Constants.Tags.Label,
							},
						],
						classes: [Constants.Classes.FormScreenshotContainer],
					},
					{
						children: [
							{
								classes: [
									Constants.Classes.FontSubText,
									Constants.Classes.SubmitButton,
								],
								id: Constants.IDs.SingleFormSubmitButton,
								innerText: UIStrings.getUIStrings().Form.Submit,
								tag: Constants.Tags.Button,
							},
							{
								classes: [Constants.Classes.Spinner, Constants.Classes.Hidden],
								id: Constants.IDs.SingleFormSubmitButtonSpinner,
								tag: Constants.Tags.Div,
							},
						],
						classes: [Constants.Classes.FormSubmitButtonContainer],
					},
				],
				classes: [Constants.Classes.FormBottomContainer],
			},
		],
		id: containerId,
	};
	return formSchema;
}

/**
 * The id for the container. This is the id of the top-most element and should
 * be unique to each form
 */
export let containerId: string = Constants.IDs.SingleFormContainer;

/**
 * Forms may be used for different feedback types. This method executes form
 * specific logic when the feedback type is selected
 * @param feedbackType feedback type
 */
export function onSelect(feedbackType: Constants.FeedbackType): void {
	document.getElementById(Constants.IDs.SingleFormComment).focus();
}

/**
 * Form template type
 */
export let type: FormTemplateType = FormTemplateType.Single;
