"use strict";
var IFloodgateStorageProvider;
(function (IFloodgateStorageProvider) {
    /**
     * Enum for the files used by floodgate
     */
    var FileType;
    (function (FileType) {
        FileType[FileType["FloodgateSettings"] = 0] = "FloodgateSettings";
        FileType[FileType["SurveyActivationStats"] = 1] = "SurveyActivationStats";
        FileType[FileType["SurveyEventActivityStats"] = 2] = "SurveyEventActivityStats";
        FileType[FileType["CampaignDefinitions"] = 3] = "CampaignDefinitions";
        FileType[FileType["CampaignStates"] = 4] = "CampaignStates";
        FileType[FileType["GovernedChannelStates"] = 5] = "GovernedChannelStates";
        FileType[FileType["TmsCache_MessageMetadata"] = 6] = "TmsCache_MessageMetadata";
        FileType[FileType["TmsCache_UserGovernance"] = 7] = "TmsCache_UserGovernance";
        FileType[FileType["TmsCache_CampaignContent"] = 8] = "TmsCache_CampaignContent";
        FileType[FileType["Tms_DynamicSettings"] = 9] = "Tms_DynamicSettings";
        FileType[FileType["LogLevelSettings"] = 10] = "LogLevelSettings";
    })(FileType = IFloodgateStorageProvider.FileType || (IFloodgateStorageProvider.FileType = {}));
})(IFloodgateStorageProvider || (IFloodgateStorageProvider = {}));
module.exports = IFloodgateStorageProvider;
