"use strict";
var IFloodgateStringProvider;
(function (IFloodgateStringProvider) {
    /**
     * Enum for the strings used by floodgate
     */
    var StringType;
    (function (StringType) {
        StringType[StringType["NpsPromptTitle"] = 0] = "NpsPromptTitle";
        StringType[StringType["NpsPromptQuestion"] = 1] = "NpsPromptQuestion";
        StringType[StringType["NpsPromptYesLabel"] = 2] = "NpsPromptYesLabel";
        StringType[StringType["NpsPromptNotNowLabel"] = 3] = "NpsPromptNotNowLabel";
        StringType[StringType["NpsRatingQuestion"] = 4] = "NpsRatingQuestion";
        StringType[StringType["Nps11RatingValue0"] = 5] = "Nps11RatingValue0";
        StringType[StringType["Nps11RatingValue1"] = 6] = "Nps11RatingValue1";
        StringType[StringType["Nps11RatingValue2"] = 7] = "Nps11RatingValue2";
        StringType[StringType["Nps11RatingValue3"] = 8] = "Nps11RatingValue3";
        StringType[StringType["Nps11RatingValue4"] = 9] = "Nps11RatingValue4";
        StringType[StringType["Nps11RatingValue5"] = 10] = "Nps11RatingValue5";
        StringType[StringType["Nps11RatingValue6"] = 11] = "Nps11RatingValue6";
        StringType[StringType["Nps11RatingValue7"] = 12] = "Nps11RatingValue7";
        StringType[StringType["Nps11RatingValue8"] = 13] = "Nps11RatingValue8";
        StringType[StringType["Nps11RatingValue9"] = 14] = "Nps11RatingValue9";
        StringType[StringType["Nps11RatingValue10"] = 15] = "Nps11RatingValue10";
        StringType[StringType["Nps5RatingValue1"] = 16] = "Nps5RatingValue1";
        StringType[StringType["Nps5RatingValue2"] = 17] = "Nps5RatingValue2";
        StringType[StringType["Nps5RatingValue3"] = 18] = "Nps5RatingValue3";
        StringType[StringType["Nps5RatingValue4"] = 19] = "Nps5RatingValue4";
        StringType[StringType["Nps5RatingValue5"] = 20] = "Nps5RatingValue5";
        StringType[StringType["NpsCommentQuestion"] = 21] = "NpsCommentQuestion";
        StringType[StringType["PsatPromptTitle"] = 22] = "PsatPromptTitle";
        StringType[StringType["PsatPromptQuestion"] = 23] = "PsatPromptQuestion";
        StringType[StringType["PsatPromptYesLabel"] = 24] = "PsatPromptYesLabel";
        StringType[StringType["PsatPromptNotNowLabel"] = 25] = "PsatPromptNotNowLabel";
        StringType[StringType["PsatRatingQuestion"] = 26] = "PsatRatingQuestion";
        StringType[StringType["PsatRatingValue1"] = 27] = "PsatRatingValue1";
        StringType[StringType["PsatRatingValue2"] = 28] = "PsatRatingValue2";
        StringType[StringType["PsatRatingValue3"] = 29] = "PsatRatingValue3";
        StringType[StringType["PsatRatingValue4"] = 30] = "PsatRatingValue4";
        StringType[StringType["PsatRatingValue5"] = 31] = "PsatRatingValue5";
        StringType[StringType["PsatCommentQuestion"] = 32] = "PsatCommentQuestion";
        StringType[StringType["BpsPromptTitle"] = 33] = "BpsPromptTitle";
        StringType[StringType["BpsPromptQuestion"] = 34] = "BpsPromptQuestion";
        StringType[StringType["BpsPromptYesLabel"] = 35] = "BpsPromptYesLabel";
        StringType[StringType["BpsPromptNotNowLabel"] = 36] = "BpsPromptNotNowLabel";
        StringType[StringType["BpsRatingQuestion"] = 37] = "BpsRatingQuestion";
        StringType[StringType["BpsRatingValue1"] = 38] = "BpsRatingValue1";
        StringType[StringType["BpsRatingValue2"] = 39] = "BpsRatingValue2";
        StringType[StringType["BpsCommentQuestion"] = 40] = "BpsCommentQuestion";
    })(StringType = IFloodgateStringProvider.StringType || (IFloodgateStringProvider.StringType = {}));
})(IFloodgateStringProvider || (IFloodgateStringProvider = {}));
module.exports = IFloodgateStringProvider;
