"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ActivityTrackingData_1 = require("./ActivityTrackingData");
var ActivityTrackingSet_1 = require("./ActivityTrackingSet");
var ISurveyEvent = require("./ISurveyEvent");
var CountedActivityEvent = /** @class */ (function () {
    function CountedActivityEvent(data) {
        if (!data) {
            throw new Error("data must not be null");
        }
        if (data.count <= 0) {
            throw new Error("count must be greater than 0");
        }
        if (!data.activity || data.activity.length === 0) {
            throw new Error("activity must not be null or an empty string");
        }
        this.data = data;
    }
    CountedActivityEvent.make = function (data) {
        try {
            return new CountedActivityEvent(data);
        }
        catch (e) {
            return null;
        }
    };
    CountedActivityEvent.prototype.getActivity = function () {
        return this.data.activity;
    };
    CountedActivityEvent.prototype.getCount = function () {
        return this.data.count;
    };
    CountedActivityEvent.prototype.isAggregate = function () {
        return this.data.isAggregate;
    };
    CountedActivityEvent.prototype.getType = function () {
        return ISurveyEvent.Type.CountedActivity;
    };
    CountedActivityEvent.prototype.getTrackingSet = function () {
        var trackingDataList = new Array();
        trackingDataList.push(new ActivityTrackingData_1.ActivityTrackingData(this.data.activity, this.data.count, this.data.isAggregate));
        return new ActivityTrackingSet_1.ActivityTrackingSet(false, trackingDataList);
    };
    return CountedActivityEvent;
}());
exports.CountedActivityEvent = CountedActivityEvent;
/**
 * Data class for serialization and deserialization. Do not add logic in here.
 */
var CountedActivityEventData = /** @class */ (function () {
    function CountedActivityEventData() {
    }
    return CountedActivityEventData;
}());
exports.CountedActivityEventData = CountedActivityEventData;
var CountedActivitySequenceEvent = /** @class */ (function () {
    function CountedActivitySequenceEvent(data) {
        if (!data) {
            throw new Error("data must not be null");
        }
        if (!data.sequence) {
            throw new Error("data.sequence must not be null");
        }
        if (data.sequence.length === 0) {
            throw new Error("data.sequence size must be greater than 0");
        }
        this.data = [];
        for (var _i = 0, _a = data.sequence; _i < _a.length; _i++) {
            var countedActivityEventData = _a[_i];
            this.data.push(new CountedActivityEvent(countedActivityEventData));
        }
    }
    CountedActivitySequenceEvent.make = function (data) {
        try {
            return new CountedActivitySequenceEvent(data);
        }
        catch (e) {
            return null;
        }
    };
    CountedActivitySequenceEvent.prototype.getSequence = function () {
        return this.data;
    };
    CountedActivitySequenceEvent.prototype.getType = function () {
        return ISurveyEvent.Type.CountedActivitySequence;
    };
    CountedActivitySequenceEvent.prototype.getTrackingSet = function () {
        var trackingDataList = new Array();
        for (var _i = 0, _a = this.data; _i < _a.length; _i++) {
            var countedActivityEvent = _a[_i];
            trackingDataList.push(new ActivityTrackingData_1.ActivityTrackingData(countedActivityEvent.getActivity(), countedActivityEvent.getCount(), countedActivityEvent.isAggregate()));
        }
        return new ActivityTrackingSet_1.ActivityTrackingSet(true, trackingDataList);
    };
    return CountedActivitySequenceEvent;
}());
exports.CountedActivitySequenceEvent = CountedActivitySequenceEvent;
/**
 * Data class for serialization and deserialization. Do not add logic in here.
 */
var CountedActivitySequenceEventData = /** @class */ (function () {
    function CountedActivitySequenceEventData() {
    }
    /**
     * No args constructor needed for serialization.
     */
    CountedActivitySequenceEventData.prototype.CountedActivitySequenceEventData = function () {
    };
    return CountedActivitySequenceEventData;
}());
exports.CountedActivitySequenceEventData = CountedActivitySequenceEventData;
