"use strict";
var ISurvey;
(function (ISurvey) {
    var Type;
    (function (Type) {
        // A Feedback survey. Contains a single question with a free-form text response
        Type[Type["Feedback"] = 0] = "Feedback";
        // An NPS (net promoter score) survey. Asks user to rate "whether or not they would recommend this product to family/friends".
        // Contains a prompt, question, and rating
        Type[Type["Nps"] = 1] = "Nps";
        // A PSAT (product satisfaction) survey. Asks user to rate "overall, based on their experience, how satisifed are they with this app"
        // Contains a prompt, question, and rating
        Type[Type["Psat"] = 2] = "Psat";
        // A BPS (build promotion) survey. Asks user to choose between Yes and No options of promoting the current build to the next audience ring
        // Contains a prompt, question, and rating (Yes/No)
        Type[Type["Bps"] = 3] = "Bps";
        // A FPS (feature promotion) survey. Asks user to rate a given app feature.
        // Contains a prompt, question, and rating
        Type[Type["Fps"] = 4] = "Fps";
        // A NLQS (net language quality score) survey. Asks user to rate the language quality.
        // Contains a prompt, question, and rating
        Type[Type["Nlqs"] = 5] = "Nlqs";
        // A Generic surface survey that uses content metadata to render a surface.
        // As of 4th Feb 2019 there are 11 types defined in Mso hence giving a value of 12.
        Type[Type["GenericMessagingSurface"] = 12] = "GenericMessagingSurface";
    })(Type = ISurvey.Type || (ISurvey.Type = {}));
    ISurvey.DOM_FEEDBACKSURVEY_TAGNAME = "FeedbackSurvey";
    ISurvey.DOM_NPS_TAGNAME = "Nps";
    ISurvey.DOM_PSAT_TAGNAME = "Psat";
    ISurvey.DOM_BPS_TAGNAME = "Bps";
    ISurvey.DOM_FPS_TAGNAME = "Fps";
    ISurvey.DOM_NLQS_TAGNAME = "Nlqs";
    // If the launcher type is null or undefined in campaign definition then this value will be used as default
    ISurvey.LAUNCHER_TYPE_DEFAULT = "survey";
})(ISurvey || (ISurvey = {}));
module.exports = ISurvey;
