﻿/*
 * Utils.ts
 *
 * Module for utility functions
 */

/**
 * Check if given value is a number
 * @param {any} value value
 */
function isNumber(value: any): boolean {
	return (typeof value === "number");
}

/**
 * Check if given value is an integer
 * @param {any} value value
 */
export function isInteger(value: any): boolean {
	return typeof value === "number" &&
		isFinite(value) &&
		Math.floor(value) === value;
}

/**
 * Check if given value is a string
 * @param {any} value value
 */
function isString(value: any): boolean {
	return (typeof value === "string");
}

/**
 * Check if value is an object
 * @param {any} value value
 */
function isObject(value: any): boolean {
	return (!isNullorUndefined(value) && typeof (value) === "object");
}

/**
 * Check if value is an object
 * @param {any} value value
 */
export function isBoolean(value: any): boolean {
	return typeof (value) === "boolean";
}

/**
 * Check if value is "null" or "undefined"
 * @param {any} value value
 */
export function isNullorUndefined(value: any): boolean {
	return (value === null || value === undefined);
}

/**
 * Check if value is a valid guid
 * @param {any} value value
 */
function isGuid(value: any): boolean {
	return (isString(value) &&
		/^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/.test(value));
}

/**
 * Throw if not object
 */
export function expectObject(value: any, name: string): void {
	if (!isObject(value)) {
		throw name + " is not an object: " + value;
	}
}

/**
 * Throw if not number
 */
export function expectNumber(value: any, name: string): void {
	if (!isNumber(value)) {
		throw name + " is not a number: " + value;
	}
}

/**
 * Throw if not string
 */
export function expectString(value: any, name: string): void {
	if (!isString(value)) {
		throw name + " is not a string: " + value;
	}
}

/**
 * Throw if not boolean
 */
export function expectBoolean(value: any, name: string): void {
	if (!isBoolean(value)) {
		throw name + " is not a boolean: " + value;
	}
}

/**
 * Throw if not guid
 */
export function expectGuid(value: any, name: string): void {
	if (!isGuid(value)) {
		throw name + " is not a guid: " + value;
	}
}

/**
 * Throw if not array
 */
export function expectArray(value: any, name: string): void {
	if (!Array.isArray(value)) {
		throw name + " is not an array: " + value;
	}
}

/**
 * Create guid string
 */
export function guid(): string {
	// Stitch in '4' in the third group
	return (randomHex4() + randomHex4() + "-" + randomHex4() + "-4" + randomHex4().substr(0, 3) + "-" + randomHex4() + "-"
		+ randomHex4() + randomHex4() + randomHex4()).toLowerCase();
}

/**
 * Create random Hex4 string
 */
function randomHex4(): string {
	return (Math.floor(((1 + Math.random()) * 0x10000))).toString(16).substring(1);
}

/**
 * Takes two objects (source, target) and returns the target object with values in the source added to it.
 * It overwrites any source properties which already exist in target.
 * @param sourceObject the source
 * @param targetobject the target
 * @returns the result
 */
export function overrideValues<T>(sourceObject: T, targetobject: T): T {
	if (!targetobject) {
		return targetobject;
	}

	let result: T = targetobject;

	if (sourceObject) {
		for (let field in sourceObject) {
			if (sourceObject.hasOwnProperty(field)) {
				(<any> result)[field] = (<any> sourceObject)[field];
			}
		}
	}

	return result;
}
