"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var Utils = require("./Utils");
var SurveyMetadata = /** @class */ (function () {
    function SurveyMetadata(data) {
        if (!data) {
            throw new Error("data must not be null");
        }
        if (!data.contentMetadata) {
            throw new Error("content metadata must not be null");
        }
        if (!Utils.isObject(data.contentMetadata)) {
            throw new Error("content metadata must be of object type");
        }
        this.data = data;
    }
    SurveyMetadata.make = function (data) {
        try {
            return new SurveyMetadata(data);
        }
        catch (e) {
            return null;
        }
    };
    SurveyMetadata.prototype.getContentMetadata = function () {
        return this.data.contentMetadata;
    };
    return SurveyMetadata;
}());
exports.SurveyMetadata = SurveyMetadata;
/**
 * Data class for serialization and deserialization. Do not add logic in here.
 */
var Metadata = /** @class */ (function () {
    function Metadata() {
    }
    return Metadata;
}());
exports.Metadata = Metadata;
