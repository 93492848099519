"use strict";
var ISurveyComponent = require("./../Api/ISurveyComponent");
var RatingComponent = /** @class */ (function () {
    function RatingComponent(data) {
        if (!data) {
            throw new Error("data must not be null");
        }
        if (!data.question) {
            throw new Error("data.question must not be null or empty");
        }
        if (!data.ratingValuesAscending || data.ratingValuesAscending.length < 2) {
            throw new Error("data.ratingValuesAscending must not be null or have less than two choices");
        }
        data.ratingValuesAscending.forEach(function (rating) {
            if (!rating) {
                throw new Error("rating values must not contain null or empty");
            }
        });
        this.data = data;
        this.selectedIndex = -1;
    }
    RatingComponent.make = function (data) {
        try {
            return new RatingComponent(data);
        }
        catch (e) {
            return null;
        }
    };
    RatingComponent.prototype.getType = function () {
        return ISurveyComponent.Type.Rating;
    };
    RatingComponent.prototype.getQuestion = function () {
        return this.data.question;
    };
    RatingComponent.prototype.getRatingValuesAscending = function () {
        return this.data.ratingValuesAscending;
    };
    RatingComponent.prototype.getSelectedRating = function () {
        if (!this.isRatingIndexValid(this.selectedIndex)) {
            return "";
        }
        return this.data.ratingValuesAscending[this.selectedIndex];
    };
    RatingComponent.prototype.setSelectedRatingIndex = function (selected) {
        if (this.isRatingIndexValid(selected)) {
            this.selectedIndex = selected;
        }
        else {
            this.selectedIndex = -1;
        }
    };
    RatingComponent.prototype.getSelectedRatingIndex = function () {
        return this.selectedIndex;
    };
    RatingComponent.prototype.getDomElements = function (doc) {
        if (!doc) {
            throw new Error("Document must not be null");
        }
        var element = doc.createElement(ISurveyComponent.DOM_RATING_TAGNAME);
        if (!this.isRatingIndexValid(this.getSelectedRatingIndex())) {
            element.appendChild(doc.createTextNode("Not rated"));
        }
        else {
            // Enforce six digits after the decimal
            element.appendChild(doc.createTextNode(this.getNormalizedRatingScore().toFixed(6)));
        }
        return [element];
    };
    // @Override
    RatingComponent.prototype.getJsonElements = function () {
        var result = {};
        if (!this.isRatingIndexValid(this.getSelectedRatingIndex())) {
            result[ISurveyComponent.JSON_RATING_KEYNAME] = "Not rated";
        }
        else {
            result[ISurveyComponent.JSON_RATING_KEYNAME] = this.getNormalizedRatingScore();
        }
        return result;
    };
    RatingComponent.prototype.getNormalizedRatingScore = function () {
        if (!this.isRatingIndexValid(this.getSelectedRatingIndex())) {
            return -1;
        }
        if (this.data.isZeroBased) {
            return (this.selectedIndex) / (this.data.ratingValuesAscending.length - 1);
        }
        else {
            return (this.selectedIndex + 1.0) / this.data.ratingValuesAscending.length;
        }
    };
    RatingComponent.prototype.isRatingIndexValid = function (index) {
        return (index >= 0 && index < this.data.ratingValuesAscending.length);
    };
    return RatingComponent;
}());
(function (RatingComponent) {
    var RatingComponentData = /** @class */ (function () {
        function RatingComponentData() {
        }
        return RatingComponentData;
    }());
    RatingComponent.RatingComponentData = RatingComponentData;
})(RatingComponent || (RatingComponent = {}));
module.exports = RatingComponent;
