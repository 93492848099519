"use strict";
var ActivityTracker = require("./ActivityTracker");
var Constants_1 = require("./Constants");
var IndexedTracker_1 = require("./IndexedTracker");
var SurveyStatCollectionEventActivity_1 = require("./SurveyStatCollectionEventActivity");
var droppedActivityCount = 0;
/**
 * This class tracks log calls against named activity strings (matched on strict case-sensitive equality)
 * and calls the provided callback when all activity thresholds for a given survey have been crossed
 */
var SurveyActivityListener = /** @class */ (function () {
    function SurveyActivityListener(callback, loggerCallback) {
        if (!callback) {
            throw new Error("callback must not be null");
        }
        this.trackedActivityMap = {};
        this.callback = callback;
        this.loggerCallback = loggerCallback;
    }
    SurveyActivityListener.prototype.logActivity = function (activityName, increment) {
        if (increment === void 0) { increment = 1; }
        this.logActivity_private(activityName, SurveyActivityListener.LogActionType.Increment, increment, null);
    };
    SurveyActivityListener.prototype.logActivityStartTime = function (activityName, startTime) {
        this.logActivity_private(activityName, SurveyActivityListener.LogActionType.StartTime, 0, startTime);
    };
    SurveyActivityListener.prototype.logActivityStopTime = function (activityName, stopTime) {
        this.logActivity_private(activityName, SurveyActivityListener.LogActionType.StopTime, 0, stopTime);
    };
    SurveyActivityListener.prototype.setSurveys = function (surveys, baseline) {
        if (baseline === void 0) { baseline = new SurveyStatCollectionEventActivity_1.SurveyStatCollectionEventActivity(); }
        if (!surveys || surveys.length === 0 || !baseline) {
            return;
        }
        // Populate a new map based on the passed in surveys, but propagating and tracking activity counts
        // we may have seen against those events
        // NOTE: We will lose activity counts for survey events that are no longer relevant.
        var newMap = new Object();
        for (var _i = 0, surveys_1 = surveys; _i < surveys_1.length; _i++) {
            var survey = surveys_1[_i];
            var trackingSet = survey.getSurveyInfo().getActivationEvent().getTrackingSet();
            // Don't add the survey if any of its events overlap with an already registered survey
            var hasOverlap = false;
            for (var _a = 0, _b = trackingSet.getList(); _a < _b.length; _a++) {
                var trackingData = _b[_a];
                if (newMap.hasOwnProperty(trackingData.getActivity())) {
                    hasOverlap = true;
                    break;
                }
            }
            if (hasOverlap) {
                continue;
            }
            // At this point, the survey's activities are safe to add.
            // Set up the structures we need to transfer baseline counts (from previous sessions) or current counts (from previous trackers)
            // Both vectors must be sorted in the same order as the trackingSet.List items
            var baselineStats = baseline.getBySurveyId(survey.getSurveyInfo().getId());
            var baselineCounts = [trackingSet.getList().length];
            var currentIndexedTrackers = new Array(trackingSet.getList().length);
            var currentBaselineIndex = 0;
            var currentIndex = 0;
            for (var _c = 0, _d = trackingSet.getList(); _c < _d.length; _c++) {
                var trackingData = _d[_c];
                // Baseline stats are only stored for events with "IsAggregate = true"
                baselineCounts[currentIndex] = 0;
                if (trackingData.getIsAggregate() && baselineStats && currentBaselineIndex < baselineStats.Counts.length) {
                    baselineCounts[currentIndex] = baselineStats.Counts[currentBaselineIndex++];
                }
                // Session stats may be available in the previous trackedActivityMap
                // Transfer the old "current session" count to the new tracker
                // Is set to null if not available
                currentIndexedTrackers[currentIndex] = this.trackedActivityMap[trackingData.getActivity()];
                currentIndex++;
            }
            // Make a new tracker and init the counts appropriately.
            var newTracker = new ActivityTracker(trackingSet);
            // If in a future change we start keeping trackers registered past activation, this will change
            newTracker.initCounts(baselineCounts, currentIndexedTrackers, false /*wasActivatedThisSession*/);
            // Setup the indexed trackers
            for (var _e = 0, _f = newTracker.generateActivityIndexList(); _e < _f.length; _e++) {
                var activityIndex = _f[_e];
                var indexedTracker = new IndexedTracker_1.IndexedTracker();
                indexedTracker.index = activityIndex.index;
                indexedTracker.survey = survey;
                indexedTracker.tracker = newTracker;
                // Register the new tracker in our new map
                newMap[activityIndex.activity] = indexedTracker;
            }
        }
        // Set the new map in place of the old
        this.copyObject(this.trackedActivityMap, newMap);
    };
    SurveyActivityListener.prototype.clearSurveys = function () {
        var _this = this;
        Object.getOwnPropertyNames(this.trackedActivityMap).forEach(function (key) {
            delete _this.trackedActivityMap[key];
        });
    };
    SurveyActivityListener.prototype.getCount = function (activity) {
        var indexedTracker;
        indexedTracker = this.trackedActivityMap[activity];
        if (!indexedTracker) {
            return 0;
        }
        return indexedTracker.tracker.getCount(indexedTracker.index);
    };
    SurveyActivityListener.prototype.getSessionCount = function (activity) {
        var indexedTracker;
        indexedTracker = this.trackedActivityMap[activity];
        if (!indexedTracker) {
            return 0;
        }
        return indexedTracker.tracker.getSessionCount(indexedTracker.index);
    };
    /**
     * An atomic get-and-set method.  Returns the current SessionCount, resetting it to zero and adding it into the established baseline
     */
    SurveyActivityListener.prototype.moveSessionCountIntoBaseCount = function (activity) {
        var indexedTracker;
        indexedTracker = this.trackedActivityMap[activity];
        if (!indexedTracker) {
            return 0;
        }
        return indexedTracker.tracker.moveSessionCountIntoBaseCount(indexedTracker.index);
    };
    SurveyActivityListener.prototype.getSurvey = function (activity) {
        var indexedTracker;
        indexedTracker = this.trackedActivityMap[activity];
        if (!indexedTracker) {
            return null;
        }
        return indexedTracker.survey;
    };
    SurveyActivityListener.prototype.setCallback = function (callback) {
        this.callback = callback;
    };
    SurveyActivityListener.prototype.logActivity_private = function (activityName, logType, increment, timestamp) {
        if (this.callback && !this.callback.shouldAcceptActivity(activityName)) {
            droppedActivityCount++;
        }
        // Count any activities that are posted before 'FloodgateFirstStart' activity happens
        if (activityName === SurveyActivityListener.FloodgateStartActivityName) {
            if (droppedActivityCount > 0) {
                var telemetryLogger = this.loggerCallback && this.loggerCallback();
                if (telemetryLogger) {
                    telemetryLogger.log_Event(Constants_1.TelemetryEvent.SurveyActivity.LogActivity.EventsDropped, { Count: droppedActivityCount });
                }
            }
            droppedActivityCount = 0;
        }
        var indexedTracker = this.trackedActivityMap[activityName];
        if (!indexedTracker) {
            return;
        }
        switch (logType) {
            case SurveyActivityListener.LogActionType.StartTime: {
                indexedTracker.tracker.startTime(indexedTracker.index, timestamp);
                return;
            }
            case SurveyActivityListener.LogActionType.StopTime: {
                increment = indexedTracker.tracker.stopTime(indexedTracker.index, timestamp);
                // fall through to increment the activity as well.
            }
            case SurveyActivityListener.LogActionType.Increment: {
                break;
            }
            default: {
                return;
            }
        }
        var result = indexedTracker.tracker.incrementActivity(indexedTracker.index, increment);
        if (result === ActivityTracker.IncrementResult.AllActivitiesActivated) {
            this.executeCallback(indexedTracker.survey);
        }
    };
    SurveyActivityListener.prototype.executeCallback = function (survey) {
        this.callback.run(survey);
    };
    SurveyActivityListener.prototype.copyObject = function (target, source) {
        Object.keys(source).forEach(function (key) {
            target[key] = source[key];
        });
    };
    SurveyActivityListener.FloodgateStartActivityName = "FloodgateFirstStart";
    return SurveyActivityListener;
}());
(function (SurveyActivityListener) {
    var LogActionType;
    (function (LogActionType) {
        LogActionType[LogActionType["Increment"] = 0] = "Increment";
        LogActionType[LogActionType["StartTime"] = 1] = "StartTime";
        LogActionType[LogActionType["StopTime"] = 2] = "StopTime";
    })(LogActionType = SurveyActivityListener.LogActionType || (SurveyActivityListener.LogActionType = {}));
})(SurveyActivityListener || (SurveyActivityListener = {}));
module.exports = SurveyActivityListener;
