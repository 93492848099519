"use strict";
var ISurvey = require("../../src/Api/ISurvey");
var GovernedChannel_1 = require("../GovernedChannel");
var ISurveyInfo = require("../ISurveyInfo");
var Utils = require("../Utils");
var SurveyDataSource = /** @class */ (function () {
    function SurveyDataSource(data) {
        if (Utils.isNullOrUndefined(data)) {
            throw new Error("data must not be null");
        }
        if (Utils.isNullOrUndefined(data.id) || data.id === "") {
            throw new Error("data.id must not be null or empty");
        }
        if (Utils.isNullOrUndefined(data.governedChannelType)) {
            data.governedChannelType = GovernedChannel_1.GovernedChannelType.getDefault();
        }
        if (Utils.isNullOrUndefined(data.expirationTimeUtc) || data.expirationTimeUtc === "") {
            throw new Error("data.expirationTimeUtc must not be null or empty");
        }
        if (Utils.isNullOrUndefined(data.activationEvent)) {
            throw new Error("data.activationEvent must not be null");
        }
        if (Utils.isNullOrUndefined(data.backEndIdentifier) || data.backEndIdentifier === "") {
            data.backEndIdentifier = data.id;
        }
        if (Utils.isNullOrUndefined(data.launcherType)) {
            data.launcherType = ISurvey.LAUNCHER_TYPE_DEFAULT;
        }
        this.data = data;
    }
    // @Override
    SurveyDataSource.prototype.getId = function () {
        return this.data.id;
    };
    // @Override
    SurveyDataSource.prototype.getBackEndId = function () {
        return this.data.backEndIdentifier;
    };
    // @Override
    SurveyDataSource.prototype.getGovernedChannelType = function () {
        return this.data.governedChannelType;
    };
    // @Override
    SurveyDataSource.prototype.getRawStartTimeUtc = function () {
        return this.data.startTimeUtc;
    };
    // @Override
    SurveyDataSource.prototype.getStartTimeUtc = function () {
        if (Utils.isNullOrUndefined(this.data.startTimeUtc)) {
            return Utils.getDistantPast(); // Optional start date means the survey has started
        }
        var parsed = new Date(this.data.startTimeUtc);
        if (!Utils.isDate(parsed)) {
            return Utils.getDistantFuture(); // Badly specified start dates means the survey never starts
        }
        return parsed;
    };
    // @Override
    SurveyDataSource.prototype.getExpirationTimeUtc = function () {
        // Bad or missing expiration date means survey is always expired.
        if (Utils.isNullOrUndefined(this.data.expirationTimeUtc)) {
            return Utils.getDistantPast();
        }
        var parsed = new Date(this.data.expirationTimeUtc);
        if (!Utils.isDate(parsed)) {
            return Utils.getDistantPast();
        }
        return parsed;
    };
    // @Override
    SurveyDataSource.prototype.getRawExpirationTimeUtc = function () {
        return this.data.expirationTimeUtc;
    };
    // @Override
    SurveyDataSource.prototype.isActiveForDate = function (date) {
        if (Utils.isNullOrUndefined(date)) {
            return false;
        }
        return (date >= this.getStartTimeUtc() && date <= this.getExpirationTimeUtc());
    };
    // @Override
    SurveyDataSource.prototype.getActivationEvent = function () {
        return this.data.activationEvent;
    };
    // @Override
    SurveyDataSource.prototype.getPreferredLaunchType = function () {
        return this.data.preferredLaunchType;
    };
    // @Override
    SurveyDataSource.prototype.isAdditionalDataRequested = function (additionalDataToCheck) {
        if (Utils.isNullOrUndefined(this.data.additionalDataRequested)) {
            return false;
        }
        for (var _i = 0, _a = this.data.additionalDataRequested; _i < _a.length; _i++) {
            var additionalData = _a[_i];
            if (additionalData === additionalDataToCheck) {
                return true;
            }
        }
        return false;
    };
    // @Override
    SurveyDataSource.prototype.getLauncherType = function () {
        return this.data.launcherType;
    };
    // @Override
    SurveyDataSource.prototype.getMetadata = function () {
        return this.data.metadata;
    };
    // @Override
    SurveyDataSource.prototype.getDomElements = function (doc) {
        if (!doc) {
            throw new Error("Document must not be null");
        }
        var typeElement = doc.createElement(ISurveyInfo.DOM_TYPE_TAGNAME);
        typeElement.appendChild(doc.createTextNode(ISurveyInfo.DOM_TYPE_VALUE));
        var idElement = doc.createElement(ISurveyInfo.DOM_ID_TAGNAME);
        idElement.appendChild(doc.createTextNode(this.getBackEndId()));
        return [typeElement, idElement];
    };
    // @Override
    SurveyDataSource.prototype.getJsonElements = function () {
        var surveyObject = {};
        surveyObject[ISurveyInfo.JSON_ID_KEYNAME] = this.getBackEndId();
        var result = {};
        result[ISurveyInfo.JSON_SURVEY_KEYNAME] = surveyObject;
        return result;
    };
    return SurveyDataSource;
}());
(function (SurveyDataSource) {
    /**
     * Basic data needed for all Surveys
     */
    var SurveyDataSourceData = /** @class */ (function () {
        function SurveyDataSourceData() {
            this.preferredLaunchType = ISurveyInfo.LaunchType.Default;
        }
        return SurveyDataSourceData;
    }());
    SurveyDataSource.SurveyDataSourceData = SurveyDataSourceData;
})(SurveyDataSource || (SurveyDataSource = {}));
module.exports = SurveyDataSource;
