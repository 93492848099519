"use strict";
var ISurveyComponent;
(function (ISurveyComponent) {
    var Type;
    (function (Type) {
        // A quick pop-up requesting participation in a survey
        Type[Type["Prompt"] = "Prompt"] = "Prompt";
        // A question paired with a free-form text response
        Type[Type["Comment"] = "Comment"] = "Comment";
        // A question asking for a selection from a list of allowed values with numeric meaning
        Type[Type["Rating"] = "Rating"] = "Rating";
        // A question asking for one or more selections from a list of allowed values
        Type[Type["MultipleChoice"] = "MultipleChoice"] = "MultipleChoice";
    })(Type = ISurveyComponent.Type || (ISurveyComponent.Type = {}));
    ISurveyComponent.DOM_PROMPT_TAGNAME = "Prompt";
    ISurveyComponent.DOM_COMMENT_TAGNAME = "Comment";
    ISurveyComponent.DOM_RATING_TAGNAME = "Rating";
    ISurveyComponent.DOM_MULTIPLECHOICE_TAGNAME = "MultipleChoice";
    ISurveyComponent.JSON_PROMPT_KEYNAME = "prompt";
    ISurveyComponent.JSON_COMMENT_KEYNAME = "comment";
    ISurveyComponent.JSON_RATING_KEYNAME = "rating";
    ISurveyComponent.JSON_MULTIPLECHOICE_KEYNAME = "multipleChoice";
    ISurveyComponent.JSON_APPLICATION_KEYNAME = "application";
    ISurveyComponent.JSON_EXTENDEDMANIFESTDATA_KEYNAME = "extendedManifestData";
})(ISurveyComponent || (ISurveyComponent = {}));
module.exports = ISurveyComponent;
