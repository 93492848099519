"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var ISurvey = require("../Api/ISurvey");
var ISurveyComponent = require("../Api/ISurveyComponent");
var CommentComponent = require("../SurveyComponents/CommentComponent");
var MultipleChoiceComponent = require("../SurveyComponents/MultipleChoiceComponent");
var PromptComponent = require("../SurveyComponents/PromptComponent");
var RatingComponent = require("../SurveyComponents/RatingComponent");
var Utils = require("../Utils");
var Survey_1 = require("./Survey");
var SurveyDataSource = require("./SurveyDataSource");
var FpsSurvey = /** @class */ (function (_super) {
    __extends(FpsSurvey, _super);
    function FpsSurvey(data) {
        var _this = _super.call(this) || this;
        if (Utils.isNullOrUndefined(data)) {
            throw new Error("data must not be null");
        }
        _this.surveyInfo = new SurveyDataSource(data.baseData);
        _this.prompt = new PromptComponent(data.promptData);
        _this.question = data.commentData ? (new CommentComponent(data.commentData)) : null;
        _this.rating = data.ratingData ? (new RatingComponent(data.ratingData)) : null;
        _this.multipleChoice = data.multipleChoiceData ? (new MultipleChoiceComponent(data.multipleChoiceData)) : null;
        return _this;
    }
    FpsSurvey.make = function (data) {
        try {
            return new FpsSurvey(data);
        }
        catch (e) {
            return null;
        }
    };
    FpsSurvey.makeFps = function (baseData, sp, surveyModel) {
        if (Utils.isNullOrUndefined(baseData) || Utils.isNullOrUndefined(sp) || Utils.isNullOrUndefined(surveyModel)) {
            return null;
        }
        var ratingValuesAscending = [];
        var availableOptions = [];
        var content = surveyModel.content;
        // Prompt is required and at least one of the other components are required
        if (Utils.isNullOrUndefined(content) || Utils.isNullOrUndefined(content.prompt) ||
            (Utils.isNullOrUndefined(content.rating) && Utils.isNullOrUndefined(content.multipleChoice) &&
                Utils.isNullOrUndefined(content.comment))) {
            return null;
        }
        var data = new FpsSurvey.FpsSurveyData();
        data.baseData = baseData;
        data.promptData = new PromptComponent.PromptComponentData();
        data.promptData.title = sp.getCustomString(content.prompt.title);
        data.promptData.question = sp.getCustomString(content.prompt.question);
        data.promptData.yesButtonLabel = sp.getCustomString(content.prompt.yesLabel);
        data.promptData.noButtonLabel = sp.getCustomString(content.prompt.noLabel);
        if (Utils.isNullOrUndefined(data.promptData.title)
            || Utils.isNullOrUndefined(data.promptData.question)
            || Utils.isNullOrUndefined(data.promptData.yesButtonLabel)
            || Utils.isNullOrUndefined(data.promptData.noButtonLabel)) {
            return null;
        }
        if (content.rating) {
            data.ratingData = new RatingComponent.RatingComponentData();
            data.ratingData.question = sp.getCustomString(content.rating.question);
            data.ratingData.isZeroBased = content.rating.isZeroBased;
            for (var _i = 0, _a = content.rating.ratingValuesAscending; _i < _a.length; _i++) {
                var value = _a[_i];
                var customString = sp.getCustomString(value);
                if (Utils.isNullOrUndefined(customString)) {
                    return null;
                }
                ratingValuesAscending.push(customString);
            }
            data.ratingData.ratingValuesAscending = ratingValuesAscending;
            if (Utils.isNullOrUndefined(data.ratingData.question)
                || Utils.isNullOrUndefined(data.ratingData.ratingValuesAscending)) {
                return null;
            }
        }
        if (content.multipleChoice) {
            data.multipleChoiceData = new MultipleChoiceComponent.MultipleChoiceComponentData();
            data.multipleChoiceData.question = sp.getCustomString(content.multipleChoice.question);
            data.multipleChoiceData.minNumberOfSelectedOptions = content.multipleChoice.minNumberOfSelectedOptions;
            data.multipleChoiceData.maxNumberOfSelectedOptions = content.multipleChoice.maxNumberOfSelectedOptions;
            for (var _b = 0, _c = content.multipleChoice.availableOptions; _b < _c.length; _b++) {
                var value = _c[_b];
                var customString = sp.getCustomString(value);
                if (Utils.isNullOrUndefined(customString)) {
                    return null;
                }
                availableOptions.push(customString);
            }
            data.multipleChoiceData.availableOptions = availableOptions;
            if (Utils.isNullOrUndefined(data.multipleChoiceData.question)
                || Utils.isNullOrUndefined(data.multipleChoiceData.availableOptions)) {
                return null;
            }
        }
        if (content.comment) {
            data.commentData = new CommentComponent.CommentComponentData();
            data.commentData.question = sp.getCustomString(content.comment.question);
            if (Utils.isNullOrUndefined(data.commentData.question)) {
                return null;
            }
        }
        return this.make(data);
    };
    // @Override
    FpsSurvey.prototype.getType = function () {
        return ISurvey.Type.Fps;
    };
    // @Override
    FpsSurvey.prototype.getSurveyInfo = function () {
        return this.surveyInfo;
    };
    // @Override
    FpsSurvey.prototype.getCommentComponent = function () {
        return this.question;
    };
    // @Override
    FpsSurvey.prototype.getPromptComponent = function () {
        return this.prompt;
    };
    // @Override
    FpsSurvey.prototype.getRatingComponent = function () {
        return this.rating;
    };
    // @Override
    FpsSurvey.prototype.getMultipleChoiceComponent = function () {
        return this.multipleChoice;
    };
    // @Override
    FpsSurvey.prototype.getComponent = function (componentType) {
        switch (componentType) {
            case ISurveyComponent.Type.Comment:
                return this.getCommentComponent();
            case ISurveyComponent.Type.Prompt:
                return this.getPromptComponent();
            case ISurveyComponent.Type.Rating:
                return this.getRatingComponent();
            case ISurveyComponent.Type.MultipleChoice:
                return this.getMultipleChoiceComponent();
            default:
                return null;
        }
    };
    // @Override
    FpsSurvey.prototype.getDomElements = function (doc) {
        if (Utils.isNullOrUndefined(doc)) {
            throw new Error("Document must not be null");
        }
        var element = doc.createElement(ISurvey.DOM_FPS_TAGNAME);
        this.getSurveyInfo().getDomElements(doc).forEach(function (child) {
            if (!Utils.isNullOrUndefined(child)) {
                element.appendChild(child);
            }
        });
        this.getCommentComponent().getDomElements(doc).forEach(function (child) {
            if (!Utils.isNullOrUndefined(child)) {
                element.appendChild(child);
            }
        });
        if (this.getRatingComponent()) {
            this.getRatingComponent().getDomElements(doc).forEach(function (child) {
                if (!Utils.isNullOrUndefined(child)) {
                    element.appendChild(child);
                }
            });
        }
        if (this.getMultipleChoiceComponent()) {
            this.getMultipleChoiceComponent().getDomElements(doc).forEach(function (child) {
                if (!Utils.isNullOrUndefined(child)) {
                    element.appendChild(child);
                }
            });
        }
        return [element];
    };
    // @Override
    FpsSurvey.prototype.getJsonElements = function () {
        var result = {};
        result = Utils.overrideValues(this.getSurveyInfo().getJsonElements(), result);
        result[ISurveyComponent.JSON_APPLICATION_KEYNAME] = {};
        result[ISurveyComponent.JSON_APPLICATION_KEYNAME][ISurveyComponent.JSON_EXTENDEDMANIFESTDATA_KEYNAME] =
            JSON.stringify({ surveySpecificData: this.makeResponseString() });
        return result;
    };
    // Makes the string to send to Manifest respecting the components present in the survey
    FpsSurvey.prototype.makeResponseString = function () {
        var response = {};
        if (this.question) {
            response[ISurveyComponent.JSON_COMMENT_KEYNAME] = [this.getCommentComponent().getSubmittedText()];
        }
        if (this.multipleChoice) {
            response[ISurveyComponent.JSON_MULTIPLECHOICE_KEYNAME] =
                [this.getMultipleChoiceComponent().getOptionSelectedStates()];
        }
        if (this.rating) {
            response[ISurveyComponent.JSON_RATING_KEYNAME] = [
                { numberOfOptions: this.getRatingComponent().getRatingValuesAscending().length,
                    selectedRating: this.getRatingComponent().getSelectedRatingIndex() }
            ];
        }
        return JSON.stringify(response);
    };
    return FpsSurvey;
}(Survey_1.Survey));
(function (FpsSurvey) {
    /**
     * Data required for a Fps Survey
     */
    var FpsSurveyData = /** @class */ (function () {
        function FpsSurveyData() {
        }
        return FpsSurveyData;
    }());
    FpsSurvey.FpsSurveyData = FpsSurveyData;
})(FpsSurvey || (FpsSurvey = {}));
module.exports = FpsSurvey;
