"use strict";
var IFloodgateSettingIdMap;
(function (IFloodgateSettingIdMap) {
    /**
     * Collection of setting names and ids
     */
    IFloodgateSettingIdMap.RoamingSettingIdMap = {
        CampaignStates: 1258,
        GovernedChannelStates: 1257,
        SurveyActivationStats: 1259,
    };
})(IFloodgateSettingIdMap || (IFloodgateSettingIdMap = {}));
module.exports = IFloodgateSettingIdMap;
