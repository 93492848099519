"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var FloodgateEngine_1 = require("../FloodgateEngine");
var Utils = require("../Utils");
var CampaignDefinitionProvider_1 = require("./CampaignDefinitionProvider");
var CampaignStateProvider_1 = require("./CampaignStateProvider");
var CampaignSurveyFactory_1 = require("./CampaignSurveyFactory");
var es6_promise_1 = require("es6-promise");
/**
 * The standard campaign manager implementation.
 */
var CampaignManager = /** @class */ (function () {
    function CampaignManager(stateProvider, definitionProviders, stringProvider, environmentProvider, currentBuildNumber, currentDate) {
        if (!stateProvider) {
            throw new Error("stateProvider must not be null");
        }
        if (!Utils.isArray(definitionProviders)) {
            throw new Error("definitionProviders is either null or not an array type");
        }
        if (!stringProvider) {
            throw new Error("stringProvider must not be null");
        }
        if (Utils.isNullOrUndefined(currentBuildNumber)) {
            throw new Error("currentBuildNumber must not be null");
        }
        this.campaignStates = {};
        this.campaignDefinitions = {};
        this.stateProvider = stateProvider;
        this.definitionProviders = definitionProviders;
        this.stringProvider = stringProvider;
        this.environmentProvider = environmentProvider; // Can be null
        this.currentBuildNumber = currentBuildNumber;
        currentDate = currentDate ? currentDate : new Date();
    }
    CampaignManager.isStateUpForNomination = function (state, definition, date, buildNumber) {
        if (!state || !definition) {
            return false;
        }
        date = date ? date : new Date();
        // Check manual override
        if (state.ForceCandidacy) {
            return true;
        }
        // Figure out which duration we're using based on whether or not the user triggered the survey
        var campaignCooldownDuration = definition.nominationScheme.getCampaignCooldown(state.IsCandidate && state.DidCandidateTriggerSurvey);
        if (!campaignCooldownDuration) {
            return false;
        }
        // First check for build-based rules. Eventually this could get more complex if we supported counting differently seen build numbers
        if (CampaignManager.hasBuildChangeDurationElapsed(campaignCooldownDuration, state.LastNominationBuildNumber, buildNumber)) {
            return true;
        }
        // Next check date rules
        return CampaignManager.hasTimeIntervalDurationElapsed(campaignCooldownDuration, state.getCooldownStartDate(), date);
    };
    CampaignManager.isDateInRange = function (date, startDate, endDate) {
        date = date ? date : new Date();
        startDate = startDate ? startDate : Utils.getDistantPast();
        endDate = endDate ? endDate : Utils.getDistantFuture();
        // Check Start/Expire date range
        if (startDate.getTime() <= date.getTime() && endDate.getTime() >= date.getTime()) {
            return true;
        }
        return false;
    };
    CampaignManager.hasTimeIntervalDurationElapsed = function (duration, startDate, date) {
        if (Utils.isNullOrUndefined(duration) || !(duration instanceof CampaignDefinitionProvider_1.CampaignDurationTimeInterval)) {
            return false;
        }
        if (!startDate || !date) {
            return false;
        }
        var endDate = Utils.addSecondsWithoutOverflow(startDate, duration.intervalSeconds);
        return date.getTime() >= endDate.getTime();
    };
    CampaignManager.hasBuildChangeDurationElapsed = function (duration, startBuild, build) {
        if (Utils.isNullOrUndefined(duration) || !(duration instanceof CampaignDefinitionProvider_1.CampaignDurationSingleBuildChange)) {
            return false;
        }
        if (Utils.isNullOrUndefined(startBuild) || Utils.isNullOrUndefined(build)) {
            return (startBuild === null) !== (build === null);
        }
        return !(startBuild === build);
    };
    CampaignManager.isCampaignInScope = function (definition, date, environmentProvider) {
        if (!definition) {
            return false;
        }
        date = date ? date : new Date();
        // Check Start/Expire date range
        if (!CampaignManager.isDateInRangeWithDefinition(date, definition)) {
            return false;
        }
        if (definition.scope) {
            if (!definition.scope.isInScope(environmentProvider)) {
                return false;
            }
        }
        // If we get here, the definition is relevant
        return true;
    };
    CampaignManager.isDateInRangeWithDefinition = function (date, definition) {
        if (!definition) {
            return false;
        }
        date = date ? date : new Date();
        return CampaignManager.isDateInRange(date, 
        // null start dates should be impossible, but if set, should mean the campaign is disabled
        definition.startTime ? definition.startTime : Utils.getDistantFuture(), definition.endTime);
    };
    // @Override
    CampaignManager.prototype.getActiveSurveys = function () {
        var surveys = {};
        for (var key in this.campaignStates) {
            if (this.campaignStates.hasOwnProperty(key)) {
                var state = this.campaignStates[key];
                if (!state.IsCandidate) {
                    continue;
                }
                // This is a requirement because we keep some otherwise "stale" campaigns around for the save routine
                var definition = this.campaignDefinitions[state.CampaignId];
                if (!definition) {
                    continue;
                }
                var survey = CampaignSurveyFactory_1.CampaignSurveyFactory.makeSurvey(state, definition.governedChannelType, definition.surveyTemplate, this.stringProvider, definition.additionalDataRequested, definition.launcherType);
                if (!survey) {
                    continue;
                }
                surveys[survey.getSurveyInfo().getId()] = survey;
            }
        }
        return surveys;
    };
    // @Override
    CampaignManager.prototype.onCampaignSurveyActivated = function (campaignId, takenDate) {
        var state = this.campaignStates[campaignId];
        if (!state) {
            return;
        }
        takenDate = takenDate ? takenDate : new Date();
        state.markCurrentSurveyTakenOnDate(takenDate, this.campaignDefinitions[campaignId].nominationScheme.cooldownPeriod.asTimeIntervalSeconds());
        this.saveCurrentState();
    };
    CampaignManager.prototype.getCampaignStates = function () {
        return this.campaignStates;
    };
    CampaignManager.prototype.getCampaignDefinitions = function () {
        return this.campaignDefinitions;
    };
    // region ISurveyClient methods
    // @Override
    CampaignManager.prototype.onSurveyActivated = function (surveyInfo) {
        if (!surveyInfo) {
            return;
        }
        this.onCampaignSurveyActivated(surveyInfo.getBackEndId(), new Date());
    };
    // @Override
    CampaignManager.prototype.refreshSurveyDefinitions = function (channelTypes, date) {
        this.refreshSurveyDefinitionsPrivate(channelTypes, (date ? date : new Date()));
    };
    // @Override
    CampaignManager.prototype.refreshSurveyDefinitionsAsync = function (channelTypes, date) {
        var _this = this;
        return new es6_promise_1.Promise(function (resolve, reject) {
            _this.refreshSurveyDefinitionsPrivateAsync(channelTypes, (date ? date : new Date())).then(function onFulfilled() {
                resolve();
            }).catch(function OnRejected(errReason) {
                reject(errReason);
            });
        });
    };
    // @Override
    CampaignManager.prototype.getAppSurveys = function () {
        return this.getActiveSurveys();
    };
    // @Override
    CampaignManager.prototype.saveCurrentState = function () {
        // Sort by campaignId, ascending for uniformity
        var states = Utils.makeArrayFromObjectValuesSortedByKeyString(this.campaignStates);
        this.stateProvider.save(states);
    };
    // endregion
    CampaignManager.prototype.refreshSurveyDefinitionsPrivate = function (channelTypes, date) {
        this.campaignStates = {};
        this.campaignDefinitions = {};
        this.loadAndFilterCampaignData(date, channelTypes);
        this.evaluateCampaigns(date);
    };
    CampaignManager.prototype.refreshSurveyDefinitionsPrivateAsync = function (channelTypes, date) {
        this.campaignStates = {};
        this.campaignDefinitions = {};
        var thisCampaignManager = this;
        return new es6_promise_1.Promise(function (resolve, reject) {
            thisCampaignManager.loadAndFilterCampaignDataAsync(date, channelTypes).then(function onFulfilled() {
                thisCampaignManager.evaluateCampaigns(date);
                resolve();
            }).catch(function OnRejected(errReason) {
                reject(errReason);
            });
        });
    };
    /**
     * Load and filter the campaigns definitions and states
     */
    CampaignManager.prototype.loadAndFilterCampaignDataAsync = function (currentDate, channelTypes) {
        currentDate = currentDate ? currentDate : new Date();
        var loadedDefinitions = [];
        var promises = [];
        for (var _i = 0, _a = Object.keys(this.definitionProviders); _i < _a.length; _i++) {
            var i = _a[_i];
            var provider = this.definitionProviders[i];
            var promise = provider.loadAsync();
            if (!Utils.isNullOrUndefined(promise)) {
                // Promise.all fails even if one promise fails.
                // We want to ignore the promise that failed and continue with the ones
                // those are succesful, hence overriding the catch to always resolve.
                // responsibility of logging failures should be with the provider.
                promise = promise.catch(function () {
                    FloodgateEngine_1.FloodgateEngine.getTelemetryLogger().log_CampaignLoad_Failed("Failed to load from campaign definition provider");
                    es6_promise_1.Promise.resolve();
                });
                promises.push(promise);
            }
        }
        var thisCampaignManager = this;
        return new es6_promise_1.Promise(function (resolve, reject) {
            es6_promise_1.Promise.all(promises).then(function onFulfilled(values) {
                for (var _i = 0, _a = Object.keys(values); _i < _a.length; _i++) {
                    var i = _a[_i];
                    var campaignDefinitions = values[i];
                    if (Utils.isArray(campaignDefinitions)) {
                        // If there are campaign defintions with duplicate campaign Id's
                        // Floodgate will load the last one it found. FilterCampaignData does this filteration
                        loadedDefinitions = loadedDefinitions.concat(campaignDefinitions);
                    }
                }
                thisCampaignManager.FilterCampaignData(currentDate, channelTypes, loadedDefinitions);
                resolve();
            }).catch(function OnRejected(error) {
                // this should never happen as Promise.all will always be resolved
                // because of hack at the top of the function.
                FloodgateEngine_1.FloodgateEngine.getTelemetryLogger().log_CampaignLoad_Failed("Failed to load from campaign definition provider");
            });
        });
    };
    CampaignManager.prototype.loadAndFilterCampaignData = function (currentDate, channelTypes) {
        currentDate = currentDate ? currentDate : new Date();
        var loadedDefinitions = [];
        for (var _i = 0, _a = Object.keys(this.definitionProviders); _i < _a.length; _i++) {
            var index = _a[_i];
            try {
                var definitions = this.definitionProviders[index].load();
                if (Utils.isArray(definitions)) {
                    // If there are campaign defintions with duplicate campaign Id's
                    // Floodgate will load the last one it found. FilterCampaignData does this filteration
                    loadedDefinitions = loadedDefinitions.concat(definitions);
                }
            }
            catch (error) {
                // Log error, but continue with other providers
                // It should be the responsibility of provider to log detailed errors
                FloodgateEngine_1.FloodgateEngine.getTelemetryLogger().log_CampaignLoad_Failed("Failed to load from campaign definition provider. " + error.toString());
            }
        }
        this.FilterCampaignData(currentDate, channelTypes, loadedDefinitions);
    };
    /**
     * Load and filter the campaigns definitions and states
     */
    CampaignManager.prototype.FilterCampaignData = function (currentDate, channelTypes, loadedDefinitions) {
        var loadedDefinitionsMap = {};
        // Load filtered campaign definitions
        for (var key in loadedDefinitions) {
            if (loadedDefinitions.hasOwnProperty(key)) {
                var definition = loadedDefinitions[key];
                loadedDefinitionsMap[definition.campaignId] = definition;
                if (channelTypes && (channelTypes.indexOf(definition.governedChannelType) < 0)) {
                    continue;
                }
                if (!CampaignManager.isCampaignInScope(definition, currentDate, this.environmentProvider)) {
                    continue;
                }
                this.campaignDefinitions[definition.campaignId] = definition;
            }
        }
        // Load campaign state, filtering out the expired definitions
        var loadedStates = this.stateProvider.load();
        var staleLoadedStates = [];
        for (var key in loadedStates) {
            if (loadedStates.hasOwnProperty(key)) {
                var state = loadedStates[key];
                if (!this.campaignDefinitions.hasOwnProperty(state.CampaignId)) {
                    staleLoadedStates.push(state);
                }
                this.campaignStates[state.CampaignId] = state;
            }
        }
        /*
         * Stale State data cleanup
         * State needs to be cleaned up when:
         * 1) The campaign has expired
         * 2) The campaign is no longer present in the definitions (think ECS or other outages or very old expirations)
         *    In this case the state should still be cleaned up on it's reelection date
         * 3) When the user is out of scope (for an otherwise active campaign) and is up for nomination

         * This optimizes the user experience in case the campaign pops up or gets renewed when we would have preferred the user remain in cool down

         * 2&3 are basically the same, and 1 is a special case optimization on top of 2&3 for earlier clean up

         * To do 2 & 3, we just look at states with no matching definition (after filtering), and reject them if they are past their nomination period
         * To do 1, we just need to keep a list/hash of the definitions that we filtered out this load cycle, specifically for expiration dates, and bypass
         * the cool down "wait" for these specifically

         * For now, allowing some "grace" for "missing"/"blippy" campaign definitions (in case they pop in and out of ECS) by deferring missing
         * deletion by a delay-time specified by the original campaign.
         */
        for (var key in staleLoadedStates) {
            if (staleLoadedStates.hasOwnProperty(key)) {
                var state = staleLoadedStates[key];
                if (!state) {
                    continue;
                }
                var definition = loadedDefinitionsMap[state.CampaignId];
                var shouldRemove = false;
                if (!definition) {
                    if (state.LastNominationTimeUtc.getTime() <= Utils.subtractSecondsWithoutOverflow(currentDate, state.DeleteAfterSecondsWhenStale).getTime()) {
                        shouldRemove = true;
                    }
                }
                else if (CampaignManager.isStateUpForNomination(state, definition, currentDate, this.currentBuildNumber)) {
                    shouldRemove = true;
                }
                // The next time we write to storage, these will be removed
                if (shouldRemove) {
                    delete this.campaignStates[state.CampaignId];
                }
            }
        }
    };
    /**
     * Given the loaded campaign definitions and states from previous sessions, run anything up for nomination
     */
    CampaignManager.prototype.evaluateCampaigns = function (currentDate) {
        // Loop through campaigns definitions, and update their state if necessary
        currentDate = currentDate ? currentDate : new Date();
        for (var key in this.campaignDefinitions) {
            if (this.campaignDefinitions.hasOwnProperty(key)) {
                var definition = this.campaignDefinitions[key];
                var state = this.campaignStates[definition.campaignId];
                if (!state || CampaignManager.isStateUpForNomination(state, definition, currentDate, this.currentBuildNumber)) {
                    var lastSurveyId = state ? state.LastSurveyId : "";
                    var lastSurveyStartTime = state ? state.LastSurveyStartTimeUtc : Utils.getDistantPast();
                    var lastSurveyExpirationTime = state ? state.LastSurveyExpirationTimeUtc : Utils.getDistantPast();
                    var lastSurveyActivatedTime = state ? state.LastSurveyActivatedTimeUtc : Utils.getDistantPast();
                    var lastCooldownEndTimeUtc = state ? state.LastCooldownEndTimeUtc : Utils.getDistantPast();
                    // Run the nomination
                    var isCandidate = (state && state.ForceCandidacy) || definition.nominationScheme.evaluateNominationRules();
                    if (isCandidate) {
                        // Make new survey properties for the next call to get the active surveys
                        lastSurveyId = Utils.guid();
                        lastSurveyStartTime = definition.nominationScheme.calculateSurveyStartTimeFromDate(currentDate);
                        lastSurveyExpirationTime = definition.nominationScheme.calculateSurveyExpirationTimeFromSurveyStartTime(lastSurveyStartTime);
                    }
                    var newState = new CampaignStateProvider_1.CampaignState(definition.campaignId, currentDate, this.currentBuildNumber, definition.nominationScheme.getActiveSurveyTimeIntervalSeconds(), false, isCandidate, false, lastSurveyActivatedTime, lastSurveyId, lastSurveyStartTime, lastSurveyExpirationTime, lastCooldownEndTimeUtc);
                    // Save the new state to our local cache
                    this.campaignStates[newState.CampaignId] = newState;
                }
            }
        }
    };
    return CampaignManager;
}());
exports.CampaignManager = CampaignManager;
