"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var IFloodgateStorageProvider = require("./Api/IFloodgateStorageProvider");
var CampaignDefinitionProvider_1 = require("./Campaign/CampaignDefinitionProvider");
var CampaignManager_1 = require("./Campaign/CampaignManager");
var CampaignStateProvider_1 = require("./Campaign/CampaignStateProvider");
var Constants_1 = require("./Constants");
var FloodgateSettings_1 = require("./FloodgateSettings");
var GovernedChannel_1 = require("./GovernedChannel");
var GovernedChannelStateProvider_1 = require("./GovernedChannelStateProvider");
var Governor_1 = require("./Governor");
var SurveyActivityListener = require("./SurveyActivityListener");
var SurveyStatCollectionActivation_1 = require("./SurveyStatCollectionActivation");
var SurveyStatCollectionEventActivity_1 = require("./SurveyStatCollectionEventActivity");
var es6_promise_1 = require("es6-promise");
// Telemetry logger with no-op logger
var DefaultFloodgateTelemetryLogger = /** @class */ (function () {
    function DefaultFloodgateTelemetryLogger() {
    }
    // @Override
    DefaultFloodgateTelemetryLogger.prototype.log_TriggerMet = function (campaignId, surveyId, surveyType) { };
    // @Override
    DefaultFloodgateTelemetryLogger.prototype.log_UserSelected = function (campaignId, surveyId, surveyType) { };
    // @Override
    DefaultFloodgateTelemetryLogger.prototype.log_CampaignLoad_Failed = function (errorMessage) { };
    // @Override
    DefaultFloodgateTelemetryLogger.prototype.log_Event = function (eventId, properties) { };
    return DefaultFloodgateTelemetryLogger;
}());
var defaultSurveyActivityListener = {
    run: function (survey) {
    },
    shouldAcceptActivity: function (activityName) {
        return true;
    },
};
/**
 * Floodgate Engine class.  The main wiring hub and the brains responsible for
 * linking together the survey definitions, governance, activity tracking, and
 * survey launchers.  Only one-instance-at-a-time of these should be created
 * per app session.
 */
var FloodgateEngine = /** @class */ (function () {
    function FloodgateEngine(surveyClient, activityListener, launcherFactory, onSurveyActivatedCallback, storage, governor, surveyActivationStatsProvider, transporterFactory) {
        this.initializationStatus = 0 /* None */;
        this.candidateSurveys = {};
        this.launchedSurveys = {};
        this.launchedLaunchers = [];
        if (!surveyClient) {
            throw new Error("surveyClient must not be null");
        }
        if (!activityListener) {
            throw new Error("activityListener must not be null");
        }
        if (!launcherFactory) {
            throw new Error("launcherFactory must not be null");
        }
        if (!onSurveyActivatedCallback) {
            throw new Error("onSurveyActivatedCallback must not be null");
        }
        if (!storage) {
            throw new Error("storage must not be null");
        }
        if (!governor) {
            throw new Error("governor must not be null");
        }
        if (!transporterFactory) {
            throw new Error("transporterFactory must not be null");
        }
        this.surveyClient = surveyClient;
        this.activityListener = activityListener;
        this.launcherFactory = launcherFactory;
        this.onSurveyActivatedCallback = onSurveyActivatedCallback;
        this.storage = storage;
        this.governor = governor;
        this.surveyActivationStatsProvider = surveyActivationStatsProvider;
        FloodgateEngine.transporterFactory = transporterFactory;
        this.loggedFirstStart = false;
        // Initialize our list to a valid, empty collection
        this.setPendingSurveys(null);
        // If the listener was pre-configured with survey for some reason, clear it now
        this.activityListener.clearSurveys();
        // Link ourselves with the listener
        var thisObj = this; // Save current object in a variable to allow access from callback.
        this.activityListener.setCallback({
            run: function (survey) {
                thisObj.onSurveyActivated(survey);
            },
            shouldAcceptActivity: function (activityName) {
                return thisObj.shouldAcceptActivity(activityName);
            },
        });
    }
    FloodgateEngine.setTelemetryLogger = function (telemetryLogger) {
        if (!telemetryLogger) {
            throw new Error("telemetryLogger must not be null");
        }
        FloodgateEngine.telemetryLogger = telemetryLogger;
    };
    FloodgateEngine.getTelemetryLogger = function () {
        return FloodgateEngine.telemetryLogger;
    };
    FloodgateEngine.getTransportFactory = function () {
        return FloodgateEngine.transporterFactory;
    };
    FloodgateEngine.make = function (buildVersion, launcherFactory, onSurveyActivatedCallback, fileBasedStorageProvider, hostBasedStorageProvider, stringProvider, environmentProvider, transporterFactory, campaignDefinitionProviders) {
        try {
            var governedChannelStateProvider = GovernedChannelStateProvider_1.GovernedChannelStateProviderFactory.make(fileBasedStorageProvider, hostBasedStorageProvider);
            var surveyActivationStatsProvider = SurveyStatCollectionActivation_1.SurveyActivationStatsProviderFactory.make(fileBasedStorageProvider, hostBasedStorageProvider);
            var campaignStateProvider = CampaignStateProvider_1.CampaignStateProviderFactory.make(fileBasedStorageProvider, hostBasedStorageProvider);
            campaignDefinitionProviders.push(new CampaignDefinitionProvider_1.FileSystemCampaignDefinitionProvider(fileBasedStorageProvider));
            return new FloodgateEngine(new CampaignManager_1.CampaignManager(campaignStateProvider, campaignDefinitionProviders, stringProvider, environmentProvider, buildVersion, new Date()), new SurveyActivityListener(defaultSurveyActivityListener, this.getTelemetryLogger), launcherFactory, onSurveyActivatedCallback, fileBasedStorageProvider, new Governor_1.Governor(governedChannelStateProvider), surveyActivationStatsProvider, transporterFactory);
        }
        catch (e) {
            this.telemetryLogger.log_Event(Constants_1.TelemetryEvent.FloodgateEngine.Make.Failed, { ErrorMessage: e.toString() });
        }
    };
    /**
     * Save all internal stats and floodgateSettings (merging with existing file contents), without stopping the engine.
     */
    FloodgateEngine.prototype.mergeAndSave = function () {
        this.saveSettings();
        this.saveSurveyActivationHistory();
        this.saveSurveyEventActivityHistory();
        // Inform surveyClient to save its state
        this.surveyClient.saveCurrentState();
        // Save governed channel states
        this.governor.saveChannelStates();
    };
    /**
     * Start the engine.  Checks feature enable state, causes survey definitions to be read, and tracked activities to be set on the listener.
     */
    FloodgateEngine.prototype.start = function () {
        if (this.initializationStatus === 2 /* Initializing */ ||
            this.initializationStatus === 3 /* Started */) {
            return;
        }
        try {
            this.setInitializationStatus(2 /* Initializing */);
            this.loadSettingsAndPriorEventCounts();
            var channelTypes = this.getAvailableChannelTypes();
            this.surveyClient.refreshSurveyDefinitions(channelTypes);
            this.setPendingSurveysAndStartFloodgate();
            this.setInitializationStatus(3 /* Started */);
        }
        catch (e) {
            this.setInitializationStatus(1 /* Error */);
            FloodgateEngine.getTelemetryLogger().log_Event(Constants_1.TelemetryEvent.FloodgateEngine.Start.Failed, { ErrorMessage: e && e.toString() });
        }
    };
    /**
     * Start the engine.  Checks feature enable state, causes survey definitions to be read, and tracked activities to be set on the listener.
     */
    FloodgateEngine.prototype.startAsync = function () {
        if (this.initializationStatus === 2 /* Initializing */ ||
            this.initializationStatus === 3 /* Started */) {
            return es6_promise_1.Promise.resolve();
        }
        try {
            this.setInitializationStatus(2 /* Initializing */);
            this.loadSettingsAndPriorEventCounts();
            var channelTypes_1 = this.getAvailableChannelTypes();
            var thisFloodgateEngine_1 = this;
            return new es6_promise_1.Promise(function (resolve, reject) {
                thisFloodgateEngine_1.surveyClient.refreshSurveyDefinitionsAsync(channelTypes_1).then(function onFulfilled() {
                    // Handle any stop() calls that happened while initializing
                    if (thisFloodgateEngine_1.initializationStatus === 4 /* Stopped */) {
                        thisFloodgateEngine_1.setInitializationStatus(3 /* Started */);
                        thisFloodgateEngine_1.stop();
                        FloodgateEngine.getTelemetryLogger().log_Event(Constants_1.TelemetryEvent.FloodgateEngine.StartAsync.Stopped, { ErrorMessage: "Stopped because of a pending stop() call" });
                    }
                    else {
                        thisFloodgateEngine_1.setPendingSurveysAndStartFloodgate();
                    }
                    resolve();
                }).catch(function OnRejected(error) {
                    thisFloodgateEngine_1.setInitializationStatus(1 /* Error */);
                    FloodgateEngine.getTelemetryLogger().log_Event(Constants_1.TelemetryEvent.FloodgateEngine.StartAsync.Failed, { ErrorMessage: error && error.message });
                    reject(error);
                });
            });
        }
        catch (e) {
            this.setInitializationStatus(1 /* Error */);
            FloodgateEngine.getTelemetryLogger().log_Event(Constants_1.TelemetryEvent.FloodgateEngine.StartAsync.Failed, { ErrorMessage: e && e.toString() });
            return es6_promise_1.Promise.resolve();
        }
    };
    /**
     * Stop the engine.  Causes tracked activities to be cleared and any in-progress counters not otherwise saved to be thrown out.
     */
    FloodgateEngine.prototype.stop = function () {
        if (this.initializationStatus === 3 /* Started */) {
            this.mergeAndSave();
            // Clear our Survey list, and push that through to the listener
            this.setPendingSurveys(null);
            this.updateActivityListenerWithCurrentSurveyDefinitions();
        }
        this.setInitializationStatus(4 /* Stopped */);
    };
    /**
     * Gets the IActivityListener logging interface for callers that want to log directly rather than through telemetry
     */
    FloodgateEngine.prototype.getActivityListener = function () {
        return this.activityListener;
    };
    FloodgateEngine.prototype.setInitializationStatus = function (status) {
        this.initializationStatus = status;
    };
    FloodgateEngine.prototype.saveSettings = function () {
        this.storage.fileLock(IFloodgateStorageProvider.FileType.FloodgateSettings);
        try {
            this.writeString(IFloodgateStorageProvider.FileType.FloodgateSettings, FloodgateSettings_1.FloodgateSettings.toJson(this.floodgateSettings));
        }
        finally {
            this.storage.fileUnlock(IFloodgateStorageProvider.FileType.FloodgateSettings);
        }
    };
    FloodgateEngine.prototype.loadSettingsAndPriorEventCounts = function () {
        // Load up the general floodgateSettings
        this.floodgateSettings = FloodgateSettings_1.FloodgateSettings.fromJson(this.readString(IFloodgateStorageProvider.FileType.FloodgateSettings));
        // Load up the prior survey history and prior event counts
        this.previousSurveyActivationStats = this.surveyActivationStatsProvider.load();
        this.previousSurveyEventActivityStats =
            SurveyStatCollectionEventActivity_1.SurveyStatCollectionEventActivity.fromJson(this.readString(IFloodgateStorageProvider.FileType.SurveyEventActivityStats));
    };
    FloodgateEngine.prototype.getAvailableChannelTypes = function () {
        // Make sure we've loaded the current survey definitions
        var channels = this.governor.getAvailableChannelData();
        var channelTypes = [];
        channels.forEach(function (channel) {
            if (channel) {
                channelTypes.push(channel.getType());
            }
        });
        return channelTypes;
    };
    FloodgateEngine.prototype.setPendingSurveysAndStartFloodgate = function () {
        // Update our survey list, and push that through to the listener
        this.setPendingSurveys(this.surveyClient.getAppSurveys());
        this.updateActivityListenerWithCurrentSurveyDefinitions();
        // Mark us as started
        this.setInitializationStatus(3 /* Started */);
        // Log our first start (aka floodgate boot) event
        if (!this.loggedFirstStart) {
            this.loggedFirstStart = true;
            this.getActivityListener().logActivity(SurveyActivityListener.FloodgateStartActivityName);
        }
    };
    FloodgateEngine.prototype.saveSurveyActivationHistory = function () {
        this.storage.fileLock(IFloodgateStorageProvider.FileType.SurveyActivationStats);
        try {
            var statCollection = this.surveyActivationStatsProvider.load();
            // Build the update collection
            var updateCollection = new SurveyStatCollectionActivation_1.SurveyStatCollectionActivation();
            for (var key in this.launchedSurveys) {
                if (this.launchedSurveys.hasOwnProperty(key)) {
                    var stats = new SurveyStatCollectionActivation_1.SurveyActivationStats();
                    var survey = this.launchedSurveys[key];
                    stats.Type = survey.getType();
                    stats.ExpirationTimeUtc = survey.getSurveyInfo().getExpirationTimeUtc();
                    stats.ActivationTimeUtc = new Date();
                    // Make this part of the update list
                    updateCollection.addStats(survey.getSurveyInfo().getId(), stats);
                }
            }
            // Actually merge our updates into the full collection
            statCollection.accumulate(updateCollection);
            this.surveyActivationStatsProvider.save(statCollection);
            // Make sure to keep our internal collection consistent with what we just wrote
            this.previousSurveyActivationStats = statCollection;
        }
        finally {
            this.storage.fileUnlock(IFloodgateStorageProvider.FileType.SurveyActivationStats);
        }
    };
    FloodgateEngine.prototype.saveSurveyEventActivityHistory = function () {
        this.storage.fileLock(IFloodgateStorageProvider.FileType.SurveyEventActivityStats);
        try {
            var statCollection = SurveyStatCollectionEventActivity_1.SurveyStatCollectionEventActivity.fromJson(this.readString(IFloodgateStorageProvider.FileType.SurveyEventActivityStats));
            var now = new Date();
            // Fill out our list of updates
            var updateCollection = new SurveyStatCollectionEventActivity_1.SurveyStatCollectionEventActivity();
            var _loop_1 = function (key) {
                if (this_1.candidateSurveys.hasOwnProperty(key)) {
                    var stats = new SurveyStatCollectionEventActivity_1.SurveyEventActivityStats();
                    var survey = this_1.candidateSurveys[key];
                    if (!survey.getSurveyInfo().isActiveForDate(now)) {
                        return "continue";
                    }
                    stats.ExpirationTimeUtc = survey.getSurveyInfo().getExpirationTimeUtc();
                    // Get the activities which are aggregated, if there aren't any continue
                    var allActivities = survey.getSurveyInfo().getActivationEvent().getTrackingSet();
                    var aggregateActivities_1 = [];
                    allActivities.getList().forEach(function (data) {
                        if (data && data.getIsAggregate()) {
                            aggregateActivities_1.push(data.getActivity());
                        }
                    });
                    if (aggregateActivities_1.length === 0) {
                        return "continue";
                    }
                    stats.Counts = new Array(aggregateActivities_1.length);
                    // Save off the counts we've added for this session
                    for (var i = 0; i < aggregateActivities_1.length; i++) {
                        stats.Counts[i] = this_1.activityListener.moveSessionCountIntoBaseCount(aggregateActivities_1[i]);
                    }
                    // Make this part of the update list
                    updateCollection.addStats(survey.getSurveyInfo().getId(), stats);
                }
            };
            var this_1 = this;
            for (var key in this.candidateSurveys) {
                _loop_1(key);
            }
            // Actually merge our updates into the full collection
            statCollection.accumulate(updateCollection);
            this.writeString(IFloodgateStorageProvider.FileType.SurveyEventActivityStats, SurveyStatCollectionEventActivity_1.SurveyStatCollectionEventActivity.toJson(statCollection));
            // Make sure to keep our internal collection consistent with what we just wrote
            this.previousSurveyEventActivityStats = statCollection;
        }
        finally {
            this.storage.fileUnlock(IFloodgateStorageProvider.FileType.SurveyEventActivityStats);
        }
    };
    FloodgateEngine.prototype.setPendingSurveys = function (pendingSurveys) {
        this.candidateSurveys = {};
        if (pendingSurveys) {
            for (var key in pendingSurveys) {
                if (pendingSurveys.hasOwnProperty(key)) {
                    var survey = pendingSurveys[key];
                    if (this.launcherFactory.AcceptsSurvey(survey)) {
                        this.candidateSurveys[key] = survey;
                    }
                }
            }
        }
    };
    FloodgateEngine.prototype.updateActivityListenerWithCurrentSurveyDefinitions = function () {
        // Get a vector of Surveys from our id-based map
        var surveyList = new Array();
        for (var key in this.candidateSurveys) {
            if (this.candidateSurveys.hasOwnProperty(key)) {
                var survey = this.candidateSurveys[key];
                // Skip over any candidate surveys that have been previously completed
                if (this.previousSurveyActivationStats.getBySurveyId(survey.getSurveyInfo().getId())) {
                    continue;
                }
                if (!survey.getSurveyInfo().isActiveForDate(new Date())) {
                    continue;
                }
                FloodgateEngine.telemetryLogger.log_UserSelected(survey.getSurveyInfo().getBackEndId(), survey.getSurveyInfo().getId(), survey.getType());
                surveyList.push(survey);
            }
        }
        this.activityListener.setSurveys(surveyList, this.previousSurveyEventActivityStats);
    };
    FloodgateEngine.prototype.shouldAcceptActivity = function (activityName) {
        return this.initializationStatus === 3 /* Started */ ||
            activityName === SurveyActivityListener.FloodgateStartActivityName;
    };
    FloodgateEngine.prototype.onSurveyActivated = function (survey) {
        FloodgateEngine.telemetryLogger.log_TriggerMet(survey.getSurveyInfo().getBackEndId(), survey.getSurveyInfo().getId(), survey.getType());
        var launchSurvey = false;
        var governedChannelType = survey.getSurveyInfo().getGovernedChannelType();
        // Figure out if the activated survey is still relevant
        if (!this.candidateSurveys[survey.getSurveyInfo().getId()]) {
            // Survey is no longer relevant but was activated. Suppress it.
        }
        else if (!survey.getSurveyInfo().isActiveForDate(new Date())) {
            // Survey is no longer active (e.g. it was when we registered it but it has now expired)
        }
        else {
            // Refresh channels
            this.governor.refreshChannelData();
            if (!this.governor.isChannelOpen(governedChannelType)) {
                // Channel has closed, suppress the survey
                FloodgateEngine.getTelemetryLogger().log_Event(Constants_1.TelemetryEvent.FloodgateEngine.OnSurveyActivated.ClosedChannelType, { ClosedChannelType: GovernedChannel_1.GovernedChannelType[governedChannelType] });
            }
            else {
                // Get latest survey states from provider
                var refreshedSurveyActivationStats = this.surveyActivationStatsProvider.load();
                if (refreshedSurveyActivationStats.getBySurveyId(survey.getSurveyInfo().getId())) {
                    // Survey stats exists already, don't launch
                    FloodgateEngine.getTelemetryLogger().log_Event(Constants_1.TelemetryEvent.FloodgateEngine.OnSurveyActivated.ActivationStatsSuppressedSurvey, {
                        CampaignId: survey.getSurveyInfo().getBackEndId(),
                        SurveyId: survey.getSurveyInfo().getId(),
                    });
                }
                else if (Object.keys(this.launchedSurveys).length === 0) {
                    // for now we only support launching one survey per session
                    // Track this survey activation for the launch history tracker
                    this.launchedSurveys[survey.getSurveyInfo().getId()] = survey;
                    launchSurvey = true;
                }
            }
        }
        // At this point, regardless of whether or not the survey is still relevant, we should flush out the stats, and shut off further survey launches
        this.mergeAndSave();
        this.activityListener.clearSurveys();
        // Actually launch the survey
        if (launchSurvey) {
            this.governor.startChannelCooldown(governedChannelType);
            this.surveyClient.onSurveyActivated(survey.getSurveyInfo());
            this.launchLauncher(survey);
        }
    };
    FloodgateEngine.prototype.readString = function (fileType) {
        return this.storage.read(fileType);
    };
    FloodgateEngine.prototype.writeString = function (fileType, str) {
        this.storage.write(fileType, str);
    };
    FloodgateEngine.prototype.launchLauncher = function (survey) {
        var launcher = this.launcherFactory.makeSurveyLauncher(survey);
        if (launcher) {
            this.launchedLaunchers.push(launcher);
            this.onSurveyActivatedCallback.onSurveyActivated(launcher, survey);
        }
    };
    // Initialize telemetry logger with no-op logger
    FloodgateEngine.telemetryLogger = new DefaultFloodgateTelemetryLogger();
    return FloodgateEngine;
}());
exports.FloodgateEngine = FloodgateEngine;
