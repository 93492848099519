"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var SurveyStatCollectionActivation_1 = require("../src/SurveyStatCollectionActivation");
/**
 * Values for the state list types
 */
var StateListType;
(function (StateListType) {
    StateListType[StateListType["Merged"] = 0] = "Merged";
    StateListType[StateListType["ToBeUpdated"] = 1] = "ToBeUpdated";
})(StateListType = exports.StateListType || (exports.StateListType = {}));
var ItemCollection = /** @class */ (function () {
    function ItemCollection() {
        this.items = {};
        this.itemCount = 0;
    }
    ItemCollection.prototype.add = function (key, value) {
        if (!this.items[key]) {
            this.itemCount++;
        }
        this.items[key] = value;
    };
    ItemCollection.prototype.find = function (key) {
        return this.items[key];
    };
    ItemCollection.prototype.remove = function (key) {
        var item = this.items[key];
        if (item) {
            delete this.items[key];
            this.itemCount--;
        }
        return item;
    };
    ItemCollection.prototype.count = function () {
        return this.itemCount;
    };
    return ItemCollection;
}());
exports.ItemCollection = ItemCollection;
function extractSettingItemValueSubString(itemValue) {
    if (!itemValue) {
        return itemValue;
    }
    var beginPos = itemValue.indexOf(SETTING_DATA_OPEN_TAG) + SETTING_DATA_OPEN_TAG.length;
    var endPos = itemValue.indexOf(SETTING_DATA_CLOSE_TAG);
    if (beginPos < 0 || endPos < 0) {
        return itemValue;
    }
    return itemValue.substring(beginPos, endPos);
}
exports.extractSettingItemValueSubString = extractSettingItemValueSubString;
function makeSettingItemValue(value) {
    if (!value) {
        return SETTING_DATA_OPEN_TAG + SETTING_DATA_CLOSE_TAG;
    }
    return SETTING_DATA_OPEN_TAG + value + SETTING_DATA_CLOSE_TAG;
}
exports.makeSettingItemValue = makeSettingItemValue;
function MergeAndUpdateGovernedChannelStates(previousStates, currentStates) {
    var stateCollections = new ItemCollection();
    // Todo: move the following into a generic method to be shared
    if (!previousStates && !currentStates) {
        return stateCollections;
    }
    if (!previousStates) {
        stateCollections.add(StateListType.Merged, currentStates);
        return stateCollections;
    }
    if (!currentStates) {
        stateCollections.add(StateListType.Merged, previousStates);
        stateCollections.add(StateListType.ToBeUpdated, previousStates);
        return stateCollections;
    }
    var mergedStates = [];
    var toBeUpdatedStates = [];
    if (previousStates) {
        previousStates.forEach(function (previousState) {
            // find matching in current states
            var pos = -1;
            var currentState;
            for (var i = 0; i < currentStates.length; i++) {
                currentState = currentStates[i];
                if (currentState.getType() === previousState.getType()) {
                    pos = i;
                    break;
                }
            }
            // if previous state is not in current states
            if (pos === -1) {
                // add previous state to merged
                mergedStates.push(previousState);
                // add previous state to toUpdated
                toBeUpdatedStates.push(previousState);
                return;
            }
            // if ActivationTimeUtc of previous is greater than current
            if (previousState.getCooldownStartTime() > currentState.getCooldownStartTime()) {
                // add previous state to merged
                mergedStates.push(previousState);
                // add previous state to toUpdated
                toBeUpdatedStates.push(previousState);
            }
            else {
                // add current state to merged
                mergedStates.push(currentState);
            }
            // remove state from current states
            currentStates.splice(pos, 1);
        });
    }
    // for the remaining current states
    if (currentStates) {
        currentStates.forEach(function (currentState) {
            // add current state to merged
            mergedStates.push(currentState);
        });
    }
    stateCollections.add(StateListType.Merged, mergedStates);
    if (toBeUpdatedStates.length > 0) {
        stateCollections.add(StateListType.ToBeUpdated, toBeUpdatedStates);
    }
    return stateCollections;
}
exports.MergeAndUpdateGovernedChannelStates = MergeAndUpdateGovernedChannelStates;
function MergeAndUpdateCampaignStates(previousStates, currentStates) {
    var stateCollections = new ItemCollection();
    if (!previousStates && !currentStates) {
        return stateCollections;
    }
    if (!previousStates) {
        stateCollections.add(StateListType.Merged, currentStates);
        return stateCollections;
    }
    if (!currentStates) {
        stateCollections.add(StateListType.Merged, previousStates);
        stateCollections.add(StateListType.ToBeUpdated, previousStates);
        return stateCollections;
    }
    var mergedStates = [];
    var toBeUpdatedStates = [];
    if (previousStates) {
        previousStates.forEach(function (previousState) {
            var current = new Date();
            // find matching in current states
            var pos = -1;
            var currentState;
            for (var i = 0; i < currentStates.length; i++) {
                currentState = currentStates[i];
                if (currentState.CampaignId === previousState.CampaignId) {
                    pos = i;
                    break;
                }
            }
            // if previous state is not in current states
            if (pos === -1) {
                // add previous state to merged
                mergedStates.push(previousState);
                // add previous state to toUpdated
                toBeUpdatedStates.push(previousState);
                return;
            }
            // if current state is in cooldown, add to merged list
            if (currentState.LastCooldownEndTimeUtc > current) {
                // add previous state to merged list
                mergedStates.push(currentState);
                // emove state from current states
                currentStates.splice(pos, 1);
                return;
            }
            // if previous state is in cooldown, add to merged and toUpdated lists
            if (previousState.LastCooldownEndTimeUtc > current) {
                // add previous state to merged list
                mergedStates.push(previousState);
                // add previous state to to-be-updated list
                toBeUpdatedStates.push(previousState);
                // emove state from current states
                currentStates.splice(pos, 1);
                return;
            }
            // add previous state to toUpdated and merged for the following two conditions:
            //  1. previous cooldown time wins, or
            //  2. cooldown times are the same and previous nomination wins
            if (previousState.getCooldownStartDate() > currentState.getCooldownStartDate()
                || (previousState.getCooldownStartDate() === currentState.getCooldownStartDate()
                    && previousState.LastNominationTimeUtc > currentState.LastNominationTimeUtc)) {
                mergedStates.push(previousState);
                toBeUpdatedStates.push(previousState);
            }
            else {
                // add current state to merged
                mergedStates.push(currentState);
            }
            // remove state from current states
            currentStates.splice(pos, 1);
        });
    }
    // for the remaining current states
    if (currentStates) {
        currentStates.forEach(function (current) {
            // add current state to merged
            mergedStates.push(current);
        });
    }
    stateCollections.add(StateListType.Merged, mergedStates);
    if (toBeUpdatedStates.length > 0) {
        stateCollections.add(StateListType.ToBeUpdated, toBeUpdatedStates);
    }
    return stateCollections;
}
exports.MergeAndUpdateCampaignStates = MergeAndUpdateCampaignStates;
function MergeAndUpdateSurveyActivationStats(previousStats, currentStats) {
    var statsCollections = new ItemCollection();
    // Handle previousStats and/or currentStats equal to null
    if (!previousStats && !currentStats) {
        return statsCollections;
    }
    if (!previousStats) {
        statsCollections.add(StateListType.Merged, currentStats);
        return statsCollections;
    }
    if (!currentStats) {
        statsCollections.add(StateListType.Merged, previousStats);
        statsCollections.add(StateListType.ToBeUpdated, previousStats);
        return statsCollections;
    }
    // Enumerate preivous stats
    var mergedStats = new SurveyStatCollectionActivation_1.SurveyStatCollectionActivation();
    var toBeUpdatedStats = new SurveyStatCollectionActivation_1.SurveyStatCollectionActivation();
    var previousStatsDictionary = previousStats.getStats();
    var currentStatsDictionary = currentStats.getStats();
    for (var surveyId in previousStatsDictionary) {
        if (previousStatsDictionary.hasOwnProperty(surveyId)) {
            // if an updated stat is not found
            var currentActivationStats = currentStatsDictionary[surveyId];
            if (!currentActivationStats) {
                // add to merged collection
                mergedStats.addStats(surveyId, previousStatsDictionary[surveyId]);
                // add to-be-updated collection
                toBeUpdatedStats.addStats(surveyId, previousStatsDictionary[surveyId]);
                continue;
            }
            // if previous stat has higher activation time
            var previousActivationStats = previousStatsDictionary[surveyId];
            if (previousActivationStats.ActivationTimeUtc > currentActivationStats.ActivationTimeUtc) {
                // add to merged collection
                mergedStats.addStats(surveyId, previousStatsDictionary[surveyId]);
                // add to-be-updated collection
                toBeUpdatedStats.addStats(surveyId, previousStatsDictionary[surveyId]);
            }
            else {
                // add to current stat merged collection
                mergedStats.addStats(surveyId, currentStatsDictionary[surveyId]);
            }
            // delete from stats
            delete currentStatsDictionary[surveyId];
        }
    }
    // merge any remaining stats
    for (var surveyId in currentStatsDictionary) {
        if (currentStatsDictionary.hasOwnProperty(surveyId)) {
            mergedStats.addStats(surveyId, currentStatsDictionary[surveyId]);
        }
    }
    // always return merged list
    statsCollections.add(StateListType.Merged, mergedStats);
    // since dictionaries don't have an empty check, enumerate the  first entry to check for non-empty
    var toBeUpdatedStatsDictionary = toBeUpdatedStats.getStats();
    for (var toBeUpdated in toBeUpdatedStatsDictionary) {
        if (toBeUpdatedStatsDictionary.hasOwnProperty(toBeUpdated)) {
            // not empty, return to-be-updated
            statsCollections.add(StateListType.ToBeUpdated, toBeUpdatedStats);
            break;
        }
    }
    return statsCollections;
}
exports.MergeAndUpdateSurveyActivationStats = MergeAndUpdateSurveyActivationStats;
var SETTING_DATA_OPEN_TAG = "<data>";
var SETTING_DATA_CLOSE_TAG = "</data>";
