"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ISurvey = require("../Api/ISurvey");
var SurveyEvents_1 = require("../SurveyEvents");
var SurveyMetadata_1 = require("../SurveyMetadata");
var FpsSurvey = require("../Surveys/FpsSurvey");
var GenericMessagingSurfaceSurvey = require("../Surveys/GenericMessagingSurfaceSurvey");
var NlqsSurvey = require("../Surveys/NlqsSurvey");
var NpsSurvey = require("../Surveys/NpsSurvey");
var SurveyDataSource = require("../Surveys/SurveyDataSource");
var Utils = require("../Utils");
var CampaignDefinitionProvider_1 = require("./CampaignDefinitionProvider");
/**
 * Contains methods to take a campaign definition + state and generate an ISurvey
 */
var CampaignSurveyFactory = /** @class */ (function () {
    function CampaignSurveyFactory() {
    }
    // region Survey methods
    CampaignSurveyFactory.makeSurvey = function (state, governedChannelType, surveyModel, stringProvider, additionalDataRequested, launcherType) {
        if (!state || !surveyModel || !stringProvider) {
            return null;
        }
        if (surveyModel instanceof CampaignDefinitionProvider_1.CampaignSurveyTemplateNps5PointStatic) {
            return NpsSurvey.make5Point(CampaignSurveyFactory.makeSurveyDataSourceData(state, governedChannelType, surveyModel, additionalDataRequested, launcherType), stringProvider);
        }
        else if (surveyModel instanceof CampaignDefinitionProvider_1.CampaignSurveyTemplateNps11PointStatic) {
            return NpsSurvey.make11Point(CampaignSurveyFactory.makeSurveyDataSourceData(state, governedChannelType, surveyModel, additionalDataRequested, launcherType), stringProvider);
        }
        else if (surveyModel instanceof CampaignDefinitionProvider_1.CampaignSurveyTemplateFps) {
            return FpsSurvey.makeFps(CampaignSurveyFactory.makeSurveyDataSourceData(state, governedChannelType, surveyModel, additionalDataRequested, launcherType), stringProvider, surveyModel);
        }
        else if (surveyModel instanceof CampaignDefinitionProvider_1.CampaignSurveyTemplateNlqs) {
            return NlqsSurvey.makeNlqs(CampaignSurveyFactory.makeSurveyDataSourceData(state, governedChannelType, surveyModel, additionalDataRequested, launcherType), stringProvider, surveyModel);
        }
        else if (surveyModel instanceof CampaignDefinitionProvider_1.CampaignSurveyTemplateNps) {
            return NpsSurvey.makeCustom(CampaignSurveyFactory.makeSurveyDataSourceData(state, governedChannelType, surveyModel, additionalDataRequested, launcherType), stringProvider, surveyModel);
        }
        else if (surveyModel instanceof CampaignDefinitionProvider_1.CampaignSurveyTemplateGenericMessagingSurface) {
            return GenericMessagingSurfaceSurvey.makeCustom(CampaignSurveyFactory.makeSurveyDataSourceData(state, governedChannelType, surveyModel, additionalDataRequested, launcherType));
        }
        else {
            return null;
        }
    };
    CampaignSurveyFactory.makeSurveyDataSourceData = function (state, governedChannelType, surveyModel, additionalDataRequested, launcherType) {
        if (!state || !surveyModel) {
            return null;
        }
        var surveyData = new SurveyDataSource.SurveyDataSourceData();
        surveyData.activationEvent = CampaignSurveyFactory.makeEvent(state, surveyModel.activationEvent);
        surveyData.metadata = CampaignSurveyFactory.makeMetadata(surveyModel.metadata);
        surveyData.id = state.LastSurveyId;
        surveyData.backEndIdentifier = state.CampaignId;
        surveyData.expirationTimeUtc = Utils.dateToShortUtcString(state.LastSurveyExpirationTimeUtc);
        surveyData.governedChannelType = governedChannelType;
        surveyData.startTimeUtc = Utils.dateToShortUtcString(state.LastSurveyStartTimeUtc);
        surveyData.additionalDataRequested = additionalDataRequested || [];
        surveyData.launcherType = launcherType || ISurvey.LAUNCHER_TYPE_DEFAULT;
        return surveyData;
    };
    // endregion
    // region Event methods
    CampaignSurveyFactory.makeCountedActivityEventData = function (eventModel) {
        if (!eventModel) {
            return null;
        }
        var eventData = new SurveyEvents_1.CountedActivityEventData();
        eventData.activity = eventModel.activity;
        eventData.count = eventModel.count;
        eventData.isAggregate = eventModel.isAggregate;
        return eventData;
    };
    CampaignSurveyFactory.makeCountedActivityEvent = function (state, eventModel) {
        if (!state || !eventModel) {
            return null;
        }
        var eventData = CampaignSurveyFactory.makeCountedActivityEventData(eventModel);
        if (!eventData) {
            return null;
        }
        return new SurveyEvents_1.CountedActivityEvent(eventData);
    };
    CampaignSurveyFactory.makeCountedActivitySequenceEvent = function (state, eventModel) {
        if (!state || !eventModel) {
            return null;
        }
        var sequenceEventData = new SurveyEvents_1.CountedActivitySequenceEventData();
        sequenceEventData.sequence = [];
        for (var _i = 0, _a = eventModel.sequence; _i < _a.length; _i++) {
            var event_1 = _a[_i];
            var eventData = CampaignSurveyFactory.makeCountedActivityEventData(event_1);
            if (!eventData) {
                return null;
            }
            sequenceEventData.sequence.push(eventData);
        }
        return new SurveyEvents_1.CountedActivitySequenceEvent(sequenceEventData);
    };
    CampaignSurveyFactory.makeEvent = function (state, eventModel) {
        if (eventModel instanceof CampaignDefinitionProvider_1.CampaignSurveyEventCountedActivity) {
            return CampaignSurveyFactory.makeCountedActivityEvent(state, eventModel);
        }
        else if (eventModel instanceof CampaignDefinitionProvider_1.CampaignSurveyEventCountedActivitySequence) {
            return CampaignSurveyFactory.makeCountedActivitySequenceEvent(state, eventModel);
        }
        else {
            return null;
        }
    };
    CampaignSurveyFactory.makeMetadata = function (metadataModel) {
        if (Utils.isNullOrUndefined(metadataModel) || Utils.isNullOrUndefined(metadataModel.contentMetadata)) {
            return null;
        }
        var metadata = new SurveyMetadata_1.Metadata();
        metadata.contentMetadata = metadataModel.contentMetadata;
        return new SurveyMetadata_1.SurveyMetadata(metadata);
    };
    return CampaignSurveyFactory;
}());
exports.CampaignSurveyFactory = CampaignSurveyFactory;
