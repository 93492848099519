"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var ISurvey = require("../Api/ISurvey");
var Utils = require("../Utils");
var Survey_1 = require("./Survey");
var SurveyDataSource = require("./SurveyDataSource");
var GenericMessagingSurfaceSurvey = /** @class */ (function (_super) {
    __extends(GenericMessagingSurfaceSurvey, _super);
    function GenericMessagingSurfaceSurvey(data) {
        var _this = _super.call(this) || this;
        if (!data) {
            throw new Error("data must not be null");
        }
        _this.surveyInfo = new SurveyDataSource(data.baseData);
        // This check cannot be done in survey info constructor
        // as metadata is specific to this survey type.
        if (Utils.isNullOrUndefined(_this.surveyInfo.getMetadata())) {
            throw new Error("metadata must not be null");
        }
        return _this;
    }
    GenericMessagingSurfaceSurvey.make = function (data) {
        try {
            return new GenericMessagingSurfaceSurvey(data);
        }
        catch (e) {
            return null;
        }
    };
    GenericMessagingSurfaceSurvey.makeCustom = function (baseData) {
        if (Utils.isNullOrUndefined(baseData)) {
            return null;
        }
        var data = new GenericMessagingSurfaceSurvey.GenericMessagingSurfaceSurveyData();
        data.baseData = baseData;
        return this.make(data);
    };
    // @Override
    GenericMessagingSurfaceSurvey.prototype.getType = function () {
        return ISurvey.Type.GenericMessagingSurface;
    };
    // @Override
    GenericMessagingSurfaceSurvey.prototype.getSurveyInfo = function () {
        return this.surveyInfo;
    };
    // @Override
    GenericMessagingSurfaceSurvey.prototype.getComponent = function (componentType) {
        return null;
    };
    // @Override
    GenericMessagingSurfaceSurvey.prototype.getDomElements = function (doc) {
        return [];
    };
    // @Override
    GenericMessagingSurfaceSurvey.prototype.getJsonElements = function () {
        return {};
    };
    return GenericMessagingSurfaceSurvey;
}(Survey_1.Survey));
(function (GenericMessagingSurfaceSurvey) {
    /**
     * Data required for a Generic Surface Survey
     */
    var GenericMessagingSurfaceSurveyData = /** @class */ (function () {
        function GenericMessagingSurfaceSurveyData() {
        }
        return GenericMessagingSurfaceSurveyData;
    }());
    GenericMessagingSurfaceSurvey.GenericMessagingSurfaceSurveyData = GenericMessagingSurfaceSurveyData;
})(GenericMessagingSurfaceSurvey || (GenericMessagingSurfaceSurvey = {}));
module.exports = GenericMessagingSurfaceSurvey;
