﻿/**
 * Screenshot.ts
 *
 * A module for screenshot related logic.
 * Note: It uses the htnl2canvas package. The package could be used as is, it is not packaged correctly for browserify.
 * Using it without browserify would have polluted the global namespace by overriding "window.html2canvas".
 * Until these issues get fixed the code has been changed to pollute window.OfficeBrowserFeedback.html2canvas instead.
 */

import * as Window from "./Window/Window";

/**
 * Create screenshot
 */
export function createScreenShot(): Promise<HTMLCanvasElement> {
	let html2canvas = Window.get().OfficeBrowserFeedback.html2canvas();
	return html2canvas(document.body, { background: "#ffffff" });
}
