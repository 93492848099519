"use strict";
/*
 * Utils.ts
 *
 * Module for utility functions
 */
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Add time to a given date
 * Example, timeAdd(new Date(), 'minute', 5)  //returns 5 minutes from now
 * @param date  Date to start with
 * @param interval  One of: hour or h, minute or m, second or s
 * @param units  units of the given interval to add
 * @return date
 */
function timeAdd(date, interval, units) {
    switch (interval.toLowerCase()) {
        case "h":
        case "hour":
            return new Date(date.getTime() + (units * 3600000));
        case "m":
        case "minute":
            return new Date(date.getTime() + (units * 60000));
        case "s":
        case "second":
            return new Date(date.getTime() + (units * 1000));
        default:
            throw new Error("Invalid interval value of " + interval);
    }
}
exports.timeAdd = timeAdd;
/**
 * Check if an input value is a valid date, null or undefined return false.
 * @param input  input value
 * @return boolean
 */
function isDate(input) {
    if (Object.prototype.toString.call(input) === "[object Date]") {
        // it is a date
        if (!(isNaN(input.getTime()))) {
            return true;
        }
    }
    return false;
}
exports.isDate = isDate;
/**
 * Check if an input value is a valid value in the input enum
 * @param value  input value
 * @param input  input enum
 * Returns true if value exists in the enum
 */
function isEnumValue(value, input) {
    return (value in input);
}
exports.isEnumValue = isEnumValue;
/**
 * Check if an input value is a number
 * @param value: input value
 */
function isNumber(value) {
    return (value !== null && !isNaN(value) && isFinite(value));
}
exports.isNumber = isNumber;
/**
 * Check if an input value is null or undefined
 * @param value: input value
 */
function isNullOrUndefined(value) {
    return (value === null || value === undefined);
}
exports.isNullOrUndefined = isNullOrUndefined;
/**
 * Check if an input value is an object
 * @param value: input value
 */
function isObject(value) {
    return (value !== null && value !== undefined && (typeof value === "object"));
}
exports.isObject = isObject;
/**
 * Check if an input value is an array
 * @param value: input value
 */
function isArray(value) {
    return (value !== null && value !== undefined && (Array.isArray(value)));
}
exports.isArray = isArray;
/**
 * Check if given value is a string
 * @param {any} value value
 */
function isString(value) {
    return (typeof value === "string");
}
exports.isString = isString;
/**
 * Check if value is an object
 * @param {any} value value
 */
function isBoolean(value) {
    return typeof (value) === "boolean";
}
exports.isBoolean = isBoolean;
/**
 * Returns a lower temporal boundary
 * @return date
 */
function getDistantPast() {
    // Corresponds to UTC 1601-01-01T00:00:00Z
    return new Date(-11644473600000);
}
exports.getDistantPast = getDistantPast;
/**
 * Get an upper temporal boundary
 * @return date
 */
function getDistantFuture() {
    // Corresponds to UTC 4001-01-01T00:00:00Z
    return new Date(64092211200000);
}
exports.getDistantFuture = getDistantFuture;
exports.MAX_DATE_MILLISECONDS = 8640000000000000;
exports.MIN_DATE_MILLISECONDS = -8640000000000000;
/**
 * Adds seconds to a date, if overflows returns Date(Number.Max_VALUE)
 *
 * @param date    date to add to
 * @param seconds seconds as number
 * @return Resulting date
 */
function addSecondsWithoutOverflow(date, seconds) {
    if (!date) {
        return null;
    }
    if (seconds < 0) {
        return subtractSecondsWithoutOverflow(date, -1 * seconds);
    }
    else {
        var milliseconds = date.getTime() + seconds * 1000;
        if (milliseconds < exports.MAX_DATE_MILLISECONDS) {
            return new Date(milliseconds);
        }
        else {
            return new Date(exports.MAX_DATE_MILLISECONDS);
        }
    }
}
exports.addSecondsWithoutOverflow = addSecondsWithoutOverflow;
/**
 * Subtracts seconds from a date, if overflows returns Date(Number.MIN_VALUE)
 *
 * @param date   date to subtract from
 * @param seconds seconds as number
 * @return Resulting date
 */
function subtractSecondsWithoutOverflow(date, seconds) {
    if (!date) {
        return null;
    }
    if (seconds < 0) {
        seconds = -seconds;
        return this.addSecondsWithoutOverflow(date, seconds);
    }
    var milliseconds = date.getTime() - (seconds * 1000);
    if (milliseconds > exports.MIN_DATE_MILLISECONDS) {
        return new Date(milliseconds);
    }
    else {
        return new Date(exports.MIN_DATE_MILLISECONDS);
    }
}
exports.subtractSecondsWithoutOverflow = subtractSecondsWithoutOverflow;
// region Language related
/**
 * Effectively un-anchored on the right side because tags can have many more trailing sub-parts than we care to extract
 * Refer to https://www.ietf.org/rfc/rfc5646.txt
 */
var LANGUAGE_AND_SCRIPT_TAG_PATTERN = "^" +
    // capture 1:language subtag
    "(" +
    "(?:[a-zA-Z]{2,3}(?:-[a-zA-Z]{3}){0,3})" + // 2-3 Alpha chars, followed by up to three optional extension tags, each of format -AAA, A=Alpha char
    "|" +
    "(?:[a-zA-Z]{4,8})" + // 4-Alpha chars (reserved in standard) or 5-8 Alpha chars
    ")" +
    "(?:" +
    "-" +
    // capture 2: optional script subtag (without leading dash), exactly 4 alpha chars
    "([a-zA-Z]{4})" + // 4-Alpha chars
    ")?" +
    // capture 3: optional region subtag (without leading dash), exactly 2 alpha chars or 3 digits
    "(?:-([a-zA-Z]{2}|[0-9]{3}))?" +
    "(" +
    // capture 4: any left-overs, rejecting remainder strings that don't end here or lead with a dash.
    "-.*" +
    ")?" +
    "$";
function isValidLanguageSubTag(subTag) {
    if (!subTag) {
        return false;
    }
    var extractedSubTag = extractLanguageSubtag(subTag);
    if (!extractedSubTag) {
        return false;
    }
    return extractedSubTag === subTag;
}
exports.isValidLanguageSubTag = isValidLanguageSubTag;
function extractLanguageSubtag(language) {
    if (!language) {
        return null;
    }
    var matches = language.match(LANGUAGE_AND_SCRIPT_TAG_PATTERN);
    if (!matches || matches.length < 1 || !isNullOrUndefined(matches[4])) {
        return null;
    }
    // return the first capture group which should be the original input if there is a match
    // For example, "en-US" input should return "en-US", and "en" input should return "en".
    return matches[0];
}
exports.extractLanguageSubtag = extractLanguageSubtag;
// endregion
/**
 * Create guid string
 */
function guid() {
    // Stitch in '4' in the third group
    return (randomHex4() + randomHex4() + "-" + randomHex4() + "-4" + randomHex4().substr(0, 3) + "-" + randomHex4() + "-"
        + randomHex4() + randomHex4() + randomHex4()).toLowerCase();
}
exports.guid = guid;
/**
 * Create random Hex4 string
 */
function randomHex4() {
    return (Math.floor(((1 + Math.random()) * 0x10000))).toString(16).substring(1);
}
/**
 * Create an array from input object values sorted by object key
 * @param Object input object
 * @return array
 */
function makeArrayFromObjectValuesSortedByKeyString(object) {
    var keys = Object.keys(object);
    keys.sort();
    var values = [];
    for (var id in keys) {
        if (keys.hasOwnProperty(id)) {
            var key = keys[id];
            values.push(object[key]);
        }
    }
    return values;
}
exports.makeArrayFromObjectValuesSortedByKeyString = makeArrayFromObjectValuesSortedByKeyString;
/**
 * Create a date object from an input string
 * @param Object input string
 * @return date
 */
function stringToDate(input) {
    if (!isString(input)) {
        return null;
    }
    var newDate = input ? new Date(input) : null;
    return isDate(newDate) ? newDate : null;
}
exports.stringToDate = stringToDate;
/**
 * Takes two objects (source, target) and returns the target object with values in the source added to it.
 * It overwrites any source properties which already exist in target.
 */
function overrideValues(sourceObject, targetobject) {
    if (!targetobject) {
        return targetobject;
    }
    var result = targetobject;
    if (sourceObject) {
        for (var field in sourceObject) {
            if (sourceObject.hasOwnProperty(field)) {
                result[field] = sourceObject[field];
            }
        }
    }
    return result;
}
exports.overrideValues = overrideValues;
/**
 * Test a string is in our supported ISO8601 UTC format of "yyyy-MM-ddTHH:mm:ssZ" and "yyyy-MM-ddTHH:mm:ss.fffZ"
 * @param input Input string to be evaluated.
 */
function isUtcDatetimeString(input) {
    if (!isString(input)) {
        return false;
    }
    var supportedUtcRegex = /^(\d{4}\-\d\d\-\d\dT\d\d:\d\d:\d\d(\.\d\d\d)?Z)$/;
    return supportedUtcRegex.test(input);
}
exports.isUtcDatetimeString = isUtcDatetimeString;
/**
 * Convert a date object to a string in ISO8601 UTC format supported by Floodgate ("yyyy-MM-ddTHH:mm:ssZ")
 * @param input Input date object
 */
function dateToShortUtcString(input) {
    if (!isDate(input)) {
        return null;
    }
    function pad(n) {
        return (n < 10) ? ("0" + n) : n.toString();
    }
    return input.getUTCFullYear() +
        "-" + pad(input.getUTCMonth() + 1) +
        "-" + pad(input.getUTCDate()) +
        "T" + pad(input.getUTCHours()) +
        ":" + pad(input.getUTCMinutes()) +
        ":" + pad(input.getUTCSeconds()) +
        "Z";
}
exports.dateToShortUtcString = dateToShortUtcString;
