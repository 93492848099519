"use strict";
var ISurveyComponent = require("./../Api/ISurveyComponent");
var MultipleChoiceComponent = /** @class */ (function () {
    function MultipleChoiceComponent(data) {
        if (!data) {
            throw new Error("data must not be null");
        }
        if (!data.question) {
            throw new Error("data.question must not be null or empty");
        }
        if (!data.availableOptions || data.availableOptions.length < 2) {
            throw new Error("data.availableOptions must not be null or have less than two choices");
        }
        data.availableOptions.forEach(function (option) {
            if (!option) {
                throw new Error("Option values must not contain null or empty");
            }
        });
        this.data = data;
        this.optionStates = new Array(data.availableOptions.length);
        // Initialize with all false values
        for (var i = 0; i < this.optionStates.length; i++) {
            this.optionStates[i] = false;
        }
    }
    MultipleChoiceComponent.make = function (data) {
        try {
            return new MultipleChoiceComponent(data);
        }
        catch (e) {
            return null;
        }
    };
    MultipleChoiceComponent.prototype.getType = function () {
        return ISurveyComponent.Type.MultipleChoice;
    };
    MultipleChoiceComponent.prototype.getQuestion = function () {
        return this.data.question;
    };
    MultipleChoiceComponent.prototype.getAvailableOptions = function () {
        return this.data.availableOptions;
    };
    MultipleChoiceComponent.prototype.getOptionSelectedStates = function () {
        return this.optionStates;
    };
    MultipleChoiceComponent.prototype.getMinNumberofSelectedOptions = function () {
        return this.data.minNumberOfSelectedOptions;
    };
    MultipleChoiceComponent.prototype.getMaxNumberofSelectedOptions = function () {
        return this.data.maxNumberOfSelectedOptions;
    };
    MultipleChoiceComponent.prototype.setOptionSelectedStates = function (selectedStates) {
        for (var i = 0; i < selectedStates.length; i++) {
            this.optionStates[i] = selectedStates[i];
        }
    };
    MultipleChoiceComponent.prototype.ValidateMinNumberofSelectedOptions = function () {
        var result = 0;
        this.optionStates.forEach(function (element) {
            if (element) {
                result++;
            }
        });
        return (result >= this.getMinNumberofSelectedOptions());
    };
    MultipleChoiceComponent.prototype.ValidateMaxNumberofSelectedOptions = function () {
        var result = 0;
        this.optionStates.forEach(function (element) {
            if (element) {
                result++;
            }
        });
        return (result <= this.getMaxNumberofSelectedOptions());
    };
    MultipleChoiceComponent.prototype.getDomElements = function (doc) {
        if (!doc) {
            throw new Error("Document must not be null");
        }
        var element = doc.createElement(ISurveyComponent.DOM_MULTIPLECHOICE_TAGNAME);
        element.appendChild(doc.createTextNode(this.getOptionSelectedStates().toString()));
        return [element];
    };
    // @Override
    MultipleChoiceComponent.prototype.getJsonElements = function () {
        var result = {};
        result[ISurveyComponent.JSON_MULTIPLECHOICE_KEYNAME] = this.getOptionSelectedStates().toString();
        return result;
    };
    return MultipleChoiceComponent;
}());
(function (MultipleChoiceComponent) {
    var MultipleChoiceComponentData = /** @class */ (function () {
        function MultipleChoiceComponentData() {
        }
        return MultipleChoiceComponentData;
    }());
    MultipleChoiceComponent.MultipleChoiceComponentData = MultipleChoiceComponentData;
})(MultipleChoiceComponent || (MultipleChoiceComponent = {}));
module.exports = MultipleChoiceComponent;
