"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var IFloodgateStringProvider = require("../Api/IFloodgateStringProvider");
var ISurvey = require("../Api/ISurvey");
var ISurveyComponent = require("../Api/ISurveyComponent");
var CommentComponent = require("../SurveyComponents/CommentComponent");
var PromptComponent = require("../SurveyComponents/PromptComponent");
var RatingComponent = require("../SurveyComponents/RatingComponent");
var Utils = require("../Utils");
var Survey_1 = require("./Survey");
var SurveyDataSource = require("./SurveyDataSource");
var NpsSurvey = /** @class */ (function (_super) {
    __extends(NpsSurvey, _super);
    function NpsSurvey(data) {
        var _this = _super.call(this) || this;
        if (!data) {
            throw new Error("data must not be null");
        }
        _this.surveyInfo = new SurveyDataSource(data.baseData);
        _this.prompt = new PromptComponent(data.promptData);
        _this.question = new CommentComponent(data.commentData);
        _this.rating = new RatingComponent(data.ratingData);
        return _this;
    }
    NpsSurvey.make = function (data) {
        try {
            return new NpsSurvey(data);
        }
        catch (e) {
            return null;
        }
    };
    NpsSurvey.makeCustom = function (baseData, sp, surveyModel) {
        if (Utils.isNullOrUndefined(baseData) || Utils.isNullOrUndefined(sp) || Utils.isNullOrUndefined(surveyModel)) {
            return null;
        }
        var ratingValuesAscending = [];
        var content = surveyModel.content;
        if (Utils.isNullOrUndefined(content) || Utils.isNullOrUndefined(content.comment)
            || Utils.isNullOrUndefined(content.prompt) || Utils.isNullOrUndefined(content.rating)) {
            return null;
        }
        var data = new NpsSurvey.NpsSurveyData();
        data.baseData = baseData;
        data.promptData = new PromptComponent.PromptComponentData();
        data.ratingData = new RatingComponent.RatingComponentData();
        data.commentData = new CommentComponent.CommentComponentData();
        data.promptData.title = sp.getCustomString(content.prompt.title);
        data.promptData.question = sp.getCustomString(content.prompt.question);
        data.promptData.yesButtonLabel = sp.getCustomString(content.prompt.yesLabel);
        data.promptData.noButtonLabel = sp.getCustomString(content.prompt.noLabel);
        data.ratingData.question = sp.getCustomString(content.rating.question);
        data.ratingData.isZeroBased = content.rating.isZeroBased;
        data.commentData.question = sp.getCustomString(content.comment.question);
        for (var _i = 0, _a = content.rating.ratingValuesAscending; _i < _a.length; _i++) {
            var value = _a[_i];
            var customString = sp.getCustomString(value);
            if (Utils.isNullOrUndefined(customString)) {
                return null;
            }
            ratingValuesAscending.push(customString);
        }
        data.ratingData.ratingValuesAscending = ratingValuesAscending;
        if (Utils.isNullOrUndefined(data.ratingData.question)
            || Utils.isNullOrUndefined(data.commentData.question)
            || Utils.isNullOrUndefined(data.promptData.title)
            || Utils.isNullOrUndefined(data.promptData.question)
            || Utils.isNullOrUndefined(data.promptData.yesButtonLabel)
            || Utils.isNullOrUndefined(data.promptData.noButtonLabel)
            || Utils.isNullOrUndefined(data.ratingData.ratingValuesAscending)) {
            return null;
        }
        return this.make(data);
    };
    NpsSurvey.make5Point = function (baseData, sp) {
        if (!baseData || !sp) {
            return null;
        }
        var data = new NpsSurvey.NpsSurveyData();
        data.baseData = baseData;
        data.ratingData = new RatingComponent.RatingComponentData();
        data.ratingData.isZeroBased = false;
        data.commentData = new CommentComponent.CommentComponentData();
        data.promptData = new PromptComponent.PromptComponentData();
        data.ratingData.question = sp.loadStringResource(IFloodgateStringProvider.StringType.NpsRatingQuestion);
        data.commentData.question = sp.loadStringResource(IFloodgateStringProvider.StringType.NpsCommentQuestion);
        data.promptData.title = sp.loadStringResource(IFloodgateStringProvider.StringType.NpsPromptTitle);
        data.promptData.question = sp.loadStringResource(IFloodgateStringProvider.StringType.NpsPromptQuestion);
        data.promptData.yesButtonLabel = sp.loadStringResource(IFloodgateStringProvider.StringType.NpsPromptYesLabel);
        data.promptData.noButtonLabel = sp.loadStringResource(IFloodgateStringProvider.StringType.NpsPromptNotNowLabel);
        var ratingValuesAscending = new Array(5);
        ratingValuesAscending[0] = sp.loadStringResource(IFloodgateStringProvider.StringType.Nps5RatingValue1);
        ratingValuesAscending[1] = sp.loadStringResource(IFloodgateStringProvider.StringType.Nps5RatingValue2);
        ratingValuesAscending[2] = sp.loadStringResource(IFloodgateStringProvider.StringType.Nps5RatingValue3);
        ratingValuesAscending[3] = sp.loadStringResource(IFloodgateStringProvider.StringType.Nps5RatingValue4);
        ratingValuesAscending[4] = sp.loadStringResource(IFloodgateStringProvider.StringType.Nps5RatingValue5);
        if (!data.ratingData.question
            || !data.commentData.question
            || !data.promptData.title
            || !data.promptData.question
            || !data.promptData.yesButtonLabel
            || !data.promptData.noButtonLabel
            || !ratingValuesAscending[0]
            || !ratingValuesAscending[1]
            || !ratingValuesAscending[2]
            || !ratingValuesAscending[3]
            || !ratingValuesAscending[4]) {
            return null;
        }
        data.ratingData.ratingValuesAscending = ratingValuesAscending;
        return this.make(data);
    };
    NpsSurvey.make11Point = function (baseData, sp) {
        if (!baseData || !sp) {
            return null;
        }
        var data = new NpsSurvey.NpsSurveyData();
        data.baseData = baseData;
        data.ratingData = new RatingComponent.RatingComponentData();
        data.ratingData.isZeroBased = true;
        data.commentData = new CommentComponent.CommentComponentData();
        data.promptData = new PromptComponent.PromptComponentData();
        data.ratingData.question = sp.loadStringResource(IFloodgateStringProvider.StringType.NpsRatingQuestion);
        data.commentData.question = sp.loadStringResource(IFloodgateStringProvider.StringType.NpsCommentQuestion);
        data.promptData.title = sp.loadStringResource(IFloodgateStringProvider.StringType.NpsPromptTitle);
        data.promptData.question = sp.loadStringResource(IFloodgateStringProvider.StringType.NpsPromptQuestion);
        data.promptData.yesButtonLabel = sp.loadStringResource(IFloodgateStringProvider.StringType.NpsPromptYesLabel);
        data.promptData.noButtonLabel = sp.loadStringResource(IFloodgateStringProvider.StringType.NpsPromptNotNowLabel);
        var ratingValuesAscending = new Array(11);
        ratingValuesAscending[0] = sp.loadStringResource(IFloodgateStringProvider.StringType.Nps11RatingValue0);
        ratingValuesAscending[1] = sp.loadStringResource(IFloodgateStringProvider.StringType.Nps11RatingValue1);
        ratingValuesAscending[2] = sp.loadStringResource(IFloodgateStringProvider.StringType.Nps11RatingValue2);
        ratingValuesAscending[3] = sp.loadStringResource(IFloodgateStringProvider.StringType.Nps11RatingValue3);
        ratingValuesAscending[4] = sp.loadStringResource(IFloodgateStringProvider.StringType.Nps11RatingValue4);
        ratingValuesAscending[5] = sp.loadStringResource(IFloodgateStringProvider.StringType.Nps11RatingValue5);
        ratingValuesAscending[6] = sp.loadStringResource(IFloodgateStringProvider.StringType.Nps11RatingValue6);
        ratingValuesAscending[7] = sp.loadStringResource(IFloodgateStringProvider.StringType.Nps11RatingValue7);
        ratingValuesAscending[8] = sp.loadStringResource(IFloodgateStringProvider.StringType.Nps11RatingValue8);
        ratingValuesAscending[9] = sp.loadStringResource(IFloodgateStringProvider.StringType.Nps11RatingValue9);
        ratingValuesAscending[10] = sp.loadStringResource(IFloodgateStringProvider.StringType.Nps11RatingValue10);
        if (!data.ratingData.question
            || !data.commentData.question
            || !data.promptData.title
            || !data.promptData.question
            || !data.promptData.yesButtonLabel
            || !data.promptData.noButtonLabel
            || !ratingValuesAscending[0]
            || !ratingValuesAscending[1]
            || !ratingValuesAscending[2]
            || !ratingValuesAscending[3]
            || !ratingValuesAscending[4]
            || !ratingValuesAscending[5]
            || !ratingValuesAscending[6]
            || !ratingValuesAscending[7]
            || !ratingValuesAscending[8]
            || !ratingValuesAscending[9]
            || !ratingValuesAscending[10]) {
            return null;
        }
        data.ratingData.ratingValuesAscending = ratingValuesAscending;
        return this.make(data);
    };
    // @Override
    NpsSurvey.prototype.getType = function () {
        return ISurvey.Type.Nps;
    };
    // @Override
    NpsSurvey.prototype.getSurveyInfo = function () {
        return this.surveyInfo;
    };
    // @Override
    NpsSurvey.prototype.getCommentComponent = function () {
        return this.question;
    };
    // @Override
    NpsSurvey.prototype.getPromptComponent = function () {
        return this.prompt;
    };
    // @Override
    NpsSurvey.prototype.getRatingComponent = function () {
        return this.rating;
    };
    // @Override
    NpsSurvey.prototype.getComponent = function (componentType) {
        switch (componentType) {
            case ISurveyComponent.Type.Comment:
                return this.getCommentComponent();
            case ISurveyComponent.Type.Prompt:
                return this.getPromptComponent();
            case ISurveyComponent.Type.Rating:
                return this.getRatingComponent();
            default:
                return null;
        }
    };
    // @Override
    NpsSurvey.prototype.getDomElements = function (doc) {
        if (!doc) {
            throw new Error("Document must not be null");
        }
        var element = doc.createElement(ISurvey.DOM_NPS_TAGNAME);
        this.getSurveyInfo().getDomElements(doc).forEach(function (child) {
            if (child) {
                element.appendChild(child);
            }
        });
        this.getCommentComponent().getDomElements(doc).forEach(function (child) {
            if (child) {
                element.appendChild(child);
            }
        });
        this.getRatingComponent().getDomElements(doc).forEach(function (child) {
            if (child) {
                element.appendChild(child);
            }
        });
        return [element];
    };
    // @Override
    NpsSurvey.prototype.getJsonElements = function () {
        var result = {};
        result = Utils.overrideValues(this.getSurveyInfo().getJsonElements(), result);
        result = Utils.overrideValues(this.getCommentComponent().getJsonElements(), result);
        result = Utils.overrideValues(this.getRatingComponent().getJsonElements(), result);
        return result;
    };
    return NpsSurvey;
}(Survey_1.Survey));
(function (NpsSurvey) {
    /**
     * Data required for a Nps Survey
     */
    var NpsSurveyData = /** @class */ (function () {
        function NpsSurveyData() {
        }
        return NpsSurveyData;
    }());
    NpsSurvey.NpsSurveyData = NpsSurveyData;
})(NpsSurvey || (NpsSurvey = {}));
module.exports = NpsSurvey;
