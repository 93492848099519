"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = Object.setPrototypeOf ||
        ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
        function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
var IFloodgateStorageProvider = require("./Api/IFloodgateStorageProvider");
var FileType = IFloodgateStorageProvider.FileType;
var ISurvey = require("./Api/ISurvey");
var Constants_1 = require("./Constants");
var FloodgateEngine_1 = require("./FloodgateEngine");
var StateProviderHelpers = require("./StateProviderHelpers");
var SurveyStatCollection_1 = require("./SurveyStatCollection");
var Utils = require("./Utils");
/**
 * StatCollection for activated surveys.  Should be used to track the SurveyIds
 * that have been recently shown to a user
 */
// TODO (gachoi) Check if this applies - VSOBug: 1443010 One bad Stat object fails the entire serialization
var SurveyStatCollectionActivation = /** @class */ (function (_super) {
    __extends(SurveyStatCollectionActivation, _super);
    function SurveyStatCollectionActivation() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    /**
     * Load from Json
     */
    SurveyStatCollectionActivation.fromJson = function (json) {
        var statCollection = new SurveyStatCollectionActivation();
        if (!json) {
            FloodgateEngine_1.FloodgateEngine.getTelemetryLogger().log_Event(Constants_1.TelemetryEvent.SurveyStatCollectionActivation.FromJson.Failed, { ErrorMessage: "Input json is null or empty." });
            return statCollection;
        }
        var readStats;
        try {
            readStats = JSON.parse(json);
        }
        catch (e) {
            FloodgateEngine_1.FloodgateEngine.getTelemetryLogger().log_Event(Constants_1.TelemetryEvent.SurveyStatCollectionActivation.FromJson.Failed, { ErrorMessage: "Json parsing failed. " + e.toString() });
            return statCollection;
        }
        if (!statCollection.deserialize(readStats)) {
            return new SurveyStatCollectionActivation();
        }
        return statCollection;
    };
    /**
     * Convert to Json
     */
    SurveyStatCollectionActivation.toJson = function (object) {
        if (!object) {
            FloodgateEngine_1.FloodgateEngine.getTelemetryLogger().log_Event(Constants_1.TelemetryEvent.SurveyStatCollectionActivation.ToJson.Failed, { ErrorMessage: "Input json is null or empty." });
            object = new SurveyStatCollectionActivation();
        }
        return JSON.stringify(object);
    };
    /**
     * Add another SurveyStatCollectionActivation object
     */
    SurveyStatCollectionActivation.prototype.accumulate = function (other) {
        if (!other) {
            return;
        }
        var stats = other.getStats();
        // SurveyActivationStats accumulation simply overwrites any keys from 'other' into our collection
        for (var key in stats) {
            if (stats.hasOwnProperty(key)) {
                this.addStats(key, stats[key]);
            }
        }
    };
    /**
     * Method to deserialize SurveyStatCollectionActivation
     * @param input: collection of SurveyStatCollectionActivation
     * Returns result of validation check
     */
    SurveyStatCollectionActivation.prototype.deserialize = function (input) {
        var rawStats = input.Surveys;
        var now = new Date();
        for (var key in rawStats) {
            if (rawStats.hasOwnProperty(key)) {
                var newStat = new SurveyActivationStats();
                if (newStat.deserialize(rawStats[key]) && newStat.ExpirationTimeUtc > now) {
                    this.addStats(key, newStat);
                }
            }
        }
        return this.validate();
    };
    /**
     * Validate the Surveys
     * Returns false if validation fails
     */
    SurveyStatCollectionActivation.prototype.validate = function () {
        return Utils.isObject(this.getStats());
    };
    return SurveyStatCollectionActivation;
}(SurveyStatCollection_1.SurveyStatCollection));
exports.SurveyStatCollectionActivation = SurveyStatCollectionActivation;
var SurveyActivationStatsProviderFactory = /** @class */ (function () {
    function SurveyActivationStatsProviderFactory() {
    }
    SurveyActivationStatsProviderFactory.make = function (fileBasedStorage, hostBasedStorage) {
        if (hostBasedStorage) {
            return new HostBasedSurveyActivationStatsProvider(fileBasedStorage, hostBasedStorage);
        }
        return new FileBasedSurveyActivationStatsProvider(fileBasedStorage);
    };
    return SurveyActivationStatsProviderFactory;
}());
exports.SurveyActivationStatsProviderFactory = SurveyActivationStatsProviderFactory;
var HostBasedSurveyActivationStatsProvider = /** @class */ (function () {
    function HostBasedSurveyActivationStatsProvider(fileBasedStorageProvider, hostBasedStorage) {
        if (!hostBasedStorage) {
            throw new Error("host-based storage must not be null");
        }
        this.hostBasedStorage = hostBasedStorage;
        // file based provider is optional
        if (fileBasedStorageProvider) {
            this.fileBasedStorageProvider = new FileBasedSurveyActivationStatsProvider(fileBasedStorageProvider);
        }
    }
    // @Override
    HostBasedSurveyActivationStatsProvider.prototype.load = function () {
        var surveyStatsFromHost = this.getSurveyStatsFromHost();
        var surveyStatsFromFile = new SurveyStatCollectionActivation();
        if (this.fileBasedStorageProvider) {
            surveyStatsFromFile = this.fileBasedStorageProvider.load();
        }
        var surveyStatsCollections = StateProviderHelpers.MergeAndUpdateSurveyActivationStats(surveyStatsFromFile, surveyStatsFromHost);
        return surveyStatsCollections.find(StateProviderHelpers.StateListType.Merged);
    };
    // @Override
    HostBasedSurveyActivationStatsProvider.prototype.save = function (stats) {
        if (!stats) {
            return;
        }
        var statsFromHost = this.getSurveyStatsFromHost();
        var statCollection = StateProviderHelpers.MergeAndUpdateSurveyActivationStats(stats, statsFromHost);
        var toBeUpdatedStats = statCollection.find(StateProviderHelpers.StateListType.ToBeUpdated);
        var mergedStates = statCollection.find(StateProviderHelpers.StateListType.Merged);
        // write delta_data to Roaming
        if (toBeUpdatedStats) {
            var toBeUpdatedStatsDictionary = toBeUpdatedStats.getStats();
            for (var surveyId in toBeUpdatedStatsDictionary) {
                if (toBeUpdatedStatsDictionary.hasOwnProperty(surveyId)) {
                    var item = StateProviderHelpers.makeSettingItemValue(JSON.stringify(toBeUpdatedStatsDictionary[surveyId]));
                    this.hostBasedStorage.upsertSettingItem(FileType[FileType.SurveyActivationStats], surveyId, item);
                }
            }
        }
        // Write merged data to File
        if (this.fileBasedStorageProvider) {
            this.fileBasedStorageProvider.save(mergedStates);
        }
    };
    HostBasedSurveyActivationStatsProvider.prototype.getSurveyStatsFromHost = function () {
        var statsCollection = new SurveyStatCollectionActivation();
        var itemData = this.hostBasedStorage.readSettingList(FileType[FileType.SurveyActivationStats]);
        for (var surveyId in itemData) {
            if (!itemData.hasOwnProperty(surveyId)) {
                continue;
            }
            var dataString = StateProviderHelpers.extractSettingItemValueSubString(itemData[surveyId]);
            var newStats = new SurveyActivationStats();
            var readStats = void 0;
            try {
                readStats = JSON.parse(dataString);
                newStats.deserialize(readStats);
            }
            catch (e) {
                FloodgateEngine_1.FloodgateEngine.getTelemetryLogger().log_Event(Constants_1.TelemetryEvent.HostBasedSurveyActivationStatsProvider.GetSurveyStatsFromHost.Failed, { ErrorMessage: "Json parsing/deserializing failed. " + e.toString() + ".  Input:" + dataString });
            }
            var now = new Date();
            if (newStats.ExpirationTimeUtc <= now) {
                // Delete survey stat from host storage if it's expired.
                this.hostBasedStorage.deleteSettingItem(FileType[FileType.SurveyActivationStats], surveyId);
            }
            else {
                statsCollection.addStats(surveyId, newStats);
            }
        }
        return statsCollection;
    };
    return HostBasedSurveyActivationStatsProvider;
}());
exports.HostBasedSurveyActivationStatsProvider = HostBasedSurveyActivationStatsProvider;
var FileBasedSurveyActivationStatsProvider = /** @class */ (function () {
    function FileBasedSurveyActivationStatsProvider(storage) {
        if (!storage) {
            throw new Error("storage must not be null");
        }
        this.storage = storage;
    }
    // @Override
    FileBasedSurveyActivationStatsProvider.prototype.load = function () {
        var readString = this.storage.read(IFloodgateStorageProvider.FileType.SurveyActivationStats);
        if (!readString) {
            return new SurveyStatCollectionActivation();
        }
        return SurveyStatCollectionActivation.fromJson(readString);
    };
    // @Override
    FileBasedSurveyActivationStatsProvider.prototype.save = function (surveyStatCollectionActivation) {
        if (!surveyStatCollectionActivation) {
            return;
        }
        var writeString = SurveyStatCollectionActivation.toJson(surveyStatCollectionActivation);
        this.storage.write(IFloodgateStorageProvider.FileType.SurveyActivationStats, writeString);
    };
    return FileBasedSurveyActivationStatsProvider;
}());
exports.FileBasedSurveyActivationStatsProvider = FileBasedSurveyActivationStatsProvider;
var SurveyActivationStats = /** @class */ (function () {
    function SurveyActivationStats() {
    }
    /**
     * Method to deserialize a JSON object to class object
     * @param input: JSON object
     * Returns result of validation check
     */
    SurveyActivationStats.prototype.deserialize = function (input) {
        this.ActivationTimeUtc = input.ActivationTimeUtc;
        this.ExpirationTimeUtc = input.ExpirationTimeUtc;
        this.Type = input.Type;
        return this.validate();
    };
    /**
     * Method to call after deserialization to validate generated object.
     * Returns false if not valid.
     */
    SurveyActivationStats.prototype.validate = function () {
        // make it a date object if it's a valid UTC date time value
        if (Utils.isUtcDatetimeString(this.ActivationTimeUtc)) {
            this.ActivationTimeUtc = Utils.stringToDate(this.ActivationTimeUtc);
        }
        else {
            return false;
        }
        // make it a date object if it's a valid UTC date time value
        if (Utils.isUtcDatetimeString(this.ExpirationTimeUtc)) {
            this.ExpirationTimeUtc = Utils.stringToDate(this.ExpirationTimeUtc);
        }
        else {
            return false;
        }
        return Utils.isEnumValue(this.Type, ISurvey.Type);
    };
    return SurveyActivationStats;
}());
exports.SurveyActivationStats = SurveyActivationStats;
