"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Class to hold a collection of Activity Tracking Data
 */
var ActivityTrackingSet = /** @class */ (function () {
    function ActivityTrackingSet(isOrdered, list) {
        this.isOrdered = isOrdered;
        this.list = list;
    }
    ActivityTrackingSet.prototype.getIsOrdered = function () {
        return this.isOrdered;
    };
    ActivityTrackingSet.prototype.getList = function () {
        return this.list;
    };
    return ActivityTrackingSet;
}());
exports.ActivityTrackingSet = ActivityTrackingSet;
