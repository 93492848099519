"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var IFloodgateStorageProvider = require("../Api/IFloodgateStorageProvider");
var Constants_1 = require("../Constants");
var FloodgateEngine_1 = require("../FloodgateEngine");
var StateProviderHelpers = require("../StateProviderHelpers");
var Utils = require("../Utils");
var FileType = IFloodgateStorageProvider.FileType;
var StateListType = StateProviderHelpers.StateListType;
var CampaignState = /** @class */ (function () {
    function CampaignState(campaignId, lastNominationTime, lastNominationBuildNumber, deleteAfterSecondsWhenStale, ForceCandidacy, isCandidate, didCandidateTriggerSurvey, lastSurveyActivatedTime, lastSurveyId, lastSurveyStartTime, lastSurveyExpirationTime, lastCooldownEndTimeUtc) {
        this.CampaignId = campaignId;
        this.LastNominationTimeUtc = lastNominationTime;
        this.LastNominationBuildNumber = lastNominationBuildNumber;
        this.DeleteAfterSecondsWhenStale = deleteAfterSecondsWhenStale;
        this.ForceCandidacy = ForceCandidacy;
        this.IsCandidate = isCandidate;
        this.DidCandidateTriggerSurvey = didCandidateTriggerSurvey;
        this.LastSurveyActivatedTimeUtc = lastSurveyActivatedTime;
        this.LastSurveyId = lastSurveyId;
        this.LastSurveyStartTimeUtc = lastSurveyStartTime;
        this.LastSurveyExpirationTimeUtc = lastSurveyExpirationTime;
        this.LastCooldownEndTimeUtc = lastCooldownEndTimeUtc;
        if (!this.validate()) {
            throw new Error("Constructor arguments are not valid");
        }
    }
    /**
     * Method to deserialize a JSON object to class object
     * @param input: JSON object
     * Returns class object
     */
    CampaignState.deserialize = function (input) {
        var result;
        if (!input) {
            return null;
        }
        try {
            result = new CampaignState(input.CampaignId, input.LastNominationTimeUtc, input.LastNominationBuildNumber, input.DeleteAfterSecondsWhenStale, false, // ForceCandidacy
            input.IsCandidate, input.DidCandidateTriggerSurvey, input.LastSurveyActivatedTimeUtc, input.LastSurveyId, input.LastSurveyStartTimeUtc, input.LastSurveyExpirationTimeUtc, input.LastCooldownEndTimeUtc);
        }
        catch (e) {
            FloodgateEngine_1.FloodgateEngine.getTelemetryLogger().log_Event(Constants_1.TelemetryEvent.CampaignState.Deserialize.Failed, { ErrorMessage: e.toString() });
            return null;
        }
        return result;
    };
    CampaignState.prototype.validate = function () {
        if (!this.CampaignId || !Utils.isString(this.CampaignId)) {
            return false;
        }
        if (Utils.isNullOrUndefined(this.LastNominationBuildNumber)
            || !Utils.isString(this.LastNominationBuildNumber)) {
            return false;
        }
        if (!Utils.isNumber(this.DeleteAfterSecondsWhenStale) || this.DeleteAfterSecondsWhenStale < 0) {
            return false;
        }
        if (!Utils.isBoolean(this.IsCandidate) || !Utils.isBoolean(this.DidCandidateTriggerSurvey)) {
            return false;
        }
        if (this.IsCandidate) {
            // Disallow the following null's if isCandidate == true
            if (Utils.isNullOrUndefined(this.LastSurveyStartTimeUtc) || Utils.isNullOrUndefined(this.LastSurveyExpirationTimeUtc)) {
                return false;
            }
            // LastSurveyId could be ""
            if (!Utils.isString(this.LastSurveyId)) {
                return false;
            }
        }
        if (this.DidCandidateTriggerSurvey) {
            // Disallow the following null's if didCandidateTriggerSurvey == true
            if (Utils.isNullOrUndefined(this.LastSurveyActivatedTimeUtc)) {
                return false;
            }
        }
        if (Utils.isNullOrUndefined(this.LastSurveyId)) {
            this.LastSurveyId = "";
        }
        else if (!Utils.isString(this.LastSurveyId)) {
            return false;
        }
        // LastNominationTime does not allow null
        if (Utils.isUtcDatetimeString(this.LastNominationTimeUtc)) {
            this.LastNominationTimeUtc = Utils.stringToDate(this.LastNominationTimeUtc);
            // invalidate if it's not a Date object
        }
        else if (!Utils.isDate(this.LastNominationTimeUtc)) {
            return false;
        }
        // Set value to distance past if value not provided
        if (Utils.isNullOrUndefined(this.LastSurveyActivatedTimeUtc)) {
            this.LastSurveyActivatedTimeUtc = Utils.getDistantPast();
            // check if it's UTC date time string, possibly coming from deserialize method
        }
        else if (Utils.isUtcDatetimeString(this.LastSurveyActivatedTimeUtc)) {
            this.LastSurveyActivatedTimeUtc = Utils.stringToDate(this.LastSurveyActivatedTimeUtc);
            // invalidate if it's not a Date object
        }
        else if (!Utils.isDate(this.LastSurveyActivatedTimeUtc)) {
            return false;
        }
        // Set value to distance past if value not provided
        if (Utils.isNullOrUndefined(this.LastSurveyStartTimeUtc)) {
            this.LastSurveyStartTimeUtc = Utils.getDistantPast();
            // check if it's string, possibly coming from deserialize method
        }
        else if (Utils.isUtcDatetimeString(this.LastSurveyStartTimeUtc)) {
            this.LastSurveyStartTimeUtc = Utils.stringToDate(this.LastSurveyStartTimeUtc);
            // invalidate if it's not a Date object
        }
        else if (!Utils.isDate(this.LastSurveyStartTimeUtc)) {
            return false;
        }
        // Set value to distance past if value not provided
        if (Utils.isNullOrUndefined(this.LastSurveyExpirationTimeUtc)) {
            this.LastSurveyExpirationTimeUtc = Utils.getDistantPast();
            // check if it's string, possibly coming from deserialize method
        }
        else if (Utils.isUtcDatetimeString(this.LastSurveyExpirationTimeUtc)) {
            this.LastSurveyExpirationTimeUtc = Utils.stringToDate(this.LastSurveyExpirationTimeUtc);
        }
        else if (!Utils.isDate(this.LastSurveyExpirationTimeUtc)) {
            return false;
        }
        // Set value to distance past if value not provided
        if (Utils.isNullOrUndefined(this.LastCooldownEndTimeUtc)) {
            this.LastCooldownEndTimeUtc = Utils.getDistantPast();
            // check if it's string, possibly coming from deserialize method
        }
        else if (Utils.isUtcDatetimeString(this.LastCooldownEndTimeUtc)) {
            this.LastCooldownEndTimeUtc = Utils.stringToDate(this.LastCooldownEndTimeUtc);
        }
        else if (!Utils.isDate(this.LastCooldownEndTimeUtc)) {
            return false;
        }
        return true;
    };
    /**
     * Record that the current survey was taken on this date
     *
     * @param date date
     * @param cooldownSeconds number
     */
    CampaignState.prototype.markCurrentSurveyTakenOnDate = function (date, cooldownSeconds) {
        this.DidCandidateTriggerSurvey = true;
        this.LastSurveyActivatedTimeUtc = date != null ? date : new Date();
        this.LastCooldownEndTimeUtc = Utils.addSecondsWithoutOverflow(this.LastSurveyActivatedTimeUtc, cooldownSeconds);
    };
    CampaignState.prototype.getCooldownStartDate = function () {
        if (!this.IsCandidate) {
            return this.LastNominationTimeUtc;
        }
        if (this.DidCandidateTriggerSurvey) {
            return this.LastSurveyActivatedTimeUtc;
        }
        // In practice this wil be equal to lastNominationTime, but
        // distribution models like a ramp effectively cause us to
        // shift our "effective nomination date" forward with the survey start date
        return this.LastSurveyStartTimeUtc;
    };
    return CampaignState;
}());
exports.CampaignState = CampaignState;
var CampaignStateProviderFactory = /** @class */ (function () {
    function CampaignStateProviderFactory() {
    }
    CampaignStateProviderFactory.make = function (fileBasedStorage, hostBasedStorage) {
        if (hostBasedStorage) {
            return new HostBasedCampaignStateProvider(fileBasedStorage, hostBasedStorage);
        }
        return new FileBasedCampaignStateProvider(fileBasedStorage);
    };
    return CampaignStateProviderFactory;
}());
exports.CampaignStateProviderFactory = CampaignStateProviderFactory;
/**
 * Class representing what is stored in the file.
 */
var FileData = /** @class */ (function () {
    function FileData() {
    }
    return FileData;
}());
var HostBasedCampaignStateProvider = /** @class */ (function () {
    function HostBasedCampaignStateProvider(fileBasedStorage, hostBasedStorage) {
        if (!hostBasedStorage) {
            throw new Error("host-based storage must not be null");
        }
        this.hostBasedStorage = hostBasedStorage;
        // file-based provider is optional
        if (fileBasedStorage) {
            this.fileBasedStateProvider = new FileBasedCampaignStateProvider(fileBasedStorage);
        }
    }
    // @Override
    HostBasedCampaignStateProvider.prototype.load = function () {
        var statesFromHost = this.getStatesFromHost();
        var statesFromFile;
        if (this.fileBasedStateProvider) {
            statesFromFile = this.fileBasedStateProvider.load();
        }
        var StateCollections = StateProviderHelpers.MergeAndUpdateCampaignStates(statesFromFile, statesFromHost);
        return StateCollections.find(StateListType.Merged);
    };
    // @Override
    HostBasedCampaignStateProvider.prototype.save = function (campaignStates) {
        var _this = this;
        if (!campaignStates) {
            return;
        }
        var statesFromHost = this.getStatesFromHost();
        var StateCollections = StateProviderHelpers.MergeAndUpdateCampaignStates(campaignStates, statesFromHost);
        var toBeUpdatedStates = StateCollections.find(StateListType.ToBeUpdated);
        var mergedStates = StateCollections.find(StateListType.Merged);
        // write delta_data to host
        if (toBeUpdatedStates) {
            toBeUpdatedStates.forEach(function (state) {
                var stateValue = StateProviderHelpers.makeSettingItemValue(JSON.stringify(state));
                _this.hostBasedStorage.upsertSettingItem(FileType[FileType.CampaignStates], state.CampaignId, stateValue);
            });
        }
        // Write merged data to File
        if (this.fileBasedStateProvider) {
            this.fileBasedStateProvider.save(mergedStates);
        }
    };
    HostBasedCampaignStateProvider.prototype.getStatesFromHost = function () {
        var states = [];
        var settings = this.hostBasedStorage.readSettingList(FileType[FileType.CampaignStates]);
        for (var key in settings) {
            if (!settings.hasOwnProperty(key)) {
                continue;
            }
            var value = settings[key];
            var dataString = StateProviderHelpers.extractSettingItemValueSubString(value);
            var itemObject = {};
            try {
                itemObject = JSON.parse(dataString);
                var newState = CampaignState.deserialize(itemObject);
                if (newState) {
                    states.push(newState);
                }
            }
            catch (e) {
                FloodgateEngine_1.FloodgateEngine.getTelemetryLogger().log_Event(Constants_1.TelemetryEvent.HostBasedCampaignStateProvider.GetStatesFromHost.Failed, { ErrorMessage: "Json parsing/deserializing failed. " + e.toString() + ".  Input:" + dataString });
            }
        }
        return states;
    };
    return HostBasedCampaignStateProvider;
}());
exports.HostBasedCampaignStateProvider = HostBasedCampaignStateProvider;
var FileBasedCampaignStateProvider = /** @class */ (function () {
    function FileBasedCampaignStateProvider(storage) {
        if (!storage) {
            throw new Error("storage must not be null");
        }
        this.storage = storage;
    }
    // @Override
    FileBasedCampaignStateProvider.prototype.load = function () {
        var readString = this.storage.read(IFloodgateStorageProvider.FileType.CampaignStates);
        if (!readString) {
            return [];
        }
        var fileData;
        try {
            fileData = JSON.parse(readString);
            var result_1 = [];
            if (fileData && fileData.CampaignStates) {
                fileData.CampaignStates.forEach(function (state) {
                    var newState = CampaignState.deserialize(state);
                    if (newState) {
                        result_1.push(newState);
                    }
                });
            }
            return result_1;
        }
        catch (e) {
            FloodgateEngine_1.FloodgateEngine.getTelemetryLogger().log_Event(Constants_1.TelemetryEvent.FileBasedCampaignStateProvider.Load.Failed, { ErrorMessage: "Json parsing/deserializing failed. " + e.toString() + ".  Input:" + readString });
            return [];
        }
    };
    // @Override
    FileBasedCampaignStateProvider.prototype.save = function (campaignStates) {
        if (!campaignStates) {
            return;
        }
        var fileData = new FileData();
        fileData.CampaignStates = campaignStates;
        var writeString = JSON.stringify(fileData);
        this.storage.write(IFloodgateStorageProvider.FileType.CampaignStates, writeString);
    };
    return FileBasedCampaignStateProvider;
}());
exports.FileBasedCampaignStateProvider = FileBasedCampaignStateProvider;
