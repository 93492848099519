/**
 * An Aria-based logger
 */

import * as Aria from "./Aria";
import { App, Event, Session, Host, Release } from "./Contracts";
import ITelemetryLogger from "./ITelemetryLogger";

export default class AriaTelemetryLogger implements ITelemetryLogger {
	// Event.name is set as namespace<DELIMITER>eventName
	private static EVENT_NAME_DELIMITER: string = "_";
	private static CUSTOM_PROPERTIES_FIELD_PREFIX: string = "Data.";

	private static setApp(properties: any, app: App): void {
		properties["App.Name"] = app.getName();
		properties["App.Platform"] = app.getPlatform();
		properties["App.Version"] = app.getVersion();
	}

	private static setSession(properties: any, session: Session) {
		properties["Session.Id"] = session.getId();
	}

	private static setHost(properties: any, host: Host) {
		if (host) {
			properties["Host.Id"] = host.getId();
			// To be GDPR compliant do not log Host.SessionId
			properties["Host.Version"] = host.getVersion();
		}
	}

	private static setEvent(properties: any, event: Event) {
		properties["Event.Name"] = event.getName();
		properties["Event.Id"] = event.getId();
		properties["Event.Source"] = event.getSource();
		properties["Event.SchemaVersion"] = event.getSchemaVersion();
		properties["Event.Sequence"] = event.getSequence();
	}

	private static setRelease(properties: any, release: Release) {
		if (release) {
			properties["Release.AudienceGroup"] = release.getAudienceGroup();
		}
	}

	private oesApp: App;
	private oesSession: Session;
	private oesHost: Host;
	private oesRelease: Release;

	private namespace: string;
	private sequence: number;

	constructor(tenantToken: string, namespace: string, app: App, session: Session, host?: Host, release?: Release) {
		if (!tenantToken) {
			throw new Error("tenantToken must not be null");
		}
		if (!namespace) {
			throw new Error("namespace must not be null");
		}
		if (!app) {
			throw new Error("app must not be null");
		}
		if (!session) {
			throw new Error("session must not be null");
		}

		Aria.AWT().initialize(tenantToken);
		this.namespace = namespace;
		this.oesApp = app;
		this.oesSession = session;
		this.oesHost = host;
		this.oesRelease = release;
		this.sequence = 1; // Sequence starts at 1 not 0
	}

	public logEvent(eventName: string, customProperties?: { [customField: string]: any }) {
		if (!eventName) {
			throw new Error("eventName must not be null");
		}

		let oesEvent: Event = new Event(
			this.namespace + AriaTelemetryLogger.EVENT_NAME_DELIMITER + eventName,
			this.oesSession.getId(),
			this.sequence
		);

		let ariaProperties: any = {};

		if (customProperties != null) {
			for (const key in customProperties) {
				if (customProperties.hasOwnProperty(key)) {
					ariaProperties[AriaTelemetryLogger.CUSTOM_PROPERTIES_FIELD_PREFIX + key] = customProperties[key];
				}
			}
		}

		AriaTelemetryLogger.setApp(ariaProperties, this.oesApp);
		AriaTelemetryLogger.setSession(ariaProperties, this.oesSession);
		AriaTelemetryLogger.setHost(ariaProperties, this.oesHost);
		AriaTelemetryLogger.setRelease(ariaProperties, this.oesRelease);
		AriaTelemetryLogger.setEvent(ariaProperties, oesEvent);

		Aria.AWT().logEvent({
			name: oesEvent.getName(),
			properties: ariaProperties,
		});

		this.sequence++;
	}
}
