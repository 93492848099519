"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
/**
 * Generic class for managing a collection of survey stats. Includes read-from/write-to json structures or a file,
 * as well as merge routines for combining collections
 */
var SurveyStatCollection = /** @class */ (function () {
    function SurveyStatCollection() {
        // The following property name matches the JSON root key name for proper serialization/ deserialization
        this.Surveys = {};
        this.Surveys = {};
    }
    /**
     * Add stats. Overwrites if already exists.
     */
    SurveyStatCollection.prototype.addStats = function (surveyId, stats) {
        this.Surveys[surveyId] = stats;
    };
    /**
     * Get a SurveyActivationStats object by surveyId. Returns null if surveyId is not found
     */
    SurveyStatCollection.prototype.getBySurveyId = function (surveyId) {
        return (this.Surveys[surveyId]);
    };
    /**
     * Get all stats available
     */
    SurveyStatCollection.prototype.getStats = function () {
        return this.Surveys;
    };
    return SurveyStatCollection;
}());
exports.SurveyStatCollection = SurveyStatCollection;
